<template>
  <div>
    <div class="understand">
      <div class="head">
        <img src="@/assets/img/favicon.png"
             class="favicon">
        <div>
          <span>Swan Finance</span>
        </div>
      </div>

      <div class="text">
        <div>
          <span>
            {{ $t('lang.swap208') }}
          </span>
        </div>
        <div>
          <span>
            {{ $t('lang.swap209') }}
          </span>
        </div>
        <div>
          <span>
            {{ $t('lang.swap210') }}
          </span>
        </div>
        <div>
          <span>
            {{ $t('lang.swap211') }}
          </span>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="explain">
        <span> {{ $t('lang.swap212') }}</span>
      </div>
      <div class="explain">
        <div>
          <span> {{ $t('lang.swap213') }}</span>
        </div>
        <span> {{ $t('lang.swap214') }}</span>
      </div>
      <div class="logo">
        <img src="@/assets/img/favicon.png">
        <span>Swan Finance</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.understand {
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 16px 16px 6px 6px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  padding: 20px 10px 30px;
  margin-top: 20px;
  .head {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    align-items: center;
    color: #5b3106;
    border-bottom: 3px solid #c7c7c7;
    padding-bottom: 20px;
    .favicon {
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
    }
  }
  .text {
    font-size: 12px;
    color: #333333;
    padding: 0 10px;
    div {
      margin-top: 20px;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: bold;
  color: #666666;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 6px 6px 16px 16px;
  border: 2px solid #ffffff;
  padding: 10px 0;
  margin-top: 6px;
  .explain {
    transform: scale(0.7, 0.7);
  }
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    img {
      width: 22px;
      height: 22px;
    }
    span {
      transform: scale(0.7, 0.7);
    }
  }
}
</style>