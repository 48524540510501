<template>
  <div class="home_page">
    <!-- <div>
      <van-swipe class="my-swipe" indicator-color="white" :show-indicators="false" :autoplay="0">
        <van-swipe-item v-for="(item, index) of RotationList" :key="index">
          <van-image :src="item.img" class="bannerImg" />
        </van-swipe-item>
      </van-swipe>
    </div> -->

    <div class="information">
      <div class="information_my">
        <span>{{ $t('lang.swap141') }}:</span>
      </div>
      <div class="transaction">
        <img class="logo" src="@/assets/img/favicon.png" />
        <van-button type="primary" class="swapBtn" @click="toSwap">{{ $t('lang.swap88') }}</van-button>
      </div>
      <div class="swan_list">
        <div class="li">
          <span>{{ $t('lang.swap138') }}</span>
          <span class="special">${{ price.toFixed(2) }}</span>
        </div>
        <div class="li">
          <span>{{ $t('lang.swap139') }}</span>
          <span class="special">29000</span>
        </div>
        <div class="li">
          <span>{{ $t('lang.swap140') }}:</span>
          <span class="special">${{ (29000 * price).toFixed(2) }}</span>
        </div>
        <div class="li">
          <span>{{ $t('lang.swap136') }}:</span>
          <span class="special">{{ myBalance.toFixed(6) }}</span>
        </div>
      </div>
    </div>
    <div>
      <van-notice-bar :scrollable="false">
        <div class="text" @click="jumpLink('NoticeIndex')">
          <span class="text_left">{{ noticeObj.title }}</span>
          <span>{{ $t('lang.swap131') }} ></span>
        </div>
      </van-notice-bar>
    </div>
    <!-- <div class="increase">
      <div v-for="(item) of 4"
           :key="item">
        <div style="transform: scale(0.7, 0.7);">
          <span class="currency">Swan</span>
          <span :class="item >2?'negative':'just'">+5.68%</span>
        </div>
        <span>$1,896.45</span>
      </div>
    </div>-->
    <div>
      <div class="market" @click="jumpLink('GameFi')">
        <div class="market_left">
          <span>{{ $t('lang.swap202') }}</span>
          <img src="@/assets/img/chainTour.png" class="market_img" />
        </div>
        <div class="market_right">
          <img src="@/assets/img/swan.png" class="swan" />
        </div>
      </div>
      <div class="middle">
        <div class="middle_left" @click="jumpLink()">
          <span>{{ $t('lang.swap203') }}</span>
          <img src="@/assets/img/freeMarket.png" class="middle_img" />
        </div>
        <div class="middle_right" @click="jumpLink('StartingCenter')">
          <span>{{ $t('lang.swap178') }}</span>
          <img src="@/assets/img/startingCenters.png" class="middle_img" />
        </div>
      </div>
      <div class="special_area">
        <div class="mining" @click="jumpLink('Mining')">
          <div class="mining_top">
            <span>{{ $t('lang.swap204') }}</span>
            <img src="@/assets/img/undamaged.png" class="undamaged" />
          </div>
          <img src="@/assets/img/backgroundSwan.png" class="backgroundSwan" />
        </div>
        <div class="mortgage">
          <div class="mortgage_top" @click="jumpLink()">
            <span>{{ $t('lang.swap205') }}</span>
            <img src="@/assets/img/mortgage.png" class="mortgage_img" />
          </div>
          <div class="mortgage_top" @click="jumpLink()">
            <span>{{ $t('lang.swap206') }}</span>
            <img src="@/assets/img/nftZone.png" class="mortgage_img" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="list">
      <div class="classification">
        <span class="name">关注榜</span>
        <span class="name">热门榜</span>
      </div>
      <div class="line"></div>
      <div>

      </div>
    </div>-->
    <div class="partner_list">
      <div class="partner">
        <div class="partner_title">
          <span>{{ $t('lang.swap207') }}</span>
        </div>
        <div class="partner_img">
          <img v-for="(item, index) of institutionsListC" :key="index" :src="item" class="img" />
        </div>
      </div>
      <div class="Icon_img">
        <img v-for="(item, index) of institutionsListIcon" :key="index" :src="item" class="img" />
      </div>
      <div class="reserved">
        <span>Copyright©2021Phoenix.All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import { mapState } from 'vuex';
import lp from '../../methods/lp.js';
import { basic } from '../../api/index';
import { invite } from '../../api/index';
export default {
  data() {
    return {
      price: 0,
      death: 0,
      myBalance: 0,
      chainInviteInfo: '',
      profit: 0,
      swanPrice: 0,
      institutionsListC: [
        require('@/assets/img/BINANCE.png'),
        require('@/assets/img/BitKeep.png'),
        require('@/assets/img/TRUST.png'),
        require('@/assets/img/METAMASK.png'),
        require('@/assets/img/CoinMarketCap.png'),
        require('@/assets/img/MATH.png'),
        require('@/assets/img/TOKEN.png'),
        require('@/assets/img/CoinGecko.png'),
        require('@/assets/img/LINEHUB.png'),
        require('@/assets/img/IMAGE.png'),
        require('@/assets/img/SECRET.png'),
        require('@/assets/img/Kirin.png'),
      ],
      institutionsListIcon: [
        require('@/assets/img/IconA.png'),
        require('@/assets/img/IconB.png'),
        require('@/assets/img/IconC.png'),
        require('@/assets/img/IconD.png'),
        require('@/assets/img/IconE.png'),
      ],
      noticeObj: '',
      RotationList: [{ img: require('@/assets/img/home-banner1.png') }, { img: require('@/assets/img/home-banner2.jpg') }],
      site: '',
    };
  },
  created() {
    this.site = this.$route.query.invite_address ? this.$route.query.invite_address : '';

    this.init();
    this.getSwanPrice();
    this.getBasicLatest();
    this.watchLang();
  },
  methods: {
    bind() {
      let data = {
        recommendAddress: this.$route.query.invite_address,
      };
      invite.userBind(data, this.account).then(res => {
        if (res.code === 200) {
        } else {
        }
      });

      let data2 = {
        recommend_address: this.$route.query.invite_address,
      };
      axios({
        method: 'post',
        url: 'https://box.swanfinance.cc/v1/user-center/bind',
        headers: {
          'Content-Type': 'application/json',
          'Show-Language': 'zh-CN',
          Authorization: this.account,
        },
        data: data2,
      })
        .then(res => {})
        .catch(e => {});
    },
    watchLang() {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        if (e.key == 'lang') {
          _this.getBasicLatest();
        }
      });
    },

    async getBasicLatest() {
      this.language = await localStorage.getItem('lang');
      if (this.language == null) {
        this.language = 'en-US';
      }
      // this.loadstatus = true
      if (this.language) {
        this.language = localStorage.getItem('lang');
        basic.getNoticeLatest(this.account, this.language).then(res => {
          if (res.code == 200) {
            this.noticeObj = res.data;
          }
        });
      }
    },

    async init() {
      // axios
      //   .get('https://api.pancakeswap.info/api/v2/tokens/0x25BF35B089DAcB352eadB802a685207d31E23a9F')
      //   .then(res => {
      //     this.price = res.data.data.price * 1;
      //     console.log(res);
      //   })
      //   .catch(e => {});
      const erc20Contract = getErc20Contract('0x25BF35B089DAcB352eadB802a685207d31E23a9F');
      const balance = await erc20Contract.methods.balanceOf('0x0000000000000000000000000000000000000000').call();
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.myBalance = (bal * 1) / Math.pow(10, 18);
      this.death = (balance * 1) / Math.pow(10, 18);
      if (this.site) {
        this.bind();
      }
      this.getReward();
    },
    without() {
      this.$router.push({ name: 'Mining' });
    },
    toSwap() {
      this.$router.push({ name: 'Swap' });
    },
    getPrice() {},
    getReward() {
      lp.pendingReward('0', this.account).then(res => {
        this.profit = res / Math.pow(10, 18);
      });
    },
    async getSwanPrice() {
      const jackPairContract = getJackPairContract('0xee639d7Dc9A13956e08407D6C014225b0cE9b763');
      let reserves = await jackPairContract.methods.getReserves().call();
      let usdtAddress = '0x55d398326f99059fF775485246999027B3197955'; //from
      let swanAddress = '0x25BF35B089DAcB352eadB802a685207d31E23a9F'; //to
      if (swanAddress.toLocaleLowerCase() < usdtAddress.toLowerCase()) {
        let fromAll = reserves._reserve0 / Math.pow(10, 18);
        let toAll = reserves._reserve1 / Math.pow(10, 18);
        this.price = toAll / fromAll; //币的价格
      } else {
        let fromALL = reserves._reserve1 / Math.pow(10, 18);
        let toAll = reserves._reserve0 / Math.pow(10, 18);
        this.price = toAll / fromALL; //币的价格
      }
    },
    jumpLink(router) {
      if (router) {
        this.$router.push({ name: router });
      } else {
        this.$toast(this.$t('lang.swap65'));
      }
    },
  },
  computed: {
    ...mapState(['account']),
  },
  components: {},
};
</script>

<style lang="less" scoped>
.home_page {
  padding: 20px 0 20px;
  .my-swipe {
    // display: block;
    color: #c67700;
    font-size: 16px;
    // text-align: center;
    margin-bottom: 20px;
    .van-swipe-item {
      // width: 100%;
      height: 176px;
      background: #ffffff;
      border-radius: 16px;
      overflow: hidden;
      // /deep/ .van-swipe__track {
      //   width: 1000px;
      // }
      .bannerImg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .van-notice-bar {
    font-size: 12px;
    color: #ffffff;
    border-radius: 16px;
    background: #ffd245;
    margin: 20px 0 40px;
    /deep/ .van-ellipsis {
      width: 100%;
    }
    .text {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .text_left {
        display: block;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        padding-right: 10px;
      }
    }
  }
  .information {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 16px;
    // border: 1px solid #ffd245;
    padding: 26px 26px 20px;
    .information_my {
      font-size: 22px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .transaction {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .logo {
        width: 50px;
        height: 50px;
      }
      .swapBtn {
        height: 30px;
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        padding: 2px 28px !important;
      }
    }
    .swan_list {
      .li {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        margin-top: 20px;
        .special {
          color: #333333;
        }
      }
    }
  }
  .increase {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 16px;
    text-align: center;
    padding: 16px 0;
    margin: 16px 0 42px;
    .currency {
      font-size: 14px;
      color: #666666;
    }
    .just {
      font-size: 10px;
      color: #0fbe61;
    }
    .negative {
      font-size: 10px;
      color: #e30c0c;
      transform: scale(0.3, 0.3);
    }
  }
  .market {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 10px;
    .market_left {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding: 26px 0;
      .market_img {
        width: 70px;
        height: 70px;
        margin-top: 20px;
      }
    }
    .market_right {
      position: relative;
      flex: 1;
      .swan {
        position: absolute;
        top: 0;
        right: 20px;
        width: 180px;
        height: 200px;
        transform: translateX(0%) translateY(-26%);
      }
    }
  }
  .middle {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46%;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
      border-radius: 10px;
      padding: 20px 0;
      .middle_img {
        width: 70px;
        height: 70px;
        margin-top: 20px;
      }
    }
    .middle_left {
      color: #ffffff;
      background: linear-gradient(180deg, #ffd245 0%, #fff2cb 100%);
      color: #ffffff;
    }
  }
  .special_area {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    .mining {
      width: 46%;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
      border-radius: 10px;
      padding-top: 20px;
      .mining_top {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .undamaged {
        width: 70px;
        height: 70px;
        margin-top: 10px;
      }
      .backgroundSwan {
        display: block;
        width: 100%;
      }
    }
    .mortgage {
      width: 46%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .mortgage_top {
        height: 46%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
        border-radius: 10px;
        padding: 20px 6px;
      }
      .mortgage_img {
        width: 70px;
        height: 70px;
      }
    }
  }
  .list {
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 10px;
    padding: 20px 10px;
    margin: 20px 0;
    .classification {
      font-size: 16px;
      color: #333333;
      .name {
        box-shadow: 0px 3px 6px 1px rgba(255, 210, 69, 0.30196078431372547);
        border-radius: 16px;
        opacity: 1;
        border: 1px solid #ffd245;
        padding: 4px 14px;
        margin-left: 10px;
      }
    }
    .line {
      border: 1px solid #707070;
      margin: 20px 0 10px;
    }
  }
  .partner_list {
    background: #ffffff;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 16px;
    // border: 1px solid #3399ff;
    padding: 26px 26px 20px;
    margin-top: 20px;
    .partner {
      margin-bottom: 10px;
      .partner_title {
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .partner_img {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
        img {
          width: 48%;
          margin-bottom: 10px;
        }
      }
    }
    .reserved {
      font-size: 10px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
    }
    .Icon_img {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .img {
        width: 50px;
      }
    }
  }
}
</style>