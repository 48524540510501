import request from '../utils/request';
export default {
    //捐赠
    donate(data,address,language) {
        return request.request({
            url: 'member/donate',
            method: "post",
            headers:{
                Authorization : address,
                'Show-Language':language
            },
            data:data
            
        })
    },

    //获取捐赠状态
    donateStatus(data,address,language){
        return request.request({
            url: '/member/donate/status',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':language,
            },
            params:data
        })
    }
}