<template>
  <div>
    <div class="choice">
      <div v-for="(item, index) of choiceList" :key="index" @click="choiceSwitch(index)" :class="choiceIndex == index ? 'special' : ''">
        <span>{{ item }}</span>
      </div>
    </div>
    <!-- 进行中 -->
    <div v-show="choiceIndex == '0'">
      <div class="mining" v-for="(item, index) of MiningData" :key="index">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img" :src="item.logoA" />
            <img class="special_img" :src="item.logoB" />
            <span>{{ item.name }}</span>
          </div>
          <!-- <div class="head_right">
          <div class="exhibition">
            <span>{{ $t('lang.swap142') }}</span>
            <span class="value">0.00000</span>
          </div>
          <div class="exhibition">
            <span>年化利率</span>
            <span class="value">32.45</span>
          </div>
          </div>-->
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.profit * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary" class="confirmBtn" @click="harvest(index)" v-if="!notWithDrowNotTakeOut">
                <span>{{ $t('lang.swap146') }}</span>
              </van-button>
              <van-button type="default" class="confirmBtn" v-else>
                <span>{{ $t('lang.swap146') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned" v-if="!item.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.myNumber * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img" src="@/assets/img/reduce.png" @click="selectV2(false, index)" v-if="!notWithDrowNotTakeOut" />
              <img class="button_img" src="@/assets/img/no-reduce.png" v-else />
              <img class="button_img" src="@/assets/img/plus.png" @click="selectV2(true, index)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked" v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary" class="confirmBtn" @click="confirmRemovalV2(index)" loading-text="Waiting..." :loading="item.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
            <!-- <span>当前质押{{MiningData.myNumber / Math.pow(10,18)}}</span> -->
          </div>

          <div class="tvl" v-if="index == 0">
            <div>
              <span>{{ $t('lang.swap144') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.userTvl }}</span>
              <img class="money_img" src="@/assets/img/money.png" />
            </div>
          </div>
          <div class="tvl"  v-if="index == 0">
            <div>
              <span>{{ $t('lang.swap220') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.OtherTVL }}</span>
              <img class="money_img" src="@/assets/img/money.png" />
            </div>
          </div>
          <div class="tvl"  v-if="index == 0">
            <div>
              <span>{{ $t('lang.swap221') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.maxTVL }}</span>
              <img class="money_img" src="@/assets/img/money.png" />
            </div>
          </div>
          <div class="tvl"  v-if="index == 0">
            <div>
              <span>{{ $t('lang.swap233') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.power }}</span>
            </div>
          </div>

          <div class="specific_data">
            <div class="specific">
              <span>{{ $t('lang.swap147') }}</span>
              <div>
                <span class="special">{{ ((item.allReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>
                <span class="company">{{ item.currencyName }}</span>
              </div>
            </div>
            <div class="specific">
              <span>{{ $t('lang.swap148') }}</span>
              <div>
                <span class="special">{{ ((item.todayReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>
                <span class="company">{{ item.currencyName }}</span>
              </div>
            </div>
            <!-- <div class="specific">
              <span>{{ $t('lang.swap149') }}</span>
              <div>
                <span class="special">{{item.pledge_num}}</span>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <!-- 已结束 -->
    <div v-show="choiceIndex == '1'">
      <div class="mining" v-for="(item, index) of hasEnded" :key="index">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img" src="@/assets/img/usdt.png" />
            <img class="special_img" src="@/assets/img/favicon.png" />
            <span>SWAN-USDT V1 POOL</span>
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.profit * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary" class="confirmBtn" @click="harvest(index)">
                <span>{{ $t('lang.swap146') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned" v-if="!item.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.myNumber * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img" src="@/assets/img/reduce.png" @click="select(false, index)" />
              <img class="button_img" src="@/assets/img/errplus.png" @click="select(true, index)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked" v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary" class="confirmBtn" @click="confirmRemoval" loading-text="Waiting..." :loading="item.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
          </div>

          <!-- <div class="tvl">
            <div>
              <img class="tvl_img" src="@/assets/img/personal.png" />
              <span>{{ $t('lang.swap144') }}</span>
            </div>
            <div class="tvl_value">
              <img class="money_img" src="@/assets/img/money.png" />
              <span>{{item.userTvl}}</span>
            </div>
          </div> -->
          <!-- <div class="tvl">
            <div>
              <img class="tvl_img"
                   src="@/assets/img/team.png" />
              <span>{{ $t('lang.swap145') }}</span>
              <span class="invitation"
                    @click="Referrals">{{ $t('lang.swap60') }}</span>
            </div>
            <div class="tvl_value">
              <img class="money_img"
                   src="@/assets/img/money.png" />
              <span>{{item.maxTVL}}</span>
            </div>
          </div>-->

          <div class="specific_data">
            <div class="specific">
              <span>{{ $t('lang.swap147') }}</span>
              <div>
                <span class="special">{{ ((item.allReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>
                <span class="company">SWAN</span>
              </div>
            </div>
            <div class="specific">
              <span>{{ $t('lang.swap148') }}</span>
              <div>
                <span class="special">{{ ((item.todayReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>
                <span class="company">SWAN</span>
              </div>
            </div>
            <!-- <div class="specific">
              <span>{{ $t('lang.swap149') }}</span>
              <div>
                <span class="special">{{item.pledge_num}}</span>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="MiningPopupShow" round>
      <MiningPopup @handleConfirm="MiningPopupfirm" @getMyAddress="getBalance" :selectShow="selectShow" :max="max" :title="popupTitle"></MiningPopup>
    </van-popup>
  </div>
</template>

<script>
import MiningPopup from '@/views/index/MiningPopup.vue';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import lp from '../../methods/lp.js';
import lpV2 from '../../methods/lpv2.js';
import sgfLP from '../../methods/sgfLp.js';
import { coin } from '../../api/index';
import { significantDigits } from '@/utils/format.js';
export default {
  data() {
    return {
      MiningData: [
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          maxTVL: 0,
          OtherTVL: 0,
          power: 0,
          pledge_num: 0,
          logoA: require('@/assets/img/usdt.png'),
          logoB: require('@/assets/img/favicon.png'),
          name: 'SWAN-USDT V2 POOL',
          currencyName: 'SWAN',
        },
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          maxTVL: 0,
          OtherTVL: 0,
          power: 0,
          pledge_num: 0,
          logoA: require('@/assets/img/SWANGAMEFI.png'),
          logoB: require('@/assets/img/usdt.png'),
          name: 'SGF-USDT',
          currencyName: 'SGF',
        },
      ],
      hasEnded: [
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          OtherTVL: 0,
          maxTVL: 0,
          pledge_num: 0,
        },
      ],
      participate: [
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          teamTvl: 0,
          pledge_num: 0,
        },
      ],
      selectShow: false, //true:质押,false:赎回
      MiningPopupShow: false,
      max: 0,
      choiceList: ['进行中', '已结束'],
      choiceIndex: '0',
      canWithDrowNotTakeOutList: [], //可收割不可取出
      notWithDrowNotTakeOutList: ['0x013a2eda2dcdd97324b404e479b456e855d6e4ed', '0xcC1Db984a726dB66D627Db1df541eC571bD5585E'], //不可取出不可收割
      // canWithDrowNotTakeOut: false,
      notWithDrowNotTakeOut: false,
      popupTitle:'SWAN-USDT',
      sign: 0, //当前选择标记
    };
  },
  async created() {
    this.handleApproveBtnShow();
    this.getDeposit();
    this.getReward();
    this.getBalanceOf();
    this.getpoolReward();
    this.getUserTvl();
    this.blackListCheck();
  },

  methods: {
    Referrals() {
      this.$router.push({ name: 'Invitation' });
    },

    blackListCheck() {
      if (this.account) {
        this.notWithDrowNotTakeOut = this.notWithDrowNotTakeOutList.includes(this.account);
        // this.canWithDrowNotTakeOut = this.canWithDrowNotTakeOutList.includes(this.account);
        // console.log('不可取出不可收割', this.notWithDrowNotTakeOut);
        // console.log('不可取出可收割', this.canWithDrowNotTakeOut);
      }
    },

    // 是否需要显示v1 v2授权按钮
    async handleApproveBtnShow() {
      if (this.account) {
        const erc20Contract = getErc20Contract('0x4F9F4585D94A41c62151EB87F905C91676228fd7');
        const to = '0xfb102E219C8e861846CCA6C26CB9265bF5001536';
        const allowance = await erc20Contract.methods.allowance(this.account, to).call();
        if (allowance == 0) {
          this.hasEnded[0].allowanceToRouter = true;
        } else {
          this.hasEnded[0].allowanceToRouter = false;
        }

        const erc20ContractV2 = getErc20Contract('0xee639d7Dc9A13956e08407D6C014225b0cE9b763');
        const toV2 = '0x1A9705e620872F30a8eDBaDAA80eff34877cD26f';
        const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
        if (allowanceV2 == 0) {
          this.MiningData[0].allowanceToRouter = true;
        } else {
          this.MiningData[0].allowanceToRouter = false;
        }

        //获取sgf的授权余额
        const erc20ContractSgf = getErc20Contract('0xadf3f98fd6f577c0df9ae22f6ce2ec358786f80b');
        const toSgf = '0x2f6EFccD788f1fB76eF03516F5537186790Ea3b5';
        const allowanceSgf = await erc20ContractSgf.methods.allowance(this.account, toSgf).call();
        if (allowanceSgf == 0) {
          this.MiningData[1].allowanceToRouter = true;
        } else {
          this.MiningData[1].allowanceToRouter = false;
        }
      }
    },

    //获取v1 v2的lp余额
    async getBalanceOf() {
      if (this.account) {
        const erc20Contract = getErc20Contract('0x4F9F4585D94A41c62151EB87F905C91676228fd7');
        const balance = await erc20Contract.methods.balanceOf(this.account).call();
        this.hasEnded[0].balance = balance / Math.pow(10, 18);

        const erc20ContractV2 = getErc20Contract('0xee639d7Dc9A13956e08407D6C014225b0cE9b763');
        const balanceV2 = await erc20ContractV2.methods.balanceOf(this.account).call();
        this.MiningData[0].balance = balanceV2 / Math.pow(10, 18);
        //sgf余额
        const erc20ContractSgf = getErc20Contract('0xadf3f98fd6f577c0df9ae22f6ce2ec358786f80b');
        const balanceSgf = await erc20ContractSgf.methods.balanceOf(this.account).call();
        this.MiningData[1].balance = balanceSgf / Math.pow(10, 18);
      }
    },

    MiningPopupfirm() {
      this.MiningPopupShow = !this.MiningPopupShow;
    },

    //v1授权按钮
    confirmRemoval() {
      this.hasEnded[0].showApprove = true;
      const erc20Contract = useErc20Contract('0xee639d7Dc9A13956e08407D6C014225b0cE9b763');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0xfb102E219C8e861846CCA6C26CB9265bF5001536', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.hasEnded[0].showApprove = false;
          this.hasEnded[0].allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.hasEnded[0].showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },

    //v2授权按钮
    confirmRemovalV2(i) {
      if (i == 1) {
        this.MiningData[1].showApprove = true;
        const erc20Contract = useErc20Contract('0xadf3f98fd6f577c0df9ae22f6ce2ec358786f80b');
        const amo = MaxUint256.toString();
        erc20Contract.methods
          .approve('0x2f6EFccD788f1fB76eF03516F5537186790Ea3b5', amo)
          .send({
            from: this.account,
          })
          .on('confirmation', res => {
            this.MiningData[1].showApprove = false;
            this.MiningData[1].allowanceToRouter = false;
          })
          .on('error', (error, receipt) => {
            this.MiningData[1].showApprove = false;
            this.$toast(this.$t('lang.swap74'));
          });
      } else if (i == 0) {
        this.MiningData[0].showApprove = true;
        const erc20Contract = useErc20Contract('0xee639d7Dc9A13956e08407D6C014225b0cE9b763');
        const amo = MaxUint256.toString();
        erc20Contract.methods
          .approve('0x1A9705e620872F30a8eDBaDAA80eff34877cD26f', amo)
          .send({
            from: this.account,
          })
          .on('confirmation', res => {
            this.MiningData[0].showApprove = false;
            this.MiningData[0].allowanceToRouter = false;
          })
          .on('error', (error, receipt) => {
            this.MiningData[0].showApprove = false;
            this.$toast(this.$t('lang.swap74'));
          });
      }
    },

    // v1质押
    getBalance(res) {
      let amount = res * 1 * Math.pow(10, 18) + '';
      this.MiningPopupShow = false;
      if (this.selectShow) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap158')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        if (this.choiceIndex == '0') {
          if (this.sign == 0) {
            lpV2
              .deposit(this.account, 1, amount, transactionsDetail)
              .then(res => {
                this.getDeposit();
                this.getBalanceOf();
              })
              .catch(e => {});
          } else if (this.sign == 1) {
            sgfLP
              .deposit(this.account, 0, amount, transactionsDetail)
              .then(res => {
                this.getDeposit();
                this.getBalanceOf();
              })
              .catch(e => {});
          }
        } else {
          lp.deposit(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
            })
            .catch(e => {});
        }
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap159')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        if (this.choiceIndex == '0') {
          if (this.sign == 0) {
            lpV2
              .withdraw(this.account, 1, amount, transactionsDetail)
              .then(res => {
                this.getDeposit();
                this.getUserTvlLog();
                this.getBalanceOf();
              })
              .catch(e => {});
          } else if (this.sign == 1) {
            sgfLP
              .withdraw(this.account, 0, amount, transactionsDetail)
              .then(res => {
                this.getDeposit();
                this.getUserTvlLog();
                this.getBalanceOf();
              })
              .catch(e => {});
          }
        } else {
          lp.withdraw(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getUserTvlLog();
              this.getBalanceOf();
            })
            .catch(e => {});
        }
      }
    },

    //v1 v2收割
    harvest(i) {
      if (this.choiceIndex == '1' && this.hasEnded[i].profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      } else if (this.choiceIndex == '0' && this.MiningData[i].profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap146')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      if (this.choiceIndex == '0') {
        if (i == 0) {
          lpV2
            .harvest(this.account, 1, transactionsDetail)
            .then(res => {
              this.getReward();
            })
            .catch(e => {});
        } else if (i == 1) {
          sgfLP
            .harvest(this.account, 0, transactionsDetail)
            .then(res => {
              this.getReward();
            })
            .catch(e => {});
        }
      } else {
        lp.harvest(this.account, 0, transactionsDetail)
          .then(res => {
            this.getReward();
          })
          .catch(e => {});
      }
    },

    //v1 v2获取质押数量
    getDeposit() {
      lp.userinfo(this.account, '0').then(res => {
        this.hasEnded[0].myNumber = res.amount / Math.pow(10, 18);
      });

      lpV2.userinfo(this.account, '1').then(res => {
        this.MiningData[0].myNumber = res.amount / Math.pow(10, 18);
      });

      sgfLP.userinfo(this.account, '0').then(res => {
        this.MiningData[1].myNumber = res.amount / Math.pow(10, 18);
      });
    },

    //v1 v2获取收益
    getReward() {
      lp.pendingReward('0', this.account).then(res => {
        this.hasEnded[0].profit = res / Math.pow(10, 18);
      });

      lpV2.pendingReward('1', this.account).then(res => {
        this.MiningData[0].profit = res / Math.pow(10, 18);
      });

      sgfLP.pendingReward('0', this.account).then(res => {
        this.MiningData[1].profit = res / Math.pow(10, 18);
      });
    },

    //v1选择
    select(state) {
      this.selectShow = state;
      this.popupTitle = 'SWAN-USDT'
      if (state) {
        return;
        this.max = this.hasEnded[0].balance;
      } else {
        this.max = significantDigits(this.hasEnded[0].myNumber);
      }
      this.MiningPopupfirm();
    },

    selectV2(state, i) {
      this.selectShow = state;
      this.sign = i;
      if (i == 1) {
        this.popupTitle = 'SGF-USDT'
        if (state) {
          this.max = significantDigits(this.MiningData[1].balance);
        } else {
          this.max = significantDigits(this.MiningData[1].myNumber);
        }
      } else if (i == 0) {
        this.popupTitle = 'SWAN-USDT'
        if (state) {
          this.max = significantDigits(this.MiningData[0].balance);
        } else {
          this.max = significantDigits(this.MiningData[0].myNumber);
        }
      }
      this.MiningPopupfirm();
    },

    //v1 v2激励
    getpoolReward() {
      lp.poolReward().then(res => {
        this.hasEnded[0].allReward = res[0];
        this.hasEnded[0].todayReward = res[1];
      });

      lpV2.poolReward().then(res => {
        this.MiningData[0].allReward = res[0];
        this.MiningData[0].todayReward = res[1];
      });

      sgfLP.poolReward().then(res => {
        this.MiningData[1].allReward = res[0];
        this.MiningData[1].todayReward = res[1];
      });
    },

    //V1个人TVL
    getUserTvl() {
      coin.userTvl(this.account).then(res => {
        if (res.code == 200) {
          // this.hasEnded[0].pledge_num = res.data.pledge_num;
          this.MiningData[0].userTvl = res.data.memberTVL;
          this.MiningData[0].OtherTVL = res.data.communityOtherTVL;
          this.MiningData[0].maxTVL = res.data.communityMaxTVL;
          this.MiningData[0].power = res.data.power;
        }
      });
    },
    choiceSwitch(i) {
      this.choiceIndex = i;
    },
    significantDigits,
  },
  components: {
    MiningPopup,
  },
  watch: {
    account() {
      //获取账号
      this.handleApproveBtnShow();
      this.getDeposit();
      this.getReward();
      this.getBalanceOf();
      this.getpoolReward();
      this.getUserTvl();
      this.blackListCheck();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.choice {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 6px;
  margin-top: 20px;
  div {
    flex: 1;
    font-size: 10px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #666;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 6px 6px 2px 2px;
    padding: 4px 0;
    cursor: pointer;
    .small_triangle {
    }
  }
  .special {
    color: #333;
    // box-shadow: 0px 3px 3px 1px rgba(255, 210, 69, 0.3);
    border: 1px solid #ffd245;
  }
}
.mining {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 0 0 10px;
  overflow: hidden;
  margin: 10px 0 20px;
  .mining_head {
    display: flex;
    justify-content: space-between;
    // box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 20px 14px 0px;
    .head_left {
      display: flex;
      // align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding: 10px 0;
      .head_img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        transform: translate(0%, -10%);
      }
      .special_img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        transform: translate(-40%, 10%);
      }
    }
    .head_right {
      display: flex;
      padding-top: 10px;
      .exhibition {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        color: #666666;
        margin-right: 40px;
        .value {
          color: #333333;
          margin-top: 6px;
        }
      }
    }
  }
  .content {
    padding: 4px 24px 14px;
    .earned {
      display: flex;
      justify-content: space-between;
      background: #f8f7f7;
      border-radius: 10px;
      padding: 20px;
      margin-top: 20px;
      .earned_left {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        .earned_title {
          font-size: 12px;
          color: #666666;
          margin-bottom: 10px;
        }
      }
      .button {
        display: flex;
        align-items: flex-end;
        .confirmBtn {
          height: auto;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          border-radius: 16px;
          padding: 4px 20px;
        }
        .button_img {
          width: 26px;
          height: 26px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .staked {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f8f7f7;
      border-radius: 10px;
      padding: 10px 20px;
      margin-top: 20px;
      .staked_title {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .confirmBtn {
        height: auto;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        border-radius: 16px;
        padding: 4px 59px;
        margin-top: 20px;
      }
    }
    .tvl {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      background: #ffffff;
      border: 1px solid #ffd245;
      // box-shadow: 0px 1px 2px 1px #ffd245;
      border-radius: 10px;
      padding: 6px 12px;
      margin-top: 12px;
      div {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
      .money_img {
        width: 16px;
        height: 16px;
      }
    }
    .specific_data {
      background: #ffffff;
      border-radius: 10px;
      margin-top: 16px;
      .specific {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        font-family: Segoe UI-Bold, Segoe UI;
        font-weight: bold;
        color: #333333;
        margin-top: 10px;
        .special {
          color: #ffd245;
        }
        .company {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>