<template>
  <div class="creation">
    <div class="grade">
      <img src="@/assets/img/game_pictures.png"
           class="lianchuang" />
      <span class="grade_text">GAME AGGREGATION PLATFORM</span>
    </div>

    <div class="content">
      <div class="donate">
        <div>
          <span class="subtitle">{{ $t('lang.swap243') }}</span>
          <span class="value">{{ memberData[member].value }}</span>
          <span class="subtitle">GAPBOX</span>
        </div>

        <!-- <div class="wait">
          <span>{{ $t('lang.swap241') }}</span>
        </div> -->
      </div>
      <div class="button">
        <van-button type="disabled"
                    class="swapBtn"
                    v-if="!parent">{{ $t('lang.swap271') }}</van-button>
        <van-button type="primary"
                    class="swapBtn"
                    v-else-if="memberData[member].isApprove"
                    @click="approve"
                    loading-text="Waiting..."
                    :loading="showApprove">{{ $t('lang.swap14') }}</van-button>
        <van-button type="primary"
                    class="swapBtn"
                    v-else
                    loading-text="Waiting..."
                    @click="donation"
                    :loading="showDonation">{{ $t('lang.swap259') }}</van-button>
      </div>
      <div class="explain">
        <div class="explain_title">
          <span>--{{ $t('lang.swap151') }}--</span>
        </div>
        <div class="explain_list">
          <span>{{ $t('lang.swap255') }}</span>
        </div>
      </div>
    </div>
    <van-popup v-model="blindBoxPopupShow"
               round>
      <BlindBoxPopup @popup="popup"
                     :img="imgUrl"
                     :hashrate="hashrate" />
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import usdt from '../../methods/usdt.js';
import { blindBox } from '../../api/index';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import BlindBoxPopup from './BlindBoxPopup.vue';
import { coin } from '../../api/index';
export default {
  data () {
    return {
      memberData: [{ img: require('@/assets/img/game_pictures.png'), value: 1, progress: 0, state: false, isApprove: false }],
      // 当前选择的
      member: 0,
      otherData: {},
      showApprove: false,
      showDonation: false,
      balance: '',
      blindBoxPopupShow: false, //弹窗状态
      imgUrl: '',
      parent: '',
      hashrate: {
        price:0,
        incomeRate: 0,
      },
    };
  },
  created () {
    this.init();
  },

  methods: {
    switchButton (show) {
      if (show) {
        this.$refs.swipe.prev();
      } else {
        this.$refs.swipe.next();
      }
    },
    async getBalance () {
      const erc20Contract = getErc20Contract('0xA06Ff72C7A5a45679385Eb6abD02fEd8E7dF6e88');
      const balance = await erc20Contract.methods.balanceOf(this.account).call();
      this.balance = formatAmount(balance, 18);
    },

    init () {
      this.handleApproveBtnShow();
      this.getBalance();
      this.memberInfo()
    },

    // 关闭或打开弹窗
    popup () {
      this.blindBoxPopupShow = !this.blindBoxPopupShow;
    },

    async memberInfo () {
      coin.memberInfo(this.account).then(res => {
        if (res.code == 200) {
          this.parent = res.data.parent;
          
        }
      });
    },

    //判断是否授权
    async handleApproveBtnShow () {
      //捐赠地址
      const to = '0xDf73F08B07b8017b7295293804D70b7eE3a7C11D';
      if (this.account) {
        let erc20ContractTo = getErc20Contract('0xA06Ff72C7A5a45679385Eb6abD02fEd8E7dF6e88');
        this.allowanceTo = await erc20ContractTo.methods.allowance(this.account, to).call();
        if (this.allowanceTo == 0) {
          this.memberData[0].isApprove = true;
          // this.memberData[1].isApprove = true;
        } else {
          this.memberData[0].isApprove = false;
          // this.memberData[1].isApprove = false;
        }
      }
    },

    //授权
    approve () {
      this.showApprove = true;
      const erc20Contract = useErc20Contract('0xA06Ff72C7A5a45679385Eb6abD02fEd8E7dF6e88');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0xDf73F08B07b8017b7295293804D70b7eE3a7C11D', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.showApprove = false;
          this.memberData[0].isApprove = false;
        })
        .on('error', (error, receipt) => {
          this.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    donation () {
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap186')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      this.showDonation = true
      if (this.balance < this.memberData[this.member].value) {
        this.$toast(this.$t('lang.swap11'));
        this.showDonation = false
        return;
      }
      let amount = this.memberData[this.member].value * Math.pow(10, 18) + '';
      usdt
        .transfer(this.account, '0xDf73F08B07b8017b7295293804D70b7eE3a7C11D', amount, transactionsDetail,'0xA06Ff72C7A5a45679385Eb6abD02fEd8E7dF6e88')
        .then(res => {
          let data = {
            hash: res,
          };

          blindBox.mintMint(data, this.account).then(res => {
            this.showDonation = false
            if (res.data) {
              this.blindBoxPopupShow = true;
              this.hashrate.price = res.data.price
              this.hashrate.incomeRate = res.data.incomeRate
              this.imgUrl = res.data.imgUrl;
            } else {
              this.$toast(this.$t('lang.swap261'));
            }
          });
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('confirmation', res => { })
        .on('error', (error, receipt) => {
          this.showDonation = false
          this.$toast(this.$t('lang.swap76'));
        });
    },
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  components: {
    BlindBoxPopup,
  },
};
</script>

<style lang="less" scoped>
.creation {
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  .grade {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(255, 255, 255, 0);
    // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 10px;
    // padding: 20px 0;
    margin: 20px 0 10px;
    .lianchuang {
      display: block;
      width: 100%;
      height: 200px;
    }
    .grade_text {
      position: absolute;
      left: 50%;
      bottom: 0;
      color: #e2b018;
      transform: translateX(-50%) translateY(-50%);
      white-space: nowrap; //溢出不换行
    }
  }
  .content {
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 10px;
    padding: 10px 20px;
    .percentage {
      width: 100%;
      height: 5px;
      background: #e9e9e9;
      border-radius: 16px;
      border: 1px solid #ffd245;
      overflow: hidden;
      margin-top: 20px;

      span {
        display: block;
        width: 50%;
        height: 100%;
        background: #ffd245;
      }
    }
    .prompt {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 10px;
      .special {
        font-size: 14px;
        color: #ffd245;
      }
      .small {
        font-size: 10px;
      }
    }
    .donate {
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      border: 1px solid #ffd245;
      border-radius: 6px;
      text-align: center;
      padding: 10px 0;
      margin: 30px 0;
      .subtitle {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
      .value {
        color: #ffd245;
        font-size: 14px;
        margin: 0 6px;
      }
      .wait {
        color: #ffd245;
        margin-top: 10px;
      }
    }
    .donated {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #333333;
      margin: 30px 0 26px;
      .donated_value {
        font-size: 10px;
        color: #ffd245;
      }
    }
    .button {
      .swapBtn {
        width: 100%;
        height: auto;
        font-size: 18px;
        color: #333333;
        padding: 6px 0;
      }
    }
    .explain {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      color: #666666;
      margin: 38px 0 30px;
      .explain_title {
        text-align: center;
        margin-bottom: 10px;
      }
      .explain_list {
        display: flex;
        flex-direction: column;
      }
    }

    .balance {
      margin-top: 10px;
      text-align: left;
      .balance-text {
        font-size: 14px;
      }
    }
  }
}
</style>