import Vue from 'vue';
import VueRouter from 'vue-router';
import SwapIndex from '../views/swap/Index.vue';

import Swap from '../views/swap/Swap.vue';
import FlashExchange from '../views/swap/FlashExchange.vue';
import VisualChart from '../views/swap/VisualChart.vue';
import Home from '../views/Home.vue';
import LiquidityIndex from '../views/liquidity/Index.vue';
import Liquidity from '../views/liquidity/Liquidity.vue';
import importMining from '../views/liquidity/importMining.vue';
import Remove from '../views/liquidity/remove.vue';
import boardOfDirectors from '../views/boardOfDirectors/Index.vue';
import Creation from '../views/boardOfDirectors/Creation.vue';
import Clsm from '../views/boardOfDirectors/Clsm.vue'
import BlindBox from '../views/boardOfDirectors/BlindBox.vue'
import StartingCenter from '../views/boardOfDirectors/StartingCenter.vue';
import Game from '../views/boardOfDirectors/Game.vue';
import NoticeIndex from '../views/notice/Index.vue';
import Notice from '../views/notice/notice.vue';
import Invitation from '../views/invitation/Index.vue';
import Index from '../views/index/index.vue';
import Mining from '../views/index/Mining.vue';
import Feedback from '../views/index/Feedback.vue';
import StakePool from '../views/index/StakePool.vue';
import Blank from '../views/swap/Blank.vue';
import Understand from '../views/understand/Index.vue';
import GameFi from '../views/gameFi/Index.vue';
import Sgf from '../views/gameFi/Sgf.vue';
import Cards from '../views/gameFi/Cards.vue';
import gapCards from '../views/gameFi/gapCards.vue';
import MyRecommend from '../views/gameFi/MyRecommend.vue';
import Govern from '../views/govern/index.vue';
import governCenter from '../views/govern/governCenter.vue';

// import boardOfDirectors from '../views/boardOfDirectors/Index.vue'
Vue.use(VueRouter);
const routes = [

  {
    path: '/',
    redirect: 'SwapIndex/Swap',
    component: Home,
    children: [
      {
        path: 'Index',
        name: 'Index',
        component: Index,
      },
      {
        path: 'SwapIndex',
        name: 'SwapIndex',
        component: SwapIndex,
        children: [
          {
            path: 'Swap',
            name: 'Swap',
            component: Swap,
          },
          {
            path: 'LiquidityIndex',
            name: 'LiquidityIndex',
            component: LiquidityIndex,
          },
          {
            path: 'Liquidity',
            name: 'Liquidity',
            component: Liquidity,
          },
          {
            path: 'importMining',
            name: 'importMining',
            component: importMining,
          }
        ],
      },
      {
        path: 'Remove',
        name: 'Remove',
        component: Remove,
      },
      // {
      //   path: 'FlashExchange',
      //   name: 'FlashExchange',
      //   component: FlashExchange,
      // },
      {
        path: 'NoticeIndex',
        name: 'NoticeIndex',
        component: NoticeIndex,
      },
      {
        path: 'Notice',
        name: 'Notice',
        component: Notice,
      },
      {
        path: 'Invitation',
        name: 'Invitation',
        component: Invitation,
      },

      {
        path: 'Mining',
        name: 'Mining',
        component: Mining,
      },
      {
        path: 'Feedback',
        name: 'Feedback',
        component: Feedback,
      },
      {
        path: 'Blank',
        name: 'Blank',
        component: Blank,
      },
      {
        path: 'Creation',
        name: 'Creation',
        component: Creation,
      },
      {
        path: 'Clsm',
        name: 'Clsm',
        component: Clsm,
      },
      {
        path: 'StartingCenter',
        name: 'StartingCenter',
        component: StartingCenter,
      },
      {
        path: 'Game',
        name: 'Game',
        component: Game,
      },
      {
        path: 'Understand',
        name: 'Understand',
        component: Understand,
      },
      {
        path: 'StakePool',
        name: 'StakePool',
        component: StakePool,
      },
      {
        path: 'BlindBox',
        name: 'BlindBox',
        component: BlindBox,
      },
      {
        path: 'GameFi',
        name: 'GameFi',
        component: GameFi,
      },
      {
        path: 'Sgf',
        name: 'Sgf',
        component: Sgf,
        children: [
          {
            path: 'Cards',
            name: 'Cards',
            component: Cards,
          },
          {
            path: 'MyRecommend',
            name: 'MyRecommend',
            component: MyRecommend,
          },
        ],
      },
      {
        path: 'gapCards',
        name: 'gapCards',
        component: gapCards,
      },
      {
        path: 'govern',
        name: 'govern',
        component: Govern,
      },     
       {
        path: 'governCenter',
        name: 'governCenter',
        component: governCenter,
      },
    ],
  },
  {
    path: '/boardOfDirectors',
    name: 'boardOfDirectors',
    component: boardOfDirectors,
  },
  {
    path: '/VisualChart',
    name: 'VisualChart',
    component: VisualChart,
  }

];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  // base: './',
  routes,
});

export default router;
