import request from '../utils/request';

export default {
    //购买条件
    buyComdition(data, address) {
        return request.request({
            url: '/user-center/member/buy-condition',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data
        })
    },

    //董事长成员
    memberList(address) {
        return request.request({
            url: '/user-center/member/list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
        })
    },

    //奖励列表
    rewardList(address) {
        return request.request({
            url: '/reward/list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },

        })
    },

    //购买会员
    buyMember(data, address) {
        return request.request({
            url: '/user-center/member/buy',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data
        })
    },

    //获取tvl
    userTvl(address) {
        return request.request({
            url: '/member/info/tvl',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
        })
    },

    //添加tvl记录
    userTvlLog(address) {
        return request.request({
            url: '/user-center/lp-log',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
        })
    },

    //取出奖励
    rewardOut(data, address) {
        return request.request({
            url: '/order/reward-out',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data
        })
    },

    //注册用户
    getAccount(data, address) {
        return request.request({
            url: '/member/add',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data
        })
    },

    //添加反馈
    feedBack(data, address) {
        return request.request({
            url: '/feedback/add',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data
        })
    },

    //修改上级
    bindUpdate(data, address) {
        return request.request({
            url: '/member/bind/updata',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data
        })
    },
    //会员信息
    memberInfo(address) {
        return request.request({
            url: '/member/info',
            method: "GET",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
        })
    },
    //领取奖励
    memberReward(data,address) {
        return request.request({
            url: '/member/reward',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
            data:data
        })
    },
    //奖励记录
    memberRewardRecord(address) {
        return request.request({
            url: '/member/reward/record',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
        })
    },
    //算力榜
    memberPower(address) {
        return request.request({
            url: '/member/power',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': 'zh-CN'
            },
        })
    },
}