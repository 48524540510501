export const lang = {
  swap1: '闪兑',
  swap2: '立刻开始闪兑',
  swap3: '从',
  swap4: '余额: ',
  swap5: '选择币种',
  swap6: '最大',
  swap7: '价格',
  swap8: '滑点容差',
  swap9: '连接钱包',
  swap10: '输入数量',
  swap11: '余额不足',
  swap12: '此交易的流动性不足',
  swap13: '选择代币',
  swap14: '授权',
  swap15: '包装',
  swap16: '解包',
  swap17: '闪兑',
  swap18: '交易收据',
  swap19: '正在等待确认',
  swap20: '正在将',
  swap21: '交换为',
  swap22: '在你的钱包种确认此交易',
  swap23: '已提交交易',
  swap24: '在BscScan上查看',
  swap25: '关闭',
  swap26: '交易拒绝',
  swap27: '确认交换',
  swap28: '输出为估值，您将获得至少{inputOutMin} {symbol},或者交易将被撤',
  swap29: '输入为估值，您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap30: '最小获得量',
  swap31: '最大出售量',
  swap32: '到',
  swap33: '管理代币',
  swap34: '导入代币',
  swap35: '导入',
  swap36: '管理Tokens',
  swap37: '自定义代币',
  swap38: '全部清除',
  swap39: '搜索名称或粘贴地址',
  swap40: '设置',
  swap41: '默认交易速度(GWEI)',
  swap42: '标准',
  swap43: '快速',
  swap44: '即时',
  swap46: '输入有效的滑点百分比',
  swap47: '你的交易可能会失败',
  swap48: '你的交易可能会被超前',
  swap49: '交易截止时间(分钟)',
  swap50: '估计',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近没有交易',
  swap54: '全部清除',
  swap55: '未连接',
  swap56: '加入董事会',
  swap57: '捐赠',
  swap58: '权益赠送',
  swap59: '董事会成员列表',
  swap60: '邀请',
  swap61: '复制',
  swap62: '我的DAPP邀请人:',
  swap63: '邀请记录',
  swap64: '出错啦，请稍后再试',
  swap65: '即将开放',
  swap66: '领取',
  swap67: '取出成功',
  swap68: '捐赠中，待确认',
  swap69: '已捐赠',
  swap70: '非白名单用户',
  swap71: '捐赠无效',
  swap72: '当前余额不足',
  swap73: '授权中，请稍等',
  swap74: '授权失败',
  swap75: '捐赠中，请稍等',
  swap76: '捐赠失败',
  swap77: '兑换',
  swap78: '流动性',
  swap79: '董事会',
  swap80: '邀请',
  swap81: '权益池',
  swap82: '资产桥',
  swap83: '池子',
  swap84: '农场',
  swap85: '首页',
  swap86: '市场',
  swap87: '兑换',
  swap88: '交易',
  swap89: '赚取',
  swap90: '确认',
  swap91: '价格影响',
  swap92: '最小获得量',
  swap93: '流动性供应商费用',
  swap94: '增加流动性',
  swap95: '增加流动性以接收 LP 代币',
  swap96: '流动性池中的份额',
  swap97: '兑换率和流动池份额',
  swap98: '您的流动性',
  swap99: '移除流动性以收回代币',
  swap100: '未找到流动性',
  swap101: '未看到您加入的流动池？',
  swap102: '查看其他LP代币',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供应',
  swap106: '导入池',
  swap107: '导入现有流动性池',
  swap108: '选择代币以查找您的流动性。',
  swap109: '您在该流动性池中还没有提供流动性。',
  swap110: '您的钱包中的LP代币',
  swap111: '接收',
  swap112: '和',
  swap113: '金额',
  swap114: '你将获得',
  swap115: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
  swap116: '已焚毁',
  swap117: '已入金',
  swap118: '确认供应',
  swap119: '汇率',
  swap120: '您是第一个流动性供应商。',
  swap121: '您添加代币的比列，将决定该流动性池的初始价格。',
  swap122: '如果您对汇率满意，请点击“供应”以进行检查。',
  swap123: '正在移除',
  swap124: '正在供应',
  swap125: '确认供应',
  swap126: '白皮书',
  swap127: '审计',
  swap128: '公告',
  swap129: '提示',
  swap130: '原Swan与新Swan 1:1 兑换',
  swap131: '更多',
  swap132: '正在加载',
  swap133: '信息',
  swap134: '捐赠名额已满',
  swap135: '我的SWAN',
  swap136: '钱包中的SWAN',
  swap137: '可收割的SWAN',
  swap138: 'SWAN价格',
  swap139: 'SWAN总量',
  swap140: 'SWAN市值',
  swap141: 'SWAN信息',
  swap142: '已赚取',
  swap143: '质押',
  swap144: '个人TVL',
  swap145: '社区TVL',
  swap146: '收割',
  swap147: '累计激励 ',
  swap148: '今日激励',
  swap149: '参与市商地址数',
  swap150: '我的邀请人:',
  swap151: '说明',
  swap152: '拥有推荐地址的用户为有效地址，有效地址可以向其他链上地址进行推荐，若您还未拥有邀请地址，请联系您的邀请者或者通过反馈向我们告知。',
  swap153: '修改',
  swap154: '取消',
  swap155: '修改邀请人',
  swap156: '邀请人不能为空',
  swap157: '修改成功',
  swap158: '质押LP代币',
  swap159: '赎回LP代币',
  swap160: '赎回',
  swap161: '获取',
  swap162: '当前暂无收益',
  swap163: '标题',
  swap164: '内容',
  swap165: '地址',
  swap166: '回复邮箱',
  swap167: '提交',
  swap168: '请填写',
  swap169: '正确的回复邮箱',
  swap170: '路由',
  swap171: '价格影响过高',
  swap172: '反馈',
  swap173: '此交换的价格影响至少为 10%,请确认此交换',
  swap174: "专家模式",
  swap175: "主流币",
  swap176: "DEFI",
  swap177: "SWAN",
  swap178: "首发中心",
  swap179: "进入",
  swap180: "已完成进度",
  swap181: "总需捐献",
  swap182: "1：本次Darwin领袖招募信息由Darwin提供并负责。",
  swap183: "2：本次招募可超额进行，由Darwin社区进行考核，未予通过的领袖席位将自动退回捐献资金。",
  swap184: "联创领袖",
  swap185: "社区领袖",
  swap186: "捐献",
  swap187: "达尔文领袖",
  swap188: "Darwin AI Technology System是一款具有自我成长的链上智能AI套利系统，通过监听链上所有代币交易当中有利可图的滑点机会，进行Front Running（抢先提前）、Flash Loan（闪电贷）等交易，从而实现瞬时超额利润。",
  swap189: "招募计划",
  swap190: "已捐献",
  swap191: "捐献中,请等待",
  swap192: "众筹",
  swap193: "治理",
  swap194: "了解",
  swap195: "挂单",
  swap196: "聚合",
  swap197: "赚币",
  swap198: "社区",
  swap199: "请先添加邀请人",
  swap200: "复制成功",
  swap201: "不支持复制,该浏览器不支持自动复制",
  swap202: "链游市场",
  swap203: "自由市场",
  swap204: "无损挖矿",
  swap205: "抵押借贷",
  swap206: "NFT专区",
  swap207: "合作伙伴",
  swap208: "Swan DAO 致力于创建一个大型的社区型DAO组织，为了实现DAO梦想，聚拢社区成员，打造百万人社区，成立Swan Finance Foundation（基金会），由基金会牵头，致力于建造一个多链聚合DEFI生态，囊括但不仅限于建设DEX+NFT+Socialfi+Gamefi+WB3等一众基础设施。",
  swap209: "为有序推动生态，Swan Finance作为首个生态应用方式推出，基于标准的AMM自动做市机制，为用户提供高效的交易服务，V1版本为深度聚合Pancake交易，V2版本为Swan Finance交易方案。",
  swap210: "Swan是Swan DAO首个共识型代币，总量29000枚，采取交易税收机制，单边收取3%交易税收，用以鼓励社区发展。",
  swap211: "Swan Finance以务实、持续、发展、社区为理念，保持开放态度，欢迎各平台方申请合作，提供代币上架、平台收录、技术支持、营销孵化等",
  swap212: "联系方式",
  swap213: "邮件：Support@SwanFinance.cc",
  swap214: "反馈：反馈栏提交相关信息",
  swap215: "修改失败",
  swap216: '慈善联盟',
  swap217: '慈善联盟以爱为中心，慈善为主轴，由各界爱心人士发起。用DeFi+DAO方式，展现公平公正公开，共创共建共赢，与同频共振，有志之士联盟、优质项目联盟，打造DAO文化教育核心团队社区，以生态赋能聚合平台，让更多想干实事，而缺乏好平台发挥能力的人，提供展示的平台。',
  swap218: '1：本次招募由CSLM方面提供。',
  swap219: '2：相关信息由CSLM负责并进行解答。',
  swap220: '其他业绩区TVL',
  swap221: '最大业绩区TVL',
  swap222: '待收割奖励',
  swap223: '已收割奖励',
  swap224: '取出',
  swap225: '收割成功',
  swap226: '收割失败,请稍后重试',
  swap227: '推荐奖励',
  swap228: '暂无记录',
  swap229: '算力奖励',
  swap230: '时间',
  swap231: '数量',
  swap232: '类型',
  swap233: '个人算力',
  swap234: '暂无排名',
  swap235: '已结束',
  swap236: '盲盒预售',
  swap237: '根据SWAN DAO投研中心数据分析报告显示，2022-2023年度，GameFi市场将迎来爆发增长，Swan Finance将联合世界各地知名游戏团队一起打造属于天鹅交易所的游戏盛宴，并率先与欧洲链游提供商Hey Fun达成战略合作，其业务在迪拜和日本市场已取得广泛认可。此次合作的第一战略任务——顺势推出链游板块唯一代币SGF。',
  swap238: '1: 本次HEY FUN盲盒购买信息由HEY FUN项目方提供并负责。',
  swap239: '2: 盲盒需要使用等值USDT的SGF支付。',
  swap240: '3: 开启盲盒获得的卡牌请到链游中心查看。',
  swap241: '敬请等待',
  swap242: '暂未开放',
  swap243: '售价',
  swap244: '卡牌中心',
  swap245: 'HEY FUN卡牌',
  swap246: '我的信息',
  swap247: '我的卡牌',
  swap248: '我的等级',
  swap249: '高级盲盒',
  swap250: '创世盲盒',
  swap251: '待领取的收益',
  swap252: '高级盲盒用户',
  swap253: '创世盲盒用户',
  swap254: '无',
  swap255: '1: 本次GAP盲盒购买信息由GAP游戏公会联盟提供并负责。',
  swap256: 'Game aggregation platform是全球首个会员制元宇宙聚合游戏平台，致力于打造一个区块链游戏和传统网络游戏相结合的聚合平台，构建连接游戏用户，游戏公户和游戏开发商的一个多元化服务平台，并分别为平台内的三种角色提供游戏中心，流量赋能和技术支持等相关服务。',
  swap257: '暂无卡牌',
  swap258: '购买中，请稍等...',
  swap259: '购买盲盒',
  swap260: '激活',
  swap261: '稍后请到卡牌中心查看',
  swap262: '创世用户',
  swap263: '暂无等级',
  swap264: '恭喜您获得',
  swap265: '确定',
  swap266: '高级卡牌',
  swap267: '创世卡牌',
  swap268: '稀有卡牌',
  swap269: '传世卡牌',
  swap270: '没有更多了',
  swap271: '尚无购买资格',
  swap272: 'Game aggregation platform是全球首个会员制元宇宙聚合游戏平台，致力于打造一个区块链游戏和传统网络游戏相结合的聚合平台，构建连接游戏用户，游戏公户和游戏开发商的一个多元化服务平台，并分别为平台内的三种角色提供游戏中心，流量赋能和技术支持等相关服务',
  swap273: '算力',
  swap274: '治理中心',
  swap275: '我质押的',
  swap276: '待领取',
  swap277: '自然释放',
  swap278: '团队加速',
  swap279: '推荐加速',
  swap280: '普通盲盒',
  swap281:'总释放价值',
  swap282:'释放进度',
  swap283:'总释放天数',
  swap284:'倍数',
  swap285:'价值',
  swap286:'等值',
};
