<template>
  <div class="starting_center">
    <div class="title">
      <span>{{ $t('lang.swap178') }}</span>
    </div>
    <div class="content">
      <div class="content_top">
        <div class="startingCenter">
          <img src="@/assets/img/SWANGAMEFI.png"
               class="startingCenter_img" />
        </div>
        <div class="content_title">
          <span>HEY FUN</span>
          <div class="recruitment">
            <span>{{ $t('lang.swap236') }}</span>
          </div>
          <div>
            <van-button type="primary"
                        class="content_button"
                        @click="clsm('BlindBox')">{{ $t('lang.swap179') }}</van-button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="text">
        <span>
          {{ $t('lang.swap237') }}
        </span>
      </div>
    </div>
    <div class="content">
      <div class="content_top">
        <div class="startingCenter">
          <img src="@/assets/img/game_logo.png"
               class="startingCenter_img" />
        </div>
        <div class="content_title">
          <span>GAP</span>
          <div class="recruitment">
            <span>{{ $t('lang.swap236') }}</span>
          </div>
          <div>
            <van-button type="primary"
                        class="content_button"
                        @click="clsm('Game')">{{ $t('lang.swap179') }}</van-button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="text">
        <span>
          {{ $t('lang.swap256') }}
        </span>
      </div>
    </div>

    <!-- <div class="content">
      <div class="content_top">
        <div class="startingCenter">
          <img src="@/assets/img/clsm.png"
               class="startingCenter_img" />
        </div>
        <div class="content_title">
          <span>{{$t('lang.swap216')}}</span>
          <div class="recruitment">
            <span>{{ $t('lang.swap189') }}</span>
          </div>
          <div>
            <van-button type="primary"
                        class="content_button"
                        @click="clsm('Clsm')">{{ $t('lang.swap179') }}</van-button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="text">
        <span>
          {{ $t('lang.swap217') }}
        </span>
      </div>
    </div> -->

    <!-- <div class="content">
      <div class="content_top">
        <div class="startingCenter">
          <img src="@/assets/img/startingCenter.png"
               class="startingCenter_img" />
        </div>
        <div class="content_title">
          <span>{{ $t('lang.swap187') }}</span>
          <div class="recruitment">
            <span>{{ $t('lang.swap189') }}</span>
          </div>
          <div>
            <van-button type="primary"
                        class="content_button"
                        @click="creation">{{ $t('lang.swap179') }}</van-button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="text">
        <span>
          {{ $t('lang.swap188') }}
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  methods: {
    creation () {
      this.$router.push({ name: 'Creation' });
    },
    clsm (name) {
      // this.$toast(this.$t('lang.swap65'));
      this.$router.push({ name: name });
    }
  },
};
</script>

<style lang="less" scoped>
.starting_center {
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  .title {
    font-size: 24px;
    color: #333333;
    margin: 30px 0 20px;
  }
  .content {
    font-size: 10px;
    color: #333333;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 16px;
    padding: 30px 10px;
    margin-top: 50px;
    .content_top {
      display: flex;
      .startingCenter {
        position: relative;
        flex: 5;
        .startingCenter_img {
          position: absolute;
          width: 200px;
          transform: translateX(0%) translateY(-40%);
        }
      }
      .content_title {
        flex: 3;
        font-size: 16px;
        color: #5b3106;
        text-align: center;
        .recruitment {
          font-size: 20px;
        }
      }
    }

    .text {
      padding: 0 10px;
    }
    .line {
      border: 1px solid #c7c7c7;
      margin: 10px 0;
    }
    .content_button {
      // width: 100%;
      height: auto;
      font-size: 16px;
      color: #333333;
      padding: 8px 22px;
      border-radius: 20px;
      margin-top: 10px;
    }
  }
}
</style>