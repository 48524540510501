
import exchangeAbi from '../config/abi/exchange.json'
import Vue from 'vue';
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import store from '../store';
function dochange(amount, address,transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance;
        crossingOkContractInstance = new web3.eth.Contract(exchangeAbi, '0xfF86af2E5d37566E15cA7Bd400BF68A2bC0400A5');
        crossingOkContractInstance.methods.doExchange(0, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).send({ from: address })
            .on('transactionHash', res => {
                transactionsDetail['hash'] = res
                store.commit('SETTRANSACTIONS', transactionsDetail);
            }).on('confirmation', res => {
                resolve(res)
            }).on('error', res => {
                reject(res)  //失败
            })
    })
}

export default {
    dochange
}

