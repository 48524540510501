import request from '../utils/request';

export default {
	//购买盲盒
	boxBuy(data, address) {
		return request.request({
			url: '/box/buy',
			method: "post",
			headers: {
				Authorization: address,
			},
			data: data
		})
	},
	//盲盒信息
	boxList(address, language) {
		return request.request({
			url: '/box/list',
			method: "get",
			headers: {
				Authorization: address,
			},
		})
	},

	//我的卡牌
	cardInfo(data, address) {
		return request.request({
			url: '/member/card/info',
			method: "get",
			headers: {
				Authorization: address,
			},
			params: data
		})
	},

	//我的gap卡牌
	gapCardInfo(data, address) {
		return request.request({
			url: '/member/gap/card/info',
			method: "get",
			headers: {
				Authorization: address,
			},
			params: data
		})
	},

	//盲盒邀请信息
	boxInvite(address) {
		return request.request({
			url: '/member/box/invite',
			method: "get",
			headers: {
				Authorization: address,
			},
		})
	},

	//铸造
	mintMint(data, address) {
		return request.request({
			url: '/mint/mint',
			method: "post",
			headers: {
				Authorization: address,
			},
			data: data
		})
	}
}