<template>
  <div class="liquidity_list">
    <div>
      <div class="headWrap">
        <div>
          <span>{{ $t("lang.swap98") }}</span>
        </div>
        <div class="headWrap_img">
          <img src="@/assets/img/set.png"
               @click="handleSettingShow"
               alt
               class="iconSet" />
          <img src="@/assets/img/record.png"
               @click="handleRecordShow"
               alt
               class="iconRecord" />
        </div>
      </div>
      <div class="remove">
        <span>{{ $t("lang.swap99") }}</span>
      </div>
    </div>

    <div class="liquidity_explain">
      <div class="liquidity_data">
        <van-collapse v-model="activeNames"
                      v-for="(item, index) of list"
                      :key="index">
          <van-collapse-item :name="index">
            <template #title>
              <div class="title">
                <!-- <img class="busd" src="@/assets/img/busd.png" alt />
                <img class="busd" src="@/assets/img/busd.png" alt />-->
                <span>{{ item.fromSymbol }}</span>/
                <span>{{ item.toSymbol }}</span>
              </div>
              <div>{{ significantDigits((item.lpBalance) / Math.pow(10,18)) }}</div>
            </template>
            <div class="content">
              <div class="detail">
                <div class="detail_left">
                  <img :src="item.fromLogoURI"
                       class="busd" />
                  <span>{{ $t("lang.swap103") }} {{ item.fromSymbol }}:</span>
                </div>
                <div class="detail_amount">
                  <span>{{ (item.fromBalance*1).toFixed(9) }}</span>
                </div>
              </div>
              <div class="detail">
                <div class="detail_left">
                  <img :src="item.toLogoURI"
                       class="busd" />
                  <span>{{ $t("lang.swap103") }} {{ item.toSymbol }}:</span>
                </div>
                <div class="detail_amount">
                  <span>{{ (item.toBalance*1).toFixed(9) }}</span>
                </div>
              </div>
              <div class="detail">
                <div class="detail_left">
                  <span>{{ $t("lang.swap96") }}</span>
                </div>
                <div class="detail_amount">
                  <span v-if="item.percentage < 0.01">{{ percentageMin }}</span>
                  <span v-else-if="item.percentage >= 100">
                    {{
                    percentageMax
                    }}
                  </span>
                  <span v-else>{{ item.percentage }}%</span>
                </div>
              </div>
              <div class="mobility_button">
                <van-button type="primary"
                            class="swapBtn"
                            @click="handleSwap(item)">{{ $t("lang.swap104") }}</van-button>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="tips">
        <div v-show="mobilityList == []">
          <span>{{ $t("lang.swap100") }}</span>
        </div>
        <div class="interval">
          <span>{{ $t("lang.swap101") }}</span>
        </div>
        <div class="view_other"
             @click="liquidity('1')">
          <span>{{ $t("lang.swap102") }}</span>
        </div>
      </div>
    </div>

    <div class="button_swapBtn">
      <van-button type="primary"
                  class="swapBtn"
                  @click="liquidity('2')">+ {{ $t("lang.swap94") }}</van-button>
    </div>
    <van-popup v-model="transactionRecordShow"
               round>
      <transaction-record @listenClose="handleTransactionRecordClose" />
    </van-popup>
    <van-popup v-model="settingShow"
               round>
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
  </div>
</template>

<script>
import TransactionRecord from '@/views/swap/TransactionRecord.vue';
import SettingsModal from '@/components/SettingsModal.vue';
import { getJackPairContract, getJackFactoryContract } from '@/utils/contractHelp';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { mapState } from 'vuex';
// import DEFAULT_TOKEN_LIST from '@/config/constants/tokenList';
// import DEFAULT_TOKEN_DEFI_LIST from '@/config/constants/tokenDEFIList.json';
// import DEFAULT_TOKEN_SWAN_LIST from '@/config/constants/tokenSWANList.json';
import { BNB } from '@/config/constants/bnb';
import axios from 'axios';

export default {
  data () {
    return {
      transactionRecordShow: false,
      settingShow: false,
      mobilityList: [],
      activeNames: ['0'],
      list: [],
      storeLpList: [],
      percentageMin: '<0.01%',
      percentageMax: '>100%',
      switchVersionShow: '0',
      swanList: [],
      defiList: [],
      normalList: [],
    };
  },
  created () {
    this.getStoreList()

  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath', 'pool']),
  },
  methods: {
    getStoreList () {
      try {
        // if (this.pool.jackFactory == '0xca143ce32fe78f1f7019d7d551a6402fc5350c73') {
        //   this.storeLpList = localStorage.getItem('v1LpList') ? JSON.parse(localStorage.getItem('v1LpList')) : [];
        // } else {
        //   this.storeLpList = localStorage.getItem('v2LpList') ? JSON.parse(localStorage.getItem('v2LpList')) : [];
        // }
        this.storeLpList = localStorage.getItem('lpList') ? JSON.parse(localStorage.getItem('lpList')) : [];
        // this.$toast("走了这里")
        // this.$toast(this.storeLpList,"获取到的值")
        if (this.storeLpList) {
          this.getLpList(this.storeLpList);
        }
      } catch (e) {
        this.storeLpList = [];
      }
    },
    watchLpList () {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        //e.key : 是值发生变化的key
        //例如 e.key==="token";
        //e.newValue : 是可以对应的新值
        if (e.key === 'v1LpList' || e.key === 'v2LpList') {
          _this.getStoreList()
        }
      });
    },
    async getLpList (storeLpList) {
      // this.$toast(this.storeLpList[0].fromAddress,"走了这里");
      this.list = [];
      // await this.currencyList()
      if (storeLpList.length != 0) {
        for (let i = 0; i < storeLpList.length;i++) {
          
          let detail = {};
          let jackFactoryContract = getJackFactoryContract();
          
          let pairAddress = await jackFactoryContract.methods.getPair(this.storeLpList[i].fromAddress, this.storeLpList[i].toAddress).call();
          
          let jackPairContract = getJackPairContract(pairAddress);

          let lpBalance = await jackPairContract.methods.balanceOf(this.account).call();
          
          let showLpBalance = formatAmount(lpBalance) * 1;
          
          let allBalance = await jackPairContract.methods.totalSupply().call();
          let scale = accDiv(lpBalance, allBalance); //当前比例
          let reserves = await jackPairContract.methods.getReserves().call(); //获取当前总额
          let fromBalance;
          let toBalance;
          if (this.storeLpList[i].fromAddress.toLowerCase() < this.storeLpList[i].toAddress.toLocaleLowerCase()) {
            fromBalance = (reserves._reserve0 / Math.pow(10, this.storeLpList[i].fromDecimals)) * scale;
            toBalance = (reserves._reserve1 / Math.pow(10, this.storeLpList[i].toDecimals)) * scale;
          } else {
            fromBalance = (reserves._reserve1 / Math.pow(10, this.storeLpList[i].fromDecimals)) * scale;
            toBalance = (reserves._reserve0 / Math.pow(10, this.storeLpList[i].toDecimals)) * scale;
          }
          // this.$toast(fromBalance,"走了这里");
          detail['lpAddress'] = pairAddress; //lp地址
          detail['lpBalanceShow'] = showLpBalance; //lp展示余额
          detail['lpBalance'] = lpBalance; //lp余额
          detail['fromAddress'] = this.storeLpList[i].fromAddress; //form地址
          detail['fromSymbol'] = this.storeLpList[i].fromSymbol;
          detail['fromDecimals'] = this.storeLpList[i].fromDecimals;
          detail['toDecimals'] = this.storeLpList[i].toDecimals;
          detail['fromBalance'] = fromBalance; //from 余额
          detail['toAddress'] = this.storeLpList[i].toAddress; //to地址
          detail['toSymbol'] = this.storeLpList[i].toSymbol;
          detail['toBalance'] = toBalance; //to 余额
          detail['percentage'] = accDiv(lpBalance, allBalance) * 100; //份额
          detail['fromLogoURI'] = require('@/assets/img/default.png')
          detail['toLogoURI'] = require('@/assets/img/default.png')

          let currencyList = [...this.normalList, ...this.defiList, ...this.swanList, BNB];
          for (let i = 0; i < currencyList.length; i++) {
            if (currencyList[i].address == detail['fromAddress']) {
              detail['fromLogoURI'] = currencyList[i].logoUrl
            }
            if (currencyList[i].address == detail['toAddress']) {
              detail['toLogoURI'] = currencyList[i].logoUrl
            }
          }
          if (lpBalance != 0) {
            this.list.push(detail);
          }
        }
      }
    },
    significantDigits,
    handleSettingShow () {
      this.settingShow = true;
    },
    handleSettingClose () {
      this.settingShow = false;
    },
    handleRecordShow () {
      this.transactionRecordShow = true;
    },
    handleTransactionRecordClose () {
      this.transactionRecordShow = false;
    },
    //
    liquidity (i) {
      if (i == '2') {
        this.$router.push({ name: 'Liquidity' });
      } else if (i == '1') {
        this.$router.push({ name: 'importMining' });
      }
    },
    handleSwap (item) {
      this.$router.push({ name: 'Remove', query: { item: JSON.stringify(item) } });
    },
    currencyList () {
      axios.get('https://api.swanfinance.cc/swan.json').then(res => {
        this.swanList = res.data.tokens
      });
      axios.get('https://api.swanfinance.cc/defi.json').then(res => {

        this.defiList = res.data.tokens
      });
      axios.get('https://api.swanfinance.cc/normal.json').then(res => {
        this.normalList = res.data.tokens
      });
    }
  },
  components: {
    TransactionRecord,
    SettingsModal,
  },
};
</script>

<style lang="less" scoped>
.liquidity_list {
  background: #fff;
  // width: 100%;
  border-radius: 20px;
  // box-shadow: 0px 6px 32px rgba(13, 13, 13, 0.08);
  // border: 2px solid #f2f2f2;
  // border: 1px solid #707070;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);

  padding: 18px 0;
  margin-bottom: 10px;
  .headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding: 8px 16px;
    .iconSet {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .iconRecord {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      cursor: pointer;
    }
    .iconHash {
      width: 20px;
      height: 20px;
    }
    .headWrap_img {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(199, 199, 199, 0.2);
      border-radius: 14px;
      padding: 4px 14px;
    }
  }
  .remove {
    font-size: 10px;
    color: #666666;
    padding: 0 16px;
  }
  .liquidity_explain {
    // background: #fff;
    border-radius: 15px;
    // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

    padding: 14px 20px 34px;
    margin-top: 20px;
    .liquidity_data {
      /deep/ .van-hairline--top-bottom {
        overflow: hidden;
        background: #f5f5f5 !important;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

        border-radius: 15px;
        padding: 0;
        position: static;
        margin-bottom: 20px;
      }
      /deep/ .van-collapse-item__title {
        padding: 12px 16px !important;
        font-size: 10px;
        color: #333333;
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .busd {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 6px;
      }
      .content {
        .detail {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .detail_left {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #666666;
            // margin-left: 8px;
          }
          .detail_amount {
            font-size: 12px;
            color: #333333;
          }
        }
        .mobility_button {
          .swapBtn {
            width: 100%;
            font-size: 14px;
            color: #333333;
            border-radius: 15px;
            font-weight: bold;
            padding: 4px 0;
            // margin-top: 22px;
          }
        }
      }
    }

    .tips {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: #333333;
      .view_other {
        font-size: 16px;
        color: #ffd245;
        border: 1px solid #ffd245;
        border-radius: 16px;
        padding: 6px 18px;
        // margin-top: 52rpx;
      }
      .interval {
        margin: 32px 0 26px;
      }
    }
  }
  .button_swapBtn {
    padding: 0 24px;
    margin-top: 26px;
    .swapBtn {
      width: 100%;
      font-size: 17px;
      color: #333333;
      font-weight: bold;
      padding: 10px 0;
    }
  }
}

/deep/ .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
</style>