import request from '../utils/request';
export default {
    //公告列表
    getNoticeList(data, address, language) {
        return request.request({
            url: 'notice/list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
            params: data

        })
    },

    //获取公告详情
    getNoticeDetail(data, address, language) {
        return request.request({
            url: '/notice/detail',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language,
            },
            params: data
        })
    },

    //获取最新公告
    getNoticeLatest(address, language) {
        return request.request({
            url: '/notice/latest',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language,
            },
        })
    }
}