<template>
  <div class="center">
    <div class="li">
      <div class="subtitle">
        <span>{{$t('lang.swap275')}}SGF</span>
      </div>
      <div class="receive">
        <span>0.00000000</span>
      </div>
    </div>
    <div class="li">
      <div class="subtitle">
        <span>{{$t('lang.swap276')}}SGF</span>
      </div>
      <div class="receive">
        <span>0.00000000</span>
        <van-button type="primary"
                    class="receive_button"
                    @click="receiveClisk">{{$t('lang.swap66')}}</van-button>
      </div>
    </div>
    <div class="li">
      <div class="subtitle">
        <span>{{$t('lang.swap276')}}USDT</span>
      </div>
      <div class="receive">
        <span>0.00000000</span>
        <van-button type="primary"
                    class="receive_button"
                    @click="receiveClisk">{{$t('lang.swap66')}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data () {
    return {

    }
  },
  methods: {
    receiveClisk () {
      this.$toast(this.$t('lang.swap65'))
    }
  },
  computed: {
    ...mapState(['account']),
  },
}
</script>

<style lang="less" scoped>
.center {
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 30px 16px 2px;
  margin-top: 20px;
  .li {
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    background: #f3f3f3;
    border-radius: 10px;
    padding: 10px 16px;
    margin-bottom: 20px;
    .subtitle {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      margin-bottom: 30px;
    }
    .receive {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .receive_button {
        height: auto;
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        padding: 4px 20px;
      }
    }
  }
}
</style>