<template>
  <div class="invitation">
    <!-- 
    <div class="invitation_address">
      <div class="headline">
        <span class="headline_span">{{$t('lang.swap60')}}</span>
      </div>
      <div class="address">
        <span>{{invitee}}</span>
      </div>
      <div class="copy"
           @click="copy">
        <span>{{$t('lang.swap61')}}</span>
      </div>
    </div>-->
    <div class="invitation_record">
      <div class="my_invitee">
        <div>
          <span>{{$t('lang.swap150')}}</span>
          <div class="invitee">
            <span>{{ChainEferrer ||  $t('lang.swap199')}}</span>
          </div>
        </div>
        <div class="button">
          <!-- <van-button type="default"
                      class="confirmBtn"
                      v-if="!editable"
                      disabled>
            <img class="image"
                 src="@/assets/img/invitees.png" />
            <span>{{$t('lang.swap153')}}</span>
          </van-button> -->
          <!-- <van-button type="primary"
                      class="confirmBtn"
                      @click="handleConfirm"
                      v-else>
            <img class="image"
                 src="@/assets/img/invitee_add.png" />
            <span>{{$t('lang.swap153')}}</span>
          </van-button> -->
        </div>
      </div>
      <div class="my_invitee">
        <!-- <span>{{$t('lang.swap62')}}</span>
        <div class="invitee_address">
          <div class="tokenInput">
            <span>{{  invited}}</span>
          </div>
        </div>-->
      </div>
      <div class="explain">
        <span>{{$t('lang.swap151')}}:</span>
        <div>
          <span>{{$t('lang.swap152')}}</span>
        </div>
      </div>
      <div class="subtitle">
        <span>{{$t('lang.swap63')}}</span>
        <img class="image"
             src="@/assets/img/invitee_address.png" />
      </div>
      <div class="straight_line"></div>
      <div>
        <div class="record"
             v-for="(item,index) of invitationRecord"
             :key="index">
          <span>{{ item.substring(0, 7) + '*****' + item.substring(item.length - 7, item.length)}}</span>
        </div>
      </div>
    </div>

    <van-popup v-model="invitationPopupShow"
               round>
      <InvitationPopup @handleConfirm="handleConfirm"
                       @getMyAddress="getMyAddress"></InvitationPopup>
    </van-popup>
  </div>
</template>

<script>
import InvitationPopup from '@/views/invitation/InvitationPopup.vue';
import swan from '../../methods/swan.js';
import { invite } from '../../api/index';
import { mapState } from 'vuex';
import { coin } from '../../api/index';
export default {
  data () {
    return {
      invitationAddress: '', //邀请地址
      be_invited: '', //我的邀请人
      invitationRecord: [], //邀请记录
      invitee: '', //邀请人
      editable: true, //判断是否修改过
      invitationPopupShow: false, //弹窗状态
      chainInviteInfo: '',
      language: '',
      parentAddress: ''
    };
  },

  created () {
    this.language = localStorage.getItem('lang');
    this.init();
  },
  watch: {
    account () {
      //获取账号
      this.init();
    },
  },

  methods: {
    // 复制邀请地址
    async init () {
      if (this.account) {
        // await swan
        //   .inviter(this.account)
        //   .then(res => {
        //     this.chainInviteInfo = res;
        //   })
        //   .catch(err => {});
        this.getInfo();
      }
    },
    //绑定上级
    bind () {
      this.language = localStorage.getItem('lang');

      let data = {
        recommend_address: this.chainInviteInfo,
      };
      invite.userBind(data, this.account, this.language).then(res => {
        if (code == 200) {
          this.getinfo();
        }
      });
    },

    getInfo () {
      let data = {
        address: this.account,
      };
      coin.getAccount(data).then(res => {
        console.log("我是测试", res);
        this.chainInviteInfo = res.data.parent
        if (res.code == 200) {
          if (res.data.isChangeInvite == 0) {
            this.editable = false;
          } else {
            this.editable = true;
          }
        }
      });

      this.language = localStorage.getItem('lang');
      invite.inviteInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.invitationRecord = res.data;
        }
      });
    },
    // getinfo () {
    //   this.language = localStorage.getItem('lang');
    //   invite.inviteInfo(this.account, this.language).then(res => {
    //     if (res.code == 200) {
    //       this.invitationRecord = res.data.children;
    //       this.editable = res.data.editable;
    //       if (res.data.be_invited == '') {
    //         this.bind();
    //       } else {
    //         this.be_invited = res.data.be_invited
    //       }
    //     }
    //   });
    // },
    // 确认邀请人
    inviteeAddress () { },
    handleConfirm () {
      this.invitationPopupShow = !this.invitationPopupShow;
    },
    getMyAddress (res) {
      // this.invitationPopupShow = false
      // console.log(res);
      this.language = localStorage.getItem('lang');
      let data = {
        recommendAddress: res,
      };
      invite.inviteEdit(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap157'));
          this.invitationPopupShow = false;
          this.getInfo();
        } else {
          this.$toast(this.$t('lang.swap215'));
        }
      });
    },
  },
  components: {
    InvitationPopup,
  },
  computed: {
    ...mapState(['account']),
    // 链上邀请人
    ChainEferrer () {
      if (this.chainInviteInfo) {
        return this.chainInviteInfo.substring(0, 7) + '*****' + this.chainInviteInfo.substring(this.chainInviteInfo.length - 6, this.chainInviteInfo.length);
      } else {
        return '';
      }
    },
    // 我的DAPP邀请人
    invited () {
      if (this.be_invited) {
        return this.be_invited.substring(0, 7) + '*****' + this.be_invited.substring(this.be_invited.length - 6, this.be_invited.length);
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invitation {
  // background-color: #fefefe;
  // padding: 18px 22px;

  .invitation_address {
    // background: linear-gradient(180deg, #e5f0fe 0%, #f3f9ff 100%);
    background: url('~@/assets/img/invitation.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 18px;
    padding: 22px 40px;
    margin-top: 18px;
    .headline {
      display: flex;
      justify-content: center;
      font-size: 24px;
      // font-family: 创艺简黑体-Regular, 创艺简黑体;
      font-weight: 400;
      color: #595b5b;

      .headline_span {
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #ffd245;
        padding: 4px 30px;
      }
    }
    .address {
      font-size: 16px;
      // font-family: 创艺简黑体-Regular, 创艺简黑体;
      color: #595b5b;
      background: #f5f5f5;
      border-radius: 6px;
      border: 1px solid #ffd245;
      text-align: center;
      padding: 10px 0;
      margin-top: 40px;
    }
    .copy {
      font-size: 20px;

      font-weight: bold;
      color: #595b5b;
      background: #ffd245;
      border-radius: 16px;
      text-align: center;
      padding: 10px 0;
      margin-top: 22px;
    }
  }

  .invitation_record {
    background: #ffffff;
    border-radius: 18px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 30px 54px 16px;
    margin-top: 20px;
    .my_invitee {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #333333;
      margin-bottom: 26px;
      .invitee_address {
        position: relative;
        border-radius: 6px;
        border: 1px solid #ffd245;
        margin-top: 8px;

        .tokenInput {
          font-size: 8px;
          line-height: 0;
          font-family: Arial-Regular, Arial;
          font-weight: 400;
          color: #595b5b;
          background: #f5f5f5;
          padding: 14px 10px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .button {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        .confirmBtn {
          height: auto;
          font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          padding: 6px 10px 6px 30px;
          .image {
            position: absolute;
            left: 8px;
            top: 50%;
            width: 16px;
            height: 16px;
            transform: translateX(0) translateY(-50%);
            z-index: 123;
          }
        }
      }

      .invitee {
        font-size: 12px;
        margin-top: 10px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    .straight_line {
      border: 1px solid #ffd245;
      margin: 10px 0 18px;
    }
    .explain {
      font-size: 10px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      margin-bottom: 24px;
    }
    .subtitle {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #595b5b;
      // margin-bottom: 38rpx;
      .image {
        width: 16px;
        height: 16px;
      }
    }
    .record {
      font-size: 12px;
      font-family: Arial-Regular, Arial;
      font-weight: bold;
      color: #595b5b;
      // text-align: center;
      margin-bottom: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>