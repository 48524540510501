<template>
  <div class="cards">
    <div>
      <span>{{ $t('lang.swap247') }}</span>
    </div>
    <div
      v-if="cardsList.length == 0"
      class="tips"
    >
      <span>{{ $t('lang.swap257') }}</span>
    </div>
    <!-- <van-list v-model="loading"
              :finished="finished"
              :finished-text="$t('lang.swap270')"
              @load="cardInfo"
              v-else> -->
    <div class="cards_list">
      <div
        class="li"
        v-for="(item, index) of cardsList"
        :key="index"
      >
        <img
          :src="item.img ? 'https://box.swanfinance.cc' + item.img : defaultImg"
          class="img"
        />
        <!-- <img
          src="@/assets/img/arrows.png"
          class="arrows"
          @click="exhibitClick"
        /> -->
        <div
          class="exhibit"
          :style="{ height: exhibitShow ? '0' : '' }"
        >
          <div class="cards_detail">
            <span>{{ $t('lang.swap281') }}</span>
            <span>{{ item.total_reward }} USDT</span>
          </div>
          <div class="cards_detail">
            <span>{{ $t('lang.swap283') }}</span>
            <span>{{ item.release_day }}</span>
          </div>
          <div class="cards_detail">
            <span>{{ $t('lang.swap282') }}</span>
            <span v-if="item.status == 0 && item.already_reward != 0">{{ (((item.already_reward * 1) / item.total_reward) * 1).toFixed(0) }}%</span>
            <span v-else-if="item.status == 0 && item.already_reward == 0">0%</span>
            <span v-else>100%</span>
          </div>

          <!-- <div class="cards_detail">
            <span>{{ $t('lang.swap284') }}</span>
            <span>{{ item.reward_multiple }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <!-- </van-list> -->
  </div>
</template>

<script>
// import { blindBox } from '../../api/index';
import { mapState } from 'vuex';
import axios from 'axios';
export default {
  data() {
    return {
      cardsList: [],
      defaultImg: require('@/assets/img/heyfunDefault.png'),
      exhibitShow: false,
    };
  },
  created() {
    this.cardInfo();
  },
  methods: {
    cardInfo() {
      if (this.account) {
        axios({
          method: 'get',
          url: 'https://box.swanfinance.cc/v1/blind-box/hold-list',
          headers: {
            'Content-Type': 'application/json',
            'Show-Language': 'zh-CN',
            Authorization: this.account,
          },
        }).then(res => {
          this.cardsList = res.data.data;
          // for(let i of this.cardsList){
          //   i['exhibitShow'] = false
          // }
        });
      }
    },
    exhibitClick() {
      this.exhibitShow = !this.exhibitShow;
    },
    // },
  },
  watch: {
    account() {
      this.cardInfo();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.cards {
  font-size: 18px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  .tips {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
  .cards_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0 10px;
    .li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 49%;
      font-size: 12px;
      margin-bottom: 30px;
      white-space: nowrap; //溢出不换行
      .img {
        width: 100%;
      }
      .grade {
        display: inline-block;
        margin: 6px 6px 14px;
      }
      .arrows {
        width: 20px;
      }
    }
  }
}

.exhibit {
  width: 100%;
  // margin-top: 10px;
  // height: 80px;
  transition: all 1s;
  overflow: hidden;
  .cards_detail {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: red;
  }
}
</style>