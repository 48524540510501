<template>
  <div class="invitationPopup">
    <div class="title">
      <span>{{$t('lang.swap155')}}</span>
    </div>
    <div class="invitee_address">
      <van-field class="tokenInput"
                 v-model="Invitee"
                 placeholder="" />
    </div>
    <div class="button">
      <van-button type="primary"
                  class="confirmBtn"
                  @click="cancel">
        <span>{{$t('lang.swap154')}}</span>
      </van-button>
      <van-button type="primary"
                  class="confirmBtn"
                  @click="confirm">
        <span>{{$t('lang.swap90')}}</span>
      </van-button>

    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      Invitee: ""
    }
  },
  methods: {
    //确认
    confirm () {
      if (this.Invitee != '') {
        this.$emit('getMyAddress', this.Invitee)
      } else {
        this.$toast(this.$t('lang.swap156'));
        // 
      }
    },
    //取消
    cancel () {
      this.$emit('handleConfirm')
    }
  }
}
</script>

<style lang="less" scoped>
.invitationPopup {
  width: 350px;
  font-size: 16px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  background: #ffffff;
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 16px;
  padding: 20px 10px;
  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .invitee_address {
    position: relative;
    border-radius: 10px;
    border: 1px solid #ffd245;
    margin-top: 8px;
    overflow: hidden;
    .tokenInput {
      font-size: 14px;
      line-height: 0;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #595b5b;
      background: #f5f5f5;
      padding: 10px !important;
    }
  }
  .button {
    display: flex;
    justify-content: space-between;
    padding: 0 34px;
    margin-top: 20px;
    .confirmBtn {
      height: auto;
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding: 10px 24px;
    }
  }
}
</style>