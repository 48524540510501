<template>
  <div>

  </div>
</template>

<script>
export default {
  async created () {
    this.$router.go(-1)

  },
}
</script>

<style>
</style>