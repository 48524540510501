import request from '../utils/request';

export default {
    inviteInfo(address,language) {
        return request.request({
            url: '/member/info/invite',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':language
            },
        })
    },

    //编辑邀请人
    inviteEdit(data,address,language){
        return request.request({
            url: '/member/bind/update',
            method: "post",
            headers:{
                Authorization : address,
                'Show-Language':language
            },
            data
        })
    },

    userBind(data,address,language){
        return request.request({
            url: '/member/bind',
            method: "post",
            headers:{
                Authorization : address,
                'Show-Language':language
            },
            data
        })
    },
}