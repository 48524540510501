<template>
  <div class="creation">
    <div class="grade">
      <div class="grade_button">
        <img @click="switchButton(true)" class="triangle" src="@/assets/img/triangleLeft.png" />
      </div>
      <div class="swipe">
        <van-swipe class="my-swipe" indicator-color="white" :show-indicators="false" ref="swipe" @change="change">
          <van-swipe-item v-for="(item, index) of boxList" :key="index">
            <img :src="'https://box.swanfinance.cc' + item.img" class="lianchuang" />
            <div>
              <span>{{ boxList[index].name }}</span>
            </div>
            <!-- <div class="value">
              <span>({{item.value}})</span>
            </div>-->
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="grade_button">
        <img @click="switchButton(false)" class="triangle" src="@/assets/img/triangleRight.png" />
      </div>
    </div>

    <div class="content">
      <!-- <div class="percentage">
        <span :style="`width: ${memberData[member].progress}%;`"></span>
      </div>
      <div class="prompt">
        <span>{{ $t('lang.swap180') }}：</span>
        <span class="special">{{memberData[member].progress}}%</span>
      </div>-->
      <div class="donate" v-if="boxList">
        <div>
          <span class="subtitle">{{ $t('lang.swap285') }}</span>
          <span class="value">{{ boxList[member].price }}</span>
          <span class="subtitle">USDT {{ $t('lang.swap286') }} SGF</span>
        </div>
      </div>

      <div class="button">
        <van-button type="primary" class="swapBtn" @click="approve" loading-text="Waiting..." :loading="showApprove" v-if="!isApprove">{{ $t('lang.swap14') }}</van-button>
        <van-button type="primary" class="swapBtn" @click="buyBox" :loading-text="$t('lang.swap258')" :loading="buyBoxShow" v-else>{{ $t('lang.swap259') }}</van-button>
      </div>
      <div v-if="balance" class="balance">
        <span class="balance-text">SGF BALANCE:{{ (balance * 1).toFixed(6) }}</span>
      </div>
      <div class="explain">
        <div class="explain_title">
          <span>--{{ $t('lang.swap151') }}--</span>
        </div>
        <div class="explain_list">
          <span>{{ $t('lang.swap238') }}</span>
          <span>{{ $t('lang.swap239') }}</span>
          <span>{{ $t('lang.swap240') }}</span>
        </div>
      </div>
    </div>
    <van-popup v-model="blindBoxPopupShow" round>
      <BlindBoxPopup @popup="popup" :img="imgUrl" :hashrate="hashrate" />
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import { blindBox } from '../../api/index';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import BlindBoxPopup from './BlindBoxPopup.vue';
import sgfBox from '../../methods/sgfBox.js';

import axios from 'axios';
export default {
  data() {
    return {
      buyBoxShow: false,
      balance: '',
      blindBoxPopupShow: false, //弹窗状态
      imgUrl: '',
      hashrate: {
        price: 0,
        incomeRate: 0,
      },
      sgfPrice: 0,
      boxList: [],
      member: 0,
      receiver: '', //合约地址
      payToken: '', //支付币种地址
      isApprove: false, //判断是否授权
      showApprove: false, //授权完成
    };
  },
  created() {
    this.init();
  },
  beforeDestroy() {},

  methods: {
    init() {
      if (!this.account) {
        return;
      }
      this.getPayMethod();
      this.getBoxList();
    },
    switchButton(show) {
      if (show) {
        this.$refs.swipe.prev();
      } else {
        this.$refs.swipe.next();
      }
    },

    //购买条件
    async getPayMethod() {
      if (!this.account) {
        return;
      }
      let data = {
        chain_id: 56,
      };
      axios({
        method: 'get',
        url: 'https://box.swanfinance.cc/v1/blind-box/pay-method',
        headers: {
          'Content-Type': 'application/json',
          'Show-Language': 'zh-CN',
          Authorization: this.account,
        },
        params: data,
      }).then(res => {
        this.receiver = res.data.data.pay_method[0].receiver;
        this.payToken = res.data.data.pay_method[0].token;
        this.handleApproveBtnShow();
        this.getBalance();
        this.getSgfPrice();
      });
    },

    async getBoxList() {
      axios({
        method: 'get',
        url: 'https://box.swanfinance.cc/v1/blind-box/list',
        headers: {
          'Content-Type': 'application/json',
          'Show-Language': 'zh-CN',
          Authorization: this.account,
        },
      }).then(res => {
        this.boxList = res.data.data;

        // let data = {
        //   tx_hash: '0x31c24bb8278459a2af48aae35fc66870588f2b0324f1085f0d0197f22a2be58d',
        //   box_id: this.boxList[this.member].id,
        //   amount: 1,
        // };
        // axios({
        //   method: 'post',
        //   url: 'https://box.swanfinance.cc/v1/blind-box/buy',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Show-Language': 'zh-CN',
        //     Authorization: this.account,
        //   },
        //   data:data,
        // })
        //   .then(res => {})
        //   .catch(e => {
        //     this.$toast(this.$t('lang.swap261'));
        //   });
      });
    },

    async getBalance() {
      if (this.account) {
        const erc20Contract = getErc20Contract(this.payToken);
        const balance = await erc20Contract.methods.balanceOf(this.account).call();
        this.balance = formatAmount(balance, 18);
      }
    },

    //获取当前sgf的价格
    async getSgfPrice() {
      const jackPairContract = getJackPairContract('0xaDF3f98Fd6F577C0df9aE22f6Ce2EC358786f80B');
      let reserves = await jackPairContract.methods.getReserves().call();
      let usdtAddress = '0x55d398326f99059fF775485246999027B3197955'; //from
      let sgfAddress = this.payToken; //to
      if (sgfAddress.toLocaleLowerCase() < usdtAddress.toLowerCase()) {
        let fromAll = reserves._reserve0 / Math.pow(10, 18);
        let toAll = reserves._reserve1 / Math.pow(10, 18);
        this.sgfPrice = toAll / fromAll; //币的价格
      } else {
        let fromALL = reserves._reserve1 / Math.pow(10, 18);
        let toAll = reserves._reserve0 / Math.pow(10, 18);
        this.sgfPrice = toAll / fromALL; //币的价格
      }
    },

    buyBox() {
      let payBalance = accDiv(this.boxList[this.member].price, this.sgfPrice) * 1;
      // let payBalance = accDiv(this.boxList[this.member].price, 100) * 1;
      if ((this.balance) * 1 < (payBalance * 1)) {
        this.$toast(this.$t('lang.swap11'));
        return;
      } else {
        this.buyBoxShow = true;
        let amount = payBalance * Math.pow(10, 18) + '';
        sgfBox
          .buyBlindBox(this.account, this.payToken, amount, this.receiver)
          .then(res => {
            let dataTo = {
              tx_hash: res,
              box_price: this.boxList[this.member].price,
            };
            this.buyBoxShow = false;
            axios({
              method: 'post',
              url: 'https://box.swanfinance.cc/v1/blind-box/buy',
              headers: {
                'Content-Type': 'application/json',
                'Show-Language': 'zh-CN',
                Authorization: this.account,
              },
              data: dataTo,
            })
              .then(res => {
                  this.$toast(this.$t('lang.swap261'));    
              })
              .catch(e => {
                // this.$toast(this.$t('lang.swap261'));
              });
          })
          .catch(res => {
            this.buyBoxShow = false;
          });
      }
    },

    change(index) {
      this.member = index;
    },

    //授权
    async handleApproveBtnShow() {
      if (this.account) {
        const to = this.receiver;
        if (this.account) {
          let erc20ContractTo = getErc20Contract(this.payToken);
          const allowanceTo = await erc20ContractTo.methods.allowance(this.account, to).call();
          if (allowanceTo == 0) {
            this.isApprove = false;
          } else {
            this.isApprove = true;
          }
        }
      }
    },

    //授权
    approve() {
      this.showApprove = true;
      const erc20Contract = useErc20Contract(this.payToken);
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve(this.receiver, amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.showApprove = false;
          this.isApprove = true;
        })
        .on('error', (error, receipt) => {
          this.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    //获取盲盒数据
    boxList() {
      blindBox.boxList(this.account).then(res => {
        if (res.code == 200) {
          this.memberData = res.data;
        }
      });
    },
    // 关闭或打开弹窗
    popup() {
      this.blindBoxPopupShow = !this.blindBoxPopupShow;
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  components: {
    BlindBoxPopup,
  },
};
</script>

<style lang="less" scoped>
.creation {
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  .grade {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 10px;
    padding: 20px 0;
    margin: 20px 0 10px;
    .grade_button {
      display: flex;
      align-items: center;
    }
    .triangle {
      width: 40px;
      height: 40px;
      padding: 12px;
    }
    .swipe {
      flex: 1;
      overflow: hidden;
      .my-swipe .van-swipe-item {
        color: #c67700;
        font-size: 16px;
        // line-height: 150px;
        text-align: center;
        padding: 10px 0;
        .lianchuang {
          width: 100%;
          height: 164px;
        }
        .value {
          font-size: 10px;
        }
      }
    }
  }
  .content {
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 10px;
    padding: 10px 20px;
    .percentage {
      width: 100%;
      height: 5px;
      background: #e9e9e9;
      border-radius: 16px;
      border: 1px solid #ffd245;
      overflow: hidden;
      margin-top: 20px;

      span {
        display: block;
        width: 50%;
        height: 100%;
        background: #ffd245;
      }
    }
    .prompt {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 10px;
      .special {
        font-size: 14px;
        color: #ffd245;
      }
      .small {
        font-size: 10px;
      }
    }
    .donate {
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      border: 1px solid #ffd245;
      border-radius: 6px;
      text-align: center;
      padding: 10px 0;
      margin: 30px 0;
      .subtitle {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
      .value {
        color: #ffd245;
        font-size: 14px;
        margin: 0 6px;
      }
      .wait {
        color: #ffd245;
        margin-top: 10px;
      }
    }
    .donated {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #333333;
      margin: 30px 0 26px;
      .donated_value {
        font-size: 10px;
        color: #ffd245;
      }
    }
    .button {
      .swapBtn {
        width: 100%;
        height: auto;
        font-size: 18px;
        color: #333333;
        padding: 6px 0;
      }
    }
    .button-two {
      margin-top: 20px;
      .swapBtn {
        width: 100%;
        height: auto;
        font-size: 18px;
        color: #333333;
        padding: 6px 0;
      }
    }
    .explain {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      color: #666666;
      margin: 38px 0 30px;
      .explain_title {
        text-align: center;
        margin-bottom: 10px;
      }
      .explain_list {
        display: flex;
        flex-direction: column;
      }
    }

    .balance {
      margin-top: 10px;
      text-align: right;
      .balance-text {
        font-size: 10px;
      }
    }
  }
}
</style>