<template>
  <div class="popup">
    <div class="title">
      <span>{{ $t('lang.swap264') }}</span>
    </div>
    <div class="image">
      <img :src="img"
           class="img">
    </div>
    <div class="power"
         v-if="hashrate.price != 0">
      <span>{{ hashrate.price }} * {{ hashrate.incomeRate / 100 }} {{$t('lang.swap273')}}</span>
    </div>
    <div class="button_list">
      <van-button type="primary"
                  class="swapBtn"
                  @click="determine">{{ $t('lang.swap265') }}</van-button>
      <van-button type="primary"
                  class="swapBtn"
                  @click="cards">{{ $t('lang.swap244') }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['img', 'hashrate'],
  data () {
    return {

    }
  },
  methods: {
    determine () {
      this.$emit('popup')
    },
    cards () {
      if(this.hashrate.price == 0){
        this.$router.push({ name: 'Cards' });
      }else{
        this.$router.push({ name: 'gapCards' });
      }
      
    },
  }
}
</script>

<style lang="less" scoped>
.popup {
  width: 320px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  background: #fff;
  padding: 10px 20px;
  .title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
  }
  .image {
    display: flex;
    justify-content: center;
    .img {
      width: 60%;
    }
  }
  .power {
    color: #ffd245;
    text-align: center;
  }
  .button_list {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    .swapBtn {
      height: auto;
      color: #333333;
      padding: 6px 30px;
    }
  }
}
</style>