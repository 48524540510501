const base = [
  {
    "name": "BNB Token",
    "symbol": "BNB",
    "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    "chainId": 56,
    "decimals": 18,
    "logoUrl": require('@/assets/img/bnb.png')
  },
  {
    "name": "PancakeSwap Token",
    "symbol": "CAKE",
    "address": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    "chainId": 56,
    "decimals": 18,
    "logoUrl": "https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png"
  },
  {
    "name": "BUSD Token",
    "symbol": "BUSD",
    "address": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    "chainId": 56,
    "decimals": 18,
    "logoUrl": "https://raw.githubusercontent.com/Sexy-J/JieSwap/main/src/assets/img/busd.png"
  },
  {
    "name": "Tether USD",
    "symbol": "USDT",
    "address": "0x55d398326f99059fF775485246999027B3197955",
    "chainId": 56,
    "decimals": 18,
    "logoUrl": "https://raw.githubusercontent.com/Sexy-J/JieSwap/main/src/assets/img/usdt.png"
  },
  {
    "symbol": "Swan",
    "address": "0x25BF35B089DAcB352eadB802a685207d31E23a9F",
    "chainId": 56,
    "decimals": 18,
    "name": "Swan Finance Community Token",
    "logoUrl": "https://img.kjdsy.com/swan/logo/SWAN2.png"
    },
];

export function getAllPair(curA, curB) {
  let lpPair = [];
  for (let i = 0; i < base.length; i++) {
    if (base[i].address != curA.address) {
      lpPair.push({ toCur: base[i], fromCur: curA });
    }

    if (base[i].address != curB.address) {
      lpPair.push({ toCur: curB, fromCur: base[i] });
    }
  }
  lpPair.push({ toCur: curB, fromCur: curA });
  return lpPair;
}
