<template>
  <div class="feedback">
    <van-form @submit="onSubmit">
      <div class="feedback_title">
        <span>{{ $t('lang.swap163') }}:</span>
        <van-field
          type="text"
          v-model="titleInput"
          class="title-field"
          name="title"
          :rules="[{ required: true, message: `${$t('lang.swap168')} ${$t('lang.swap163')}` }]"
        />
      </div>

      <div class="conten">
        <div class="conten_title">
          <img src="@/assets/img/information.png" class="information" />
          <span>{{ $t('lang.swap164') }}:</span>
        </div>
        <van-field
          type="textarea"
          v-model="contentInput"
          class="conten-field"
          name="conten"
          :rules="[{ required: true, message:  `${$t('lang.swap168')} ${$t('lang.swap164')}`  }]"
        />
      </div>

      <div class="conten">
        <div class="conten_title">
          <img src="@/assets/img/information.png" class="information" />
          <span>附件:</span>
        </div>
        <van-uploader
          v-model="fileList"
          multiple
          :after-read="handleUpload"
          :before-delete="del"
          class="imgList"
        />
      </div>

      <div class="mailbox">
        <div class="currency">
          <div class="currency_img">
            <img src="@/assets/img/address.png" class="img" />
            <span>{{ $t('lang.swap165') }}:</span>
          </div>
          <van-field
            type="text"
            v-model="account"
            class="mailbox-field"
            name="address"
            disabled
            :rules="[{ required: true, message:  `${$t('lang.swap168')} ${$t('lang.swap165')}`  }]"
          />
        </div>
        <div class="currency">
          <div class="currency_img">
            <img src="@/assets/img/mailbox.png" class="img" />
            <span>{{ $t('lang.swap166') }}:</span>
          </div>
          <van-field
            type="text"
            v-model="mailboxInput"
            class="mailbox-field"
            name="mailbox"
            :rules="[{ validator, message:  `${$t('lang.swap168')} ${$t('lang.swap169')}` }]"
          />
        </div>
      </div>

      <div class="button">
        <van-button type="primary" class="feedbackBtn">{{ $t('lang.swap167') }}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { coin } from '../../api/index';
export default {
  data() {
    return {
      titleInput: '',
      contentInput: '',
      addressInput: '',
      mailboxInput: '',
      fileList: [],
      imgList: [],
    };
  },
  methods: {
    onSubmit(values) {
      let data = {
        address: this.account,
        title: this.titleInput,
        content: this.contentInput,
        email: this.mailboxInput,
        imgs: this.imgList,
      };
      coin
        .feedBack(data)
        .then(res => {
          this.titleInput = '';
          this.contentInput = '';
          this.addressInput = '';
          this.mailboxInput = '';
          this.imgList = [];
          this.fileList = [];
          this.$toast('success');
        })
        .catch(err => {});
      // axios({
      //   url: 'https://api.swanfinance.cc/feedback/add',
      //   method: 'post',
      //   data: data,
      //   timeout: 3000,
      // }).then(res => {
      //   this.titleInput = '';
      //   this.contentInput = '';
      //   this.addressInput = '';
      //   this.mailboxInput = '';
      //   this.imgList = [];
      //   this.fileList = [];
      //   this.$toast('success');
      // });

      // axios
      //   .post('http://192.168.11.236/feedback/add')
      //   .data(data)
      //   .then(res => {
      //     console.log(res);
      //   })
      //   .catch(e => {});
    },
    del(eve, detail) {
      this.imgList.splice(detail.index, 1);
    },
    handleUpload(event) {
      let fd = new FormData();
      fd.append('file', event.file);
      // common
      //   .upload(fd)
      //   .then(res => {
      //     console.log(res,"上传图片返回来的样式");
      //     this.imgList.push(res.data.data.link);
      //   })
      //   .catch(err => {});
      axios({
        url: 'https://api.swanfinance.cc/api/upload',
        method: 'post',
        // headers:{"Content-Type": "multipart/form-data;boundary=ABCD"},
        data: fd,
        timeout: 30000,
      }).then(res => {
        this.imgList.push(res.data.data.link);
      });
    },
    validator(val) {
      return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(val);
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.feedback {
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
  border-radius: 16px;
  padding: 16px 10px;
  margin-top: 20px;
  .feedback_title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    background: #f8f7f7;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 16px;
    padding: 18px 20px;
    span {
      margin-right: 10px;
    }
    .title-field {
      flex: 1;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #ffd245;
      padding: 2px 6px !important;
    }
  }
  .conten {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    background: #f8f7f7;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 16px;
    padding: 18px 20px;
    margin-top: 14px;
    .conten_title {
      display: flex;
      align-items: center;
      .information {
        width: 16px;
        margin-right: 4px;
      }
    }

    .conten-field {
      flex: 1;
      height: auto;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #ffd245;
      padding: 2px 6px !important;
      margin-top: 10px;
      /deep/ .van-field__control {
        height: 140px;
      }
    }
  }
  .mailbox {
    font-size: 10px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #999999;
    background: #f8f7f7;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 16px;
    padding: 8px 20px 18px;
    margin-top: 14px;
    .currency {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid #ffd245;
      padding: 6px;
      margin-top: 10px;
      .currency_img {
        display: flex;
        align-items: center;
        .img {
          width: 12px;
          margin-right: 4px;
        }
      }
      .mailbox-field {
        flex: 1;
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    .feedbackBtn {
      height: auto;
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      border-radius: 10px;
      padding: 6px 22px;
    }
  }
}

.imgList {
  margin-top: 20px;
}
</style>