<template>
  <div class="creation">
    <div class="grade">
      <div class="grade_button">
        <img @click="switchButton(true)"
             class="triangle"
             src="@/assets/img/triangleLeft.png" />
      </div>
      <div class="swipe">
        <van-swipe class="my-swipe"
                   indicator-color="white"
                   :show-indicators="false"
                   ref="swipe"
                   @change="change">
          <van-swipe-item v-for="(item, index) of memberData"
                          :key="index">
            <img :src="item.img"
                 :class="index == 0 ? 'lianchuang' : 'community'" />
            <div>
              <span>{{ $t(item.text) }}</span>
            </div>
            <!-- <div class="value">
              <span>({{item.value}})</span>
            </div>-->
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="grade_button">
        <img @click="switchButton(false)"
             class="triangle"
             src="@/assets/img/triangleRight.png" />
      </div>
    </div>

    <div class="content">
      <!-- <div class="percentage">
        <span :style="`width: ${memberData[member].progress}%;`"></span>
      </div>
      <div class="prompt">
        <span>{{ $t('lang.swap180') }}：</span>
        <span class="special">{{memberData[member].progress}}%</span>
      </div>-->
      <div class="donate">
        <span class="subtitle">{{ $t('lang.swap181') }}</span>
        <div>
          <span>{{ memberData[member].value }} USDT</span>
        </div>
      </div>
      <div class="button">
        <!-- <van-button type="default" class="swapBtn" disabled v-if="memberData[member].state">{{ $t('lang.swap190') }}</van-button>
        <van-button type="primary" class="swapBtn" v-else-if="memberData[member].isApprove" @click="approve" loading-text="Waiting..." :loading="showApprove">{{ $t('lang.swap14') }}</van-button>
        <van-button type="primary" class="swapBtn" v-else-if="!memberData[member].state && !memberData[member].isApprove" @click="donation">{{ $t('lang.swap186') }}</van-button>
    -->
        <van-button type="default"
                    class="swapBtn"
                    disabled>{{ $t('lang.swap235') }}</van-button>

      </div>
      <div class="balance">
        <span class="balance-text">balance:{{(balance * 1).toFixed(6)}} </span>
      </div>
      <div class="explain">
        <div class="explain_title">
          <span>--{{ $t('lang.swap151') }}--</span>
        </div>
        <div class="explain_list">
          <span>{{ $t('lang.swap218') }}</span>
          <span>{{ $t('lang.swap219') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import usdt from '../../methods/usdt.js';
import { donate } from '../../api/index';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js'
export default {
  data () {
    return {
      memberData: [
        { img: require('@/assets/img/cslm-lianchuang.png'), text: 'lang.swap184', value: 200, progress: 0, state: false, isApprove: false },
        { img: require('@/assets/img/cslm-shequ.png'), text: 'lang.swap185', value: 50, progress: 0, state: false, isApprove: false },
      ],
      // 当前选择的
      member: 0,
      otherData: {},
      showApprove: false,
      balance: '',
    };
  },
  created () {
    this.init();
  },
  beforeDestroy () {
    //清除定时器
    window.clearInterval(this.initChange);
  },

  methods: {
    switchButton (show) {
      if (show) {
        this.$refs.swipe.prev();
      } else {
        this.$refs.swipe.next();
      }
    },
    async getBalance () {
      const erc20Contract = getErc20Contract('0x55d398326f99059fF775485246999027B3197955');
      const balance = await erc20Contract.methods.balanceOf(this.account).call();
      this.balance = formatAmount(balance, 18);
    },
    handleChangeInit () {
      window.clearTimeout(this.changeInit);
      this.changeInit = setInterval(async () => {
        this.isflushDonate();
      }, 3000);
    },
    init () {
      this.getDonateStatus();
      this.handleApproveBtnShow();
      this.getBalance()
      //   this.getAllSurplus();
    },

    //获取捐赠状态，判断是否暂停
    isflushDonate () {
      if (this.account) {
        let data = {
          type: 0,
          projectId: 1,
        };
        donate
          .donateStatus(data, this.account)
          .then(res => {
            this.memberData[0].state = res.data.data;
            if (res.data.data) {
              window.clearInterval(this.initChange);
            }
          })
          .catch(err => { });
        // axios({
        //   url: 'https://api.swanfinance.cc/member/donate/status',
        //   method: 'get',
        //   headers: { Authorization: this.account },
        //   params: data,
        //   timeout: 3000,
        // }).then(res => {
        //   this.memberData[0].state = res.data.data;
        //   if (res.data.data) {
        //     window.clearInterval(this.initChange);
        //   }
        // });

        let data2 = {
          type: 1,
          projectId: 1,
        };
        donate.donateStatus(data2, this.account).then(res => {
          this.memberData[1].state = res.data;
          if (res.data.data) {
            window.clearInterval(this.initChange);
          }
        });

        // axios({
        //   url: 'https://api.swanfinance.cc/member/donate/status',
        //   method: 'get',
        //   headers: { Authorization: this.account },
        //   params: data2,
        //   timeout: 3000,
        // }).then(res => {
        //   this.memberData[1].state = res.data;
        //   if (res.data.data) {
        //     window.clearInterval(this.initChange);
        //   }
        // });
      }
    },

    //获取捐赠次数
    // getAllSurplus() {
    //   let data = {
    //     type: 0,
    //   };
    //   axios({
    //     url: 'https://api.swanfinance.cc/member/donate/surplus',
    //     method: 'get',
    //     headers: { Authorization: this.account },
    //     params: data,
    //     timeout: 3000,
    //   }).then(res => {
    //     this.memberData[0].progress = res.data.data;
    //   });

    //   let data2 = {
    //     type: 1,
    //   };
    //   axios({
    //     url: 'https://api.swanfinance.cc/member/donate/surplus',
    //     method: 'get',
    //     headers: { Authorization: this.account },
    //     params: data2,
    //     timeout: 3000,
    //   }).then(res => {
    //     this.memberData[1].progress = res.data.data;
    //   });
    // },

    //获取捐赠状态
    getDonateStatus () {
      if (this.account) {
        let data = {
          type: 0,
          projectId: 1,
        };
        donate.donateStatus(data, this.account).then(res => {
          this.memberData[0].state = res.data.data;
        });
        // axios({
        //   url: 'https://api.swanfinance.cc/member/donate/status',
        //   method: 'get',
        //   headers: { Authorization: this.account },
        //   params: data,
        //   timeout: 3000,
        // }).then(res => {
        //   this.memberData[0].state = res.data.data;
        // });

        let data2 = {
          type: 1,
          projectId: 1,
        };

        donate.donateStatus(data2, this.account).then(res => {
          this.memberData[1].state = res.data.data;
        });

        // axios({
        //   url: 'https://api.swanfinance.cc/member/donate/status',
        //   method: 'get',
        //   headers: { Authorization: this.account },
        //   params: data2,
        //   timeout: 3000,
        // }).then(res => {
        //   this.memberData[1].state = res.data.data;
        // });
      }
    },
    change (index) {
      this.member = index;
    },

    async handleApproveBtnShow () {
      //捐赠地址
      const to = '0x7976EEd9128dD726272D76f89ea34E3922c73f09';
      if (this.account) {
        let erc20ContractTo = getErc20Contract('0x55d398326f99059fF775485246999027B3197955');
        this.allowanceTo = await erc20ContractTo.methods.allowance(this.account, to).call();
        if (this.allowanceTo == 0) {
          this.memberData[0].isApprove = true;
          this.memberData[1].isApprove = true;
        } else {
          this.memberData[0].isApprove = false;
          this.memberData[1].isApprove = false;
        }
      }
    },

    approve () {
      this.showApprove = true;
      const erc20Contract = useErc20Contract('0x55d398326f99059fF775485246999027B3197955');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0x7976EEd9128dD726272D76f89ea34E3922c73f09', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.showApprove = false;
          this.memberData[0].isApprove = false;
          this.memberData[1].isApprove = false;
        })
        .on('error', (error, receipt) => {
          this.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    donation () {
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap186')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      let amount = this.memberData[this.member].value * Math.pow(10, 18) + '';
      usdt
        .transfer(this.account, '0x7976EEd9128dD726272D76f89ea34E3922c73f09', amount, transactionsDetail)
        .then(res => {
          let data = {
            hash: res,
          };

          donate.donate(data).then(res => {
            this.$toast(this.$t('lang.swap191'));
          });
          // axios({
          //   url: 'https://api.swanfinance.cc/member/donate',
          //   method: 'post',
          //   headers: { Authorization: this.account },
          //   data: data,
          //   timeout: 30000,
          // }).then(res => {
          //   this.$toast(this.$t('lang.swap191'));
          // });
          this.handleChangeInit();
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('confirmation', res => { })
        .on('error', (error, receipt) => {
          this.$toast(this.$t('lang.swap76'));
        });
    },
  },
  watch: {
    account () {
      this.getDonateStatus();
      this.handleApproveBtnShow();
      //   this.getAllSurplus();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.creation {
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  .grade {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 10px;
    padding: 20px 0;
    margin: 20px 0 10px;
    .grade_button {
      display: flex;
      align-items: center;
    }
    .triangle {
      width: 40px;
      height: 40px;
      padding: 12px;
    }
    .swipe {
      flex: 1;
      overflow: hidden;
      .my-swipe .van-swipe-item {
        color: #c67700;
        font-size: 16px;
        // line-height: 150px;
        text-align: center;
        .lianchuang {
          width: 150px;
          height: 120px;
        }
        .community {
          width: 150px;
          height: 120px;
        }
        .value {
          font-size: 10px;
        }
      }
    }
  }
  .content {
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    border-radius: 10px;
    padding: 10px 20px;
    .percentage {
      width: 100%;
      height: 5px;
      background: #e9e9e9;
      border-radius: 16px;
      border: 1px solid #ffd245;
      overflow: hidden;
      margin-top: 20px;

      span {
        display: block;
        width: 50%;
        height: 100%;
        background: #ffd245;
      }
    }
    .prompt {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 10px;
      .special {
        font-size: 14px;
        color: #ffd245;
      }
      .small {
        font-size: 10px;
      }
    }
    .donate {
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      border: 1px solid #ffd245;
      border-radius: 6px;
      text-align: center;
      padding: 18px 0;
      margin: 30px 0;
      .subtitle {
        display: block;
        font-size: 14px;
        color: #666666;
        margin-bottom: 10px;
      }
    }
    .donated {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #333333;
      margin: 30px 0 26px;
      .donated_value {
        font-size: 10px;
        color: #ffd245;
      }
    }
    .button {
      .swapBtn {
        width: 100%;
        height: auto;
        font-size: 18px;
        color: #333333;
        padding: 6px 0;
      }
    }
    .explain {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      color: #666666;
      margin: 38px 0 30px;
      .explain_title {
        text-align: center;
        margin-bottom: 10px;
      }
      .explain_list {
        display: flex;
        flex-direction: column;
      }
    }

    .balance {
      margin-top: 10px;
      text-align: left;
      .balance-text {
        font-size: 14px;
      }
    }
  }
}
</style>