export const lang = {
  swap1: 'Exchange',
  swap2: 'Trade tokens in an instant',
  swap3: 'From',
  swap4: 'Balance: ',
  swap5: 'Select a currency',
  swap6: 'MAX',
  swap7: 'Price',
  swap8: 'Slippage Tolerance',
  swap9: 'Connect Wallet',
  swap10: 'Enter an amount',
  swap11: 'insufficient balance',
  swap12: 'Insufficient liquidity for this trade',
  swap13: 'Select a token',
  swap14: 'Approve',
  swap15: 'Wrap',
  swap16: 'Unwrap',
  swap17: '闪兑',
  swap18: 'Transaction receipt',
  swap19: 'Waiting for confirmation',
  swap20: 'Will',
  swap21: 'Exchange for',
  swap22: 'Confirm this transaction in your wallet',
  swap23: 'Submitted transaction',
  swap24: 'View on BscScan',
  swap25: 'Dismiss',
  swap26: 'Transaction rejected',
  swap27: 'Confirm Swap',
  swap28: 'If the output is a valuation, you will get at least {inputOutMin} {symbol}, Or the deal will be withdrawn',
  swap29: 'Enter as valuation, you will sell the most {inputInMax} {symbol}, Or the deal will be withdrawn',
  swap30: 'Minimum received',
  swap31: 'Maximum sales',
  swap32: 'To',
  swap33: 'Manage tokens',
  swap34: 'Import token',
  swap35: 'Import',
  swap36: 'Manage tokens',
  swap37: 'Custom token',
  swap38: 'Clear all',
  swap39: 'Search name or paste address',
  swap40: 'Settings',
  swap41: 'Default transaction speed(GWEI)',
  swap42: 'Standard',
  swap43: 'Fast',
  swap44: 'Instant',
  swap46: 'Enter a vaild slippage percentage',
  swap47: 'Your transaction may fail',
  swap48: 'Your transaction may be frontrun',
  swap49: 'Tx deadline (mins)',
  swap50: 'estimated',
  swap51: 'Disable Multihops',
  swap52: 'Recent Transactions',
  swap53: 'No recent transactions',
  swap54: 'Clear All',
  swap55: 'Not connected',
  swap56: 'Join The Board',
  swap57: 'Donates',
  swap58: 'Rights',
  swap59: 'List Of Board Address',
  swap60: 'Referrals',
  swap61: 'Copy',
  swap62: 'My DAPP Referrer:',
  swap63: 'Referrals Address',
  swap64: 'Something went wrong. Please try again later',
  swap65: 'Coming soon',
  swap66: 'harvest',
  swap67: 'Extraction succeeded',
  swap68: 'Donation in progress, please wait',
  swap69: 'Donated',
  swap70: 'Non-Whitelist',
  swap71: 'Failed',
  swap72: 'Insufficient Balance',
  swap73: 'Waiting',
  swap74: 'privilege grant failed',
  swap75: 'Donation in progress, please wait',
  swap76: 'Failed',
  swap77: 'Swap',
  swap78: 'Liquidity',
  swap79: 'Boardroom',
  swap80: 'Referrals',
  swap81: 'Reward Pool',
  swap82: 'Bridge',
  swap83: 'Pool',
  swap84: 'Farm',
  swap85: 'Home',
  swap86: 'Markets',
  swap87: 'Swap',
  swap88: 'Swap',
  swap89: 'Earn',
  swap90: 'Confirm',
  swap91: 'Price Impact',
  swap92: 'Minimum received',
  swap93: 'Liquidity Provider Fee',
  swap94: 'Add Liquidity',
  swap95: 'Add liquidity to receive LP tokens',
  swap96: 'Share of Pool',
  swap97: 'Prices and pool share',
  swap98: 'Your Liquidity',
  swap99: 'Remove liquidity to receive tokens back',
  swap100: 'No liquidity found.',
  swap101: 'Dont see a pool you joined?',
  swap102: 'Find other LP tokens',
  swap103: 'Pooled',
  swap104: 'Remove',
  swap105: 'Supply',
  swap106: 'Import Pool',
  swap107: 'Import an existing pool',
  swap108: 'Select a token to find your liquidity.',
  swap109: 'You don’t have liquidity in this pool yet.',
  swap110: 'LP tokens in your wallet',
  swap111: 'To receive',
  swap112: 'and',
  swap113: 'Amount',
  swap114: 'YOU WILL RECEIVE',
  swap115: 'Output is estimated. If the price changes by more than {slippage}% your transaction will revert.',
  swap116: 'Burned',
  swap117: 'Deposited',
  swap118: 'Confirm Supply',
  swap119: 'Rates',
  swap120: 'You are the first liquidity provider.',
  swap121: 'The ratio of tokens you add will set the price of this pool.',
  swap122: 'Once you are happy with the rate click supply to review.',
  swap123: 'Removing ',
  swap124: 'Supplying',
  swap125: 'Confirm supply',
  swap126: 'White Paper',
  swap127: 'Audit',
  swap128: 'Notice',
  swap129: 'Tips',
  swap130: 'Swan(old)to Swan (new) 1:1 exchange',
  swap131: 'More',
  swap132: 'Loading',
  swap133: 'Information',
  swap134: 'Donation quota is full',
  swap135: 'MY SWAN',
  swap136: 'SWAN in Wallet',
  swap137: 'SWAN to Harvest',
  swap138: 'SWAN Price',
  swap139: 'SWAN Total',
  swap140: 'SWAN Market capitalization',
  swap141: 'SWAN INFO',
  swap142: 'Earned',
  swap143: 'STAKED',
  swap144: 'Personal TVL',
  swap145: 'Community TVL',
  swap146: 'Harvest',
  swap147: 'Total reward ',
  swap148: 'Reward today',
  swap149: 'LP Maket Makers address',
  swap150: 'My eferrer:',
  swap151: 'Explain:',
  swap152: 'A user with a referral address is a valid address, and a valid address can be recommended to other addresses on the chain. If you do not have an invitation address, please contact your inviter or let us know through feedback.',
  swap153: 'Modify',
  swap154: 'Cancel',
  swap155: 'Modify Referrer',
  swap156: 'Invitee cannot be empty',
  swap157: 'Modified Successfully',
  swap158: 'STAKED LP token',
  swap159: 'REDEEM LP token',
  swap160: 'REDEEM',
  swap161: 'Obtain',
  swap162: 'No income at present',
  swap163: 'Title',
  swap164: 'Content',
  swap165: 'Address',
  swap166: 'Reply email',
  swap167: 'Submit',
  swap168: 'Please fill',
  swap169: 'Correct reply email',
  swap170: 'Route',
  swap171: 'price impact too high',
  swap172: "Feedback",
  swap173: 'The price impact of this exchange is at least 10%. Please confirm this swap',
  swap174: 'Subgraph Health Indicator',
  swap175: "Mainstream",
  swap176: "DEFI",
  swap177: "SWAN",
  swap178: "Launch pad",
  swap179: "Get into",
  swap180: "Progress completed",
  swap181: "Always donate",
  swap182: "1：The Darwin leader recruitment information is provided and responsible by Darwin.",
  swap183: "2：This recruitment can be over-run and will be assessed by the Darwin community. The leader seat that fails will be automatically returned to the donated funds.",
  swap184: "Co-Founder leader",
  swap185: "Community leader",
  swap186: "donate",
  swap187: "Darwin Leader",
  swap188: "Darwin AI Technology System is an on-chain intelligent AI arbitrage system with self-growth. By monitoring profitable slippage opportunities in all token transactions on the chain, it can conduct Front Running (preemptive advance), Flash Loan (flash loan), etc. Trading, so as to achieve instant excess profits.",
  swap189: "Recruitment plan",
  swap190: "Donated",
  swap191: "Donating, please wait",
  swap192: "Crowdfunding",
  swap193: "DAO",
  swap194: "About",
  swap195: "Limit",
  swap196: "Mix",
  swap197: "Earn coins",
  swap198: "Community",
  swap199: "Please add an inviter first",
  swap200: "Copy successfully",
  swap201: "Copying is not supported, this browser does not support automatic copying",
  swap202: "Chain game market",
  swap203: "C2C Market",
  swap204: "Single Minning",
  swap205: "Loan",
  swap206: "NFT Market",
  swap207: "Partner",
  swap208: "Swan DAO is committed to creating a large-scale community-based DAO organization. In order to realize the DAO dream, gather community members, build a community of millions, and establish the Swan Finance Foundation (foundation), led by the foundation, is committed to building a multi-chain aggregation DEFI Ecology, including but not limited to the construction of DEX+NFT+Socialfi+Gamefi+WB3 and other infrastructures.",
  swap209: "In order to promote the ecology in an orderly manner, Swan Finance was launched as the first ecological application method. Based on the standard AMM automatic market-making mechanism, it provides users with efficient transaction services. The V1 version is a deep aggregation Pancake transaction, and the V2 version is the Swan Finance transaction solution.",
  swap210: "Swan is the first consensus token of Swan DAO, with a total of 29,000 tokens. It adopts a transaction tax mechanism and unilaterally charges 3% transaction tax to encourage community development.",
  swap211: "Swan Finance takes pragmatism, sustainability, development, and community as its concepts, and maintains an open attitude. All platforms are welcome to apply for cooperation and provide token listing, platform inclusion, technical support, marketing incubation, etc.",
  swap212: "Contact information",
  swap213: "Email: Support@SwanFinance.cc",
  swap214: "Feedback: Submit relevant information in the feedback column",
  swap215: "Fail to edit",
  swap216: 'Charity Alliance',
  swap217: 'With love as the center and charity as the main axis, the charity alliance is initiated by loving people from all walks of life. In the way of defi + Dao, we will show fairness, fairness and openness, jointly create win-win results, resonate with the same frequency, alliance of people with lofty ideals and alliance of high-quality projects, build the core team community of Dao culture and education, and use the ecological empowerment aggregation platform to provide a platform for more people who want to do practical things but lack the ability of a good platform.',
  swap218: '1：This recruitment is provided by CSLM',
  swap219: '2：CSLM is responsible for and answers relevant information',
  swap220: 'Other zone TVL',
  swap221: 'Max zone TVL',
  swap222: 'Waiting Extraction',
  swap223: 'Rewards harvested',
  swap224: 'Extraction',
  swap225: 'Success',
  swap226: 'Fail',
  swap227: 'Referral',
  swap228: 'No records',
  swap229: 'Power',
  swap230: 'Time',
  swap231: 'Amount',
  swap232: 'Type',
  swap233: 'personal power',
  swap234: 'No ranking',
  swap235: 'Has ended',
  swap236: 'Blind Box Pre-sale',
  swap237: 'According to the data analysis report of the SWAN DAO Investment Research Center, in 2022-2023, the GameFi market will usher in explosive growth. Swan Finance will join forces with well-known game teams around the world to create a game feast belonging to the Swan Exchange, and will take the lead in providing the game with European chain games. Business Hey Fun has reached a strategic cooperation, and its business has been widely recognized in the Dubai and Japanese markets. The first strategic task of this cooperation - to launch the only token SGF in the chain game sector',
  swap238: '1: This HEY FUN blind box purchase information is provided and responsible by the HEY FUN project party.',
  swap239: '2: The blind box shall be paid with SGF equivalent to USDT.',
  swap240: '3: Please go to the chain tour center to check the cards obtained by opening the blind box.',
  swap241: 'Please wait',
  swap242: 'Not yet open',
  swap243: 'Price',
  swap244: 'Card Center',
  swap245: 'HEY FUN card',
  swap246: 'My information',
  swap247: 'My card',
  swap248: 'My level',
  swap249: 'Advanced blind box',
  swap250: 'Genesis blind box',
  swap251: 'Income to be received',
  swap252: 'Advanced blind box user',
  swap253: 'Genesis blind box user',
  swap254: 'None',
  swap255: '1: This GAP blind box purchase information is provided and responsible by the GAP game guild alliance.',
  swap256: 'Game aggregation platform is the world s first weekly aggregation game platform with membership system. It is committed to building an aggregation platform combining blockchain games and traditional online games, building a diversified service platform connecting game users, game public users and game developers, and providing game center, traffic empowerment, technical support and other related services for the three roles in the platform.',
  swap257: 'No card',
  swap258: 'Buying, please wait...',
  swap259: 'Buy blind box',
  swap260: 'activation',
  swap261: 'Check it out in the Card Center later',
  swap262: 'Genesis User',
  swap263: 'No grade yet',
  swap264: 'Congratulations on getting',
  swap265: 'Sure',
  swap266: 'Advanced',
  swap267: 'Genesis',
  swap268: 'Rare',
  swap269: 'Legendary',
  swap270: 'No more',
  swap271: 'No purchase qualification',
  swap272: 'Game aggregation platform is the world s first membership-based metaverse aggregation game platform. It is committed to creating an aggregation platform that combines blockchain games and traditional online games to build a diversified service that connects game users, game publics and game developers. platform, and provide related services such as game center, traffic empowerment and technical support for the three roles in the platform',
  swap273: 'Hashrate',
  swap274: 'Governance Center',
  swap275: 'My Staking',
  swap276: 'Waiting to receive',
  swap277: 'natural release',
  swap278: 'Team Boost',
  swap279: 'Recommended acceleration',
  swap280: 'Ordinary blind box',
  swap281:'Total release value',
  swap282:'Release progress',
  swap283:'Total release days',
  swap284:'Multiple',
  swap285:'Value',
  swap286:'equivalence',
};

