import sgfBoxAbi from '../config/abi/sgfBox.json'
import Web3 from 'web3'
//购买盲盒
function buyBlindBox(myAddress, payToken, amount, lpAddress) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(sgfBoxAbi, lpAddress);
        let hash = ''
        crossingOkContractInstance.methods.buyBlindBox(payToken,amount).send({ from: myAddress })
            .on('transactionHash', res => {
                resolve(res)
            }).on('confirmation', res => {
            }).on('error', res => {
                reject(res)
            })
    })
}

export default {
    buyBlindBox
}