export const lang = {
  swap1: '閃兌',
  swap2: '立刻開始閃兌',
  swap3: '從',
  swap4: '餘額:',
  swap5: '選擇幣種',
  swap6: '最大',
  swap7: '價格',
  swap8: '滑點容差',
  swap9: '連接錢包',
  swap10: '輸入數量',
  swap11: '餘額不足',
  swap12: '此交易的流動性不足',
  swap13: '選擇代幣',
  swap14: '授權',
  swap15: '包裝',
  swap16: '解包',
  swap17: '閃兌',
  swap18: '交易收據',
  swap19: '正在等待確認',
  swap20: '正在將',
  swap21: '交換為',
  swap22: '在你的錢包種確認此交易',
  swap23: '已提交交易',
  swap24: '在BscScan上查看',
  swap25: '關閉',
  swap26: '交易拒絕',
  swap27: '確認交換',
  swap28: '輸出為估值,您將獲得至少{inputOutMin} {symbol},或者交易將被撤',
  swap29: '輸入為估值,您將出售最多{inputInMax} {symbol},或者交易將被撤回',
  swap30: '最小獲得量',
  swap31: '最大出售量',
  swap32: '到',
  swap33: '管理代幣',
  swap34: '管理代幣',
  swap35: '導入',
  swap36: '管理Tokens',
  swap37: '自定義代幣',
  swap38: '全部清除',
  swap39: '搜索名稱或粘貼地址',
  swap40: '設定',
  swap41: '默認交易速度(GWEI)',
  swap42: '標準',
  swap43: '快速',
  swap44: '即時',
  swap46: '輸入有效的滑點百分比',
  swap47: '你的交易可能會失敗',
  swap48: '你的交易可能會被超前',
  swap49: '交易截止時間(分鐘)',
  swap50: '估計',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近沒有交易',
  swap54: '全部清除',
  swap55: '未連接',
  swap56: '加入董事會',
  swap57: '捐贈',
  swap58: '權益贈送',
  swap59: '董事會成員列表',
  swap60: '邀請',
  swap61: '複製',
  swap62: '我的DAPP邀請人:',
  swap63: '邀請記錄',
  swap64: '出錯啦,請稍後再試',
  swap65: '即將開放',
  swap66: '領取',
  swap67: '取出成功',
  swap68: '捐贈中，待確認',
  swap69: '已捐贈',
  swap70: '非白名單用戶',
  swap71: '捐贈無效',
  swap72: '當前餘額不足',
  swap73: '授權中，請稍等',
  swap74: '授權失敗',
  swap75: '捐贈中，請稍等',
  swap76: '捐贈失敗',
  swap77: '兌換',
  swap78: '流動性',
  swap79: '董事會',
  swap80: '邀请',
  swap81: '權益池',
  swap82: '資產橋',
  swap83: '池子',
  swap84: '農場',
  swap85: '首頁',
  swap86: '市場',
  swap87: '兌換',
  swap88: '交易',
  swap89: '賺取',
  swap90: '確認',
  swap91: '價格影響',
  swap92: '最小獲得量',
  swap93: '流動性供應商費用',
  swap94: '增加流動性',
  swap95: '增加流動性以接收 LP 代幣',
  swap96: '流動性池中的份額',
  swap97: '兌換率和流動池份額',
  swap98: '您的流動性',
  swap99: '移除流動性以收回代幣',
  swap100: '未找到流動性',
  swap101: '未看到您加入的流動池？',
  swap102: '查看其他LP代幣',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供應',
  swap106: '導入池',
  swap107: '導入現有流動性池',
  swap108: '選擇代幣以查找您的流動性',
  swap109: '您在該流動性池中還沒有提供流動性',
  swap110: '您的錢包中的LP代幣',
  swap111: '接收',
  swap112: '和',
  swap113: '金額',
  swap114: '你將獲得',
  swap115: '輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
  swap116: '已焚毀',
  swap117: '已入金',
  swap118: '確認供應',
  swap119: '匯率',
  swap120: '您是第一個流動性供應商。',
  swap121: '您添加代幣的比列，將决定該流動性池的初始價格。',
  swap122: '如果您對匯率滿意，請點擊“供應”以進行檢查。',
  swap123: '正在移除',
  swap124: '正在供應',
  swap125: '確認供應',
  swap126: '白皮書',
  swap127: '稽核',
  swap128: '公告',
  swap129: '提示',
  swap130: '原Swan與新Swan 1:1兌換',
  swap131: '更多',
  swap132: '正在加載',
  swap133: '信息',
  swap134: '捐贈名額已滿',
  swap135: '我的SWAN',
  swap136: '錢包中的SWAN',
  swap137: '可收割的SWAN',
  swap138: 'SWAN價格',
  swap139: 'SWAN總量',
  swap140: 'SWAN市值',
  swap141: 'SWAN信息',
  swap142: '已賺取',
  swap143: '質押',
  swap144: '個人TVL',
  swap145: '社區TVL',
  swap146: '收割',
  swap147: '累計激勵 ',
  swap148: '今日激勵',
  swap149: '參與市商地址數',
  swap150: '我的邀請人:',
  swap151: '說明:',
  swap152: '擁有推薦地址的用戶為有效地址，有效地址可以向其他鏈上地址進行推薦，若您還未擁有邀請地址，請聯繫您的邀請者或者通過反饋向我們告知。',
  swap153: '修改',
  swap154: '取消',
  swap155: '修改邀請人',
  swap156: '邀請人不能為空',
  swap157: '修改成功',
  swap158: '質押LP代幣',
  swap159: '贖回LP代幣',
  swap160: '贖回',
  swap161: '獲取',
  swap162: '當前暫無收益',
  swap163: '標題',
  swap164: '内容',
  swap165: '地址',
  swap166: '回復郵箱',
  swap167: '提交',
  swap168: '請填寫',
  swap169: '正確的回復郵箱',
  swap170: '路由',
  swap171: '價格影響過高',
  swap172: '反饋',
  swap173: '此交換的價格影響至少為10%，請確認此交換',
  swap174: '專家模式',
  swap175: "主流幣",
  swap176: "DEFI",
  swap177: "SWAN",
  swap178: "首發中心",
  swap179: "進入",
  swap180: "已完成進度",
  swap181: "總需捐獻",
  swap182: "1：總需捐獻本次Darwin領袖招募資訊由Darwin提供並負責",
  swap183: "2：本次招募可超額進行，由Darwin社區進行考核，未予通過的領袖席位將自動退回捐獻資金。",
  swap184: "聯創領袖",
  swap185: "社區領袖",
  swap186: "捐獻",
  swap187: "達爾文領袖",
  swap188: "Darwin AI Technology System是一款具有自我成長的鏈上智慧AI套利系統，通過監聽鏈上所有代幣交易當中有利可圖的滑點機會，進行Front Running（搶先提前）、Flash Loan（閃電貸）等交易，從而實現暫態超額利潤。",
  swap189: "招募計畫",
  swap190: "已捐獻",
  swap191: "捐獻中，請等待",
  swap192: "眾籌",
  swap193: "治理",
  swap194: "了解",
  swap195: "掛單",
  swap196: "聚合",
  swap197: "賺幣",
  swap198: "社區",
  swap199: "請先添加邀請人",
  swap200: "複製成功",
  swap201: "不支持複製，該瀏覽器不支持自動複製",
  swap202: "鏈遊市場",
  swap203: "自由市場",
  swap204: "無損挖礦",
  swap205: "抵押借貸",
  swap206: "NFT專區",
  swap207: "合作夥伴",
  swap208: "Swan DAO致力於創建一個大型的社區型DAO組織，為了實現DAO夢想，聚攏社區成員，打造百萬人社區，成立Swan Finance Foundation（基金會），由基金會牽頭，致力於建造一個多鏈聚合DEFI生態，囊括但不僅限於建設DEX+NFT+Socialfi+Gamefi+WB3等一眾基礎設施。",
  swap209: "為有序推動生態，Swan Finance作為首個生態應用管道推出，基於標準的AMM自動做市機制，為用戶提供高效的交易服務，V1版本為深度聚合Pancake交易，V2版本為Swan Finance交易方案。",
  swap210: "Swan是Swan DAO首個共識型代幣，總量29000枚，採取交易稅收機制，單邊收取3%交易稅收，用以鼓勵社區發展。",
  swap211: "Swan Finance以務實、持續、發展、社區為理念，保持開放態度，歡迎各平臺方申請合作，提供代幣上架、平臺收錄、技術支援、行銷孵化等",
  swap212: "聯繫方式",
  swap213: "郵件： Support@SwanFinance.cc",
  swap214: "反饋：反饋欄提交相關信息",
  swap215: "修改失敗",
  swap216: '慈善聯盟',
  swap217: '慈善聯盟以愛為中心，慈善為主軸，由各界愛心人士發起。 用DeFi+DAO管道，展現公平公正公開，共創共建共贏，與同頻共振，有志之士聯盟、優質項目聯盟，打造DAO文化教育覈心團隊社區，以生態賦能聚合平臺，讓更多想幹實事，而缺乏好平臺發揮能力的人，提供展示的平臺。',
  swap218: '本次慈善聯盟同行者招募計畫資訊由慈善聯盟負責並提供',
  swap218: '1：本次招募由CSLM方面提供',
  swap219: '2：相關資訊由CSLM負責並進行解答',
  swap220: '其他業績區TVL',
  swap221: '最大業績區TVL',
  swap222: '待收割獎勵',
  swap223: '已收割獎勵',
  swap224: '取出',
  swap225: '收割成功',
  swap226: '收割失敗，請稍後重試',
  swap227: '推薦獎勵',
  swap228: '暫無記錄',
  swap229: '算力獎勵',
  swap230: '時間',
  swap231: '數量',
  swap232: '類型',
  swap233: '個人算力',
  swap234: '暫無排名',
  swap235: '已結束',
  swap236: '盲盒預售',
  swap237: '根據SWAN DAO投研中心數據分析報告顯示，2022-2023年度，GameFi市場將迎來爆發增長，Swan Finance將聯合世界各地知名遊戲團隊一起打造屬於天鹅交易所的遊戲盛宴，並率先與歐洲鏈遊提供商Hey Fun達成戰略合作，其業務在迪拜和日本市場已取得廣泛認可。 此次合作的第一戰畧任務——順勢推出鏈遊板塊唯一代幣SGF。',
  swap238: '1: 本次HEY FUN盲盒購買資訊由HEY FUN項目方提供並負責。',
  swap239: '2: 盲盒需要使用等值USDT的SGF支付。',
  swap240: '3:開啟盲盒獲得的卡牌請到鏈遊中心查看。',
  swap241: '敬請等待',
  swap242: '暫未開放',
  swap243: '售價',
  swap244: '卡牌中心',
  swap245: 'HEY FUN卡牌',
  swap246: '我的資訊',
  swap247: '我的卡牌',
  swap248: '我的等級',
  swap249: '高級盲盒',
  swap250: '創世盲盒',
  swap251: '待領取的收益',
  swap252: '高級盲盒用戶',
  swap253: '創世盲盒用戶',
  swap254: '無',
  swap255: '1: 本次GAP盲盒購買資訊由GAP遊戲公會聯盟提供並負責。',
  swap256: 'Game aggregation platform是全球首個會員制源於周聚合遊戲平臺，致力於打造一個區塊鏈遊戲和傳統網路遊戲相結合的聚合平臺，構建連接遊戲用戶，遊戲公戶和遊戲開發商的一個多元化服務平臺，並分別為平臺內的三種角色提供遊戲中心，流量賦能和技術支援等相關服務。',
  swap257: '暫無卡牌',
  swap258: '購買中，請稍等...',
  swap259: '購買盲盒',
  swap260: '激活',
  swap261: '稍後請到卡牌中心查看',
  swap262: '創世用戶',
  swap263: '暫無等級',
  swap264: '恭喜您獲得',
  swap265: '確定',
  swap266: '高級卡牌',
  swap267: '創世卡牌',
  swap268: '稀有卡牌',
  swap269: '傳世卡牌',
  swap270: '沒有更多了',
  swap271: '尚無購買資格',
  swap272: 'Game aggregation platform是全球首個會員制元宇宙聚合遊戲平台，致力於打造一個區塊鏈遊戲和傳統網絡遊戲相結合的聚合平台，構建連接遊戲用戶，遊戲公戶和遊戲開發商的一個多元化服務平台，並分別為平台內的三種角色提供遊戲中心，流量賦能和技術支持等相關服務',
  swap273: '算力',
  swap274: '治理中心',
  swap275: '我質押的',
  swap276: '待領取',
  swap277: '自然釋放',
  swap278: '團隊加速',
  swap279: '推薦加速',
  swap280: '普通盲盒',
  swap281:'總釋放價值',
  swap282:'釋放進度',
  swap283:'總釋放天數',
  swap284:'倍數',
  swap285:'價值',
  swap286:'等值',
};
