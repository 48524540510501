<template>
  <div class="recommend">
    <!-- <div>
      <span>{{ $t('lang.swap248') }}: </span>
      <span v-if="obj.type == '0'">{{ $t('lang.swap252') }}</span>
      <span v-else-if="obj.type == '1'">{{ $t('lang.swap262') }}</span>
      <span v-else>{{ $t('lang.swap263') }}</span>
    </div> -->

    <div class="content">
      <div class="user">
        <div class="li special">
          <span>{{ $t('lang.swap280') }}</span>
          <span class="quantity">{{ obj.normal }}</span>
        </div>
        <div class="li special">
          <span>{{ $t('lang.swap249') }}</span>
          <span class="quantity">{{ obj.advance }}</span>
        </div>
        <div class="li">
          <span>{{ $t('lang.swap250') }}</span>
          <span class="quantity">{{ obj.unusual }}</span>
        </div>
      </div>
      <div class="unclaimed">
        <div>
          <span>{{ $t('lang.swap251') }}:</span>
        </div>
        <div class="quantity">
          <span>{{ usableStr || 0 }} {{ coinEn }}</span>
        </div>
      </div>
    </div>
    <div>
      <van-button type="primary" class="swapBtn" v-if="usableStr != 0" @click="takeOut">{{ $t('lang.swap66') }}</van-button>
    </div>
    <div class="list">
      <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.swap270')" @load="recordList">
        <div v-if="list">
          <div class="title">
            <span>{{ $t('lang.swap232') }}</span>
            <span>{{ $t('lang.swap231') }}</span>
            <span>{{ $t('lang.swap230') }}</span>
          </div>
          <div class="li" v-for="(item, index) of list" :key="index">
            <span class="special">{{ item.behavior_txt }}</span>
            <span>{{ item.usable_change }}</span>
            <span>{{ item.created_at.substring(0, 10) }}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { blindBox, coin } from '../../api/index';
import axios from 'axios';
export default {
  data() {
    return {
      obj: {
        advance: 0,
        normal: 0,
        unusual: 0,
      },
      coinId: '',
      usableStr: '',
      coinEn: '',
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: false,
      totalPage: 0,
    };
  },
  created() {
    // this.getInviteInfo();
    this.init();
    this.watchLang();
  },
  methods: {
    init() {
      this.list = [];
      this.page = 1;
      this.total = 0;
      this.loading = false;
      this.finished = false;
      this.totalPage = 0;
      if (this.account) {
        axios({
          method: 'get',
          url: 'https://box.swanfinance.cc/v1/reward/info',
          headers: {
            'Content-Type': 'application/json',
            'Show-Language': 'zh-CN',
            Authorization: this.account,
          },
        }).then(res => {
          // console.log(res.data.data);
          this.coinId = res.data.data.coin_id;
          this.usableStr = res.data.data.usable_str;
          this.coinEn = res.data.data.coin_en;
        });
        this.blindBox();
      }
    },

    watchLang() {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        if (e.key == 'lang') {
          location.reload();
        }
      });
    },

    async takeOut() {
      let data = {
        coin_id: this.coinId,
        chain_id: 56,
      };
      let language = await localStorage.getItem('lang');
      axios({
        method: 'post',
        url: 'https://box.swanfinance.cc/v1/order/reward-out',
        headers: {
          'Content-Type': 'application/json',
          'Show-Language': language,
          Authorization: this.account,
        },
        data,
      }).then(res => {
        this.init();
      });
      // coin.memberReward(data, this.account).then(res => {
      //   if (res.code == 200) {
      //     if (res.data) {
      //       this.$toast.success(this.$t('lang.swap225'));
      //     } else {
      //       this.$toast.fail(this.$t('lang.swap226'));
      //     }
      //   }
      //   this.memberInfo();
      // });
    },
    //盲盒信息
    async blindBox() {
      let language = await localStorage.getItem('lang');
      axios({
        method: 'get',
        url: 'https://box.swanfinance.cc/v1/blind-box/hold-summary',
        headers: {
          'Content-Type': 'application/json',
          'Show-Language': language,
          Authorization: this.account,
        },
      }).then(res => {
        this.obj = res.data.data;
        // this.recordList();
      });
    },
    //列表
    async recordList() {
      let language = await localStorage.getItem('lang');
      axios({
        method: 'get',
        url: 'https://box.swanfinance.cc/v1/reward/record-list',
        headers: {
          'Content-Type': 'application/json',
          'Show-Language': language,
          Authorization: this.account,
        },
        params: {
          page: this.page,
          page_size: this.pageSize,
        },
      }).then(res => {
        this.list = this.list.concat(res.data.data.list);
        this.total = res.data.data.total_count;
        this.totalPage = Math.ceil(this.total / this.pageSize);
        this.page++;
        this.loading = false;
        if (this.page > this.totalPage) return (this.finished = true);
      });
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.recommend {
  // position: relative;
  height: 100%;
  font-size: 18px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  // z-index: 99;
  .recommend_img {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
  }
  .content {
    border-radius: 10px;
    border: 1px solid #ffd245;
    background: #fff url('~@/assets/img/recommend_bg.png');
    background-size: 100% 100%;
    margin-top: 30px;
    .user {
      display: flex;
      justify-content: space-between;
      .li {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 16px;
        padding: 10px 10px 20px;
        .quantity {
          font-size: 24px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffd245;
          margin-top: 20px;
        }
      }
      .special {
        border-right: 1px solid #ffd245;
      }
    }
    .unclaimed {
      font-size: 16px;
      padding: 20px 10px;
      border-top: 1px solid #ffd245;
      .quantity {
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffd245;
        margin-top: 20px;
      }
    }
  }
  .swapBtn {
    width: 100%;
    height: auto;
    padding: 8px 0;
    margin-top: 20px;
  }
  .list {
    max-height: 330px;
    border-radius: 10px;
    border: 1px solid #ffd245;
    background: #fff;
    font-size: 12px;
    color: #999;
    padding: 10px;
    margin: 40px 0 20px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE10+ */
    &::-webkit-scrollbar {
      display: none; /* ChromeSafari */
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }
    .li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      // span {
      //   width: 30%;
      //   overflow: hidden; //超出的文本隐藏
      //   text-overflow: ellipsis; //溢出用省略号显示
      //   white-space: nowrap; //溢出不换行
      //   text-align: center;
      // }
      .special {
        width: 20%;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
    }
  }
}
</style>