<template>
  <div class="gameFi">
    <div class="title">
      <span>{{ $t('lang.swap202') }}</span>
    </div>

    <div class="content"
         :style="{'background': 'url('+require('@/assets/img/starry_sky.png')+') no-repeat 100% 100% / 100% 100%',    }">
      <div class="content_top">
        <div class="startingCenter">
          <img src="@/assets/img/football.png"
               class="startingCenter_img" />
        </div>
        <div class="content_title">
          <img src="@/assets/img/heyfun.png"
               class="heyfun_img" />
          <div>
            <van-button type="primary"
                        class="content_button"
                        @click="clsm('Cards')">{{ $t('lang.swap179') }}</van-button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="text">
        <span>
          {{ $t('lang.swap237') }}
        </span>
      </div>
    </div>

    <div class="content"
         :style="{'background': 'url('+require('@/assets/img/gap_bg.png')+') no-repeat 100% 100% / 100% 100%',    }">
      <div class="content_top">
        <div class="startingCenter">
          <!-- <img src="@/assets/img/football.png"
               class="startingCenter_img" /> -->
        </div>
        <div class="content_title">
          <img src="@/assets/img/gap.png"
               class="heyfun_img" />
          <div>
            <van-button type="primary"
                        class="content_button"
                        @click="clsm('gapCards')">{{ $t('lang.swap179') }}</van-button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="text">
        <span>
          {{ $t('lang.swap272') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  methods: {
    creation () {
      this.$router.push({ name: 'Creation' });
    },
    clsm (name) {
      // this.$toast(this.$t('lang.swap65'));
      this.$router.push({ name: name });
    }
  },
};
</script>

<style lang="less" scoped>
.gameFi {
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  .title {
    font-size: 24px;
    color: #333333;
    margin: 30px 0 20px;
  }
  .content {
    font-size: 10px;
    color: #ffd245;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 16px;
    padding: 10px 10px 10px;
    margin-top: 50px;
    .content_top {
      display: flex;
      .startingCenter {
        position: relative;
        flex: 5;
        .startingCenter_img {
          // position: absolute;
          width: 200px;
          // transform: translateX(0%) translateY(-40%);
        }
      }
      .content_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 3;
        font-size: 16px;
        color: #ffd245;
        text-align: center;
        margin-top: 40px;

        .heyfun_img {
          width: 100%;
        }
        .recruitment {
          font-size: 20px;
        }
      }
    }

    .text {
      padding: 0 10px;
    }
    .line {
      // border: 1px solid #c7c7c7;
      margin: 20px 0;
    }
    .content_button {
      // width: 100%;
      height: auto;
      font-size: 16px;
      color: #333333;
      padding: 8px 22px;
      border-radius: 20px;
      margin-top: 10px;
    }
  }
}
</style>