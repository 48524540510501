export const BNBTOKENLIST = [
    {
        "chainId": 56,
        "address": "0x55d398326f99059fF775485246999027B3197955",
        "name": "USDT",
        "symbol": "USDT",
        "decimals": 18,
        "logoURI": require('@/assets/TokenLogo/USDT.png')
      },
      {
        "chainId": 56,
        "address": "0x25bf35b089dacb352eadb802a685207d31e23a9f",
        "name": "Swan",
        "symbol": "Swan",
        "decimals": 18,
        "logoURI": require('@/assets/TokenLogo/Swan.png')
      },
      {
        "chainId": 56,
        "address": "0xb61e1493cb2cbbf2e3e89872c6095aa000d5db32",
        "name": "HAP",
        "symbol": "HAP",
        "decimals": 18,
        "logoURI": require('@/assets/TokenLogo/HAP.png')
      },
      {
        "chainId": 56,
        "address": "0x93dabd824adf1fea0a2d5869c26dd4035cd8b6b7",
        "name": "GOC",
        "symbol": "GOC",
        "decimals": 18,
        "logoURI": require('@/assets/TokenLogo/GOC.png')
      },
]