<template>
  <div>
    <div>
      <div class="functional_region">
        <div class="region">
          <div v-for="(item,index) of functionOption"
               :key="index">
            <span :class="index ==regionIndex?'special':''"
                  @click="switchovers(index,item.route)">{{$t(item.name)}}
              <img src="@/assets/img/yellow_dot.png"
                   :class="index ==regionIndex?'yellow_dot':''">
            </span>

          </div>
        </div>
      </div>
    </div>
    <div>
      <router-view v-if="isRouterAlive" />
    </div>
    <!-- <div>
      <div class="functional_region functional_special">
        <div class="region">
          <div :class="switchVersionShow =='0'?'special':''">
            <span @click="switchVersion('0')">PANCAKE</span>
          </div>
          <div :class="switchVersionShow =='1'?'special':''">
            <span @click="switchVersion('1')">SWAN</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { basic } from '../../api/index';
export default {
  data () {
    return {
      // 功能列表
      functionOption: [{ name: 'lang.swap77', route: 'Swap' }, { name: 'lang.swap78', route: 'LiquidityIndex' }, { name: 'lang.swap195', route: '' }, { name: 'lang.swap196', route: '' },],
      regionIndex: '0',
      switchVersionShow: '0',
      isRouterAlive: true
    };
  },
  created () {
    this.getBasicList();
    this.regionIndex = this.$route.name == 'Swap' ? '0' : '1';
  },
  mounted () { },
  methods: {
    switchovers (index, route) {
      if (route != '') {
        this.regionIndex = index;
        this.$router.push({ name: route });
      } else {
        this.$toast(this.$t('lang.swap65'));
      }

    },

    getBasicList () {
      let data = {
        page: 1,
        page_size: 20,
      };
      basic.getNoticeList(data, '0x3D12Bd39bB936a73575ea97dFbf308b08b84e76B', 'zh-cn').then(res => {
        if (res.code === 200) {
        }
      });
    },
    // switchVersion (i) {
    //   let a = {};
    //   if (i == 0) {
    //     a = {
    //       jackRouter: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    //       jackFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    //     };
    //   } else {
    //     a = {
    //       jackRouter: '0x6D327b4cc271652D2bB9765A4d079a3258964a35',
    //       jackFactory: '0x04E61DF94148dD533f7f180d0B9dAC00e6E68Be0',
    //     };
    //   }
    //   localStorage.setItem('pool', JSON.stringify(a));
    //   this.$store.commit('SET_POOL', a);
    //   this.switchVersionShow = i;
    //   this.isRouterAlive = false;
    //   this.$nextTick(() => {
    //     this.isRouterAlive = true
    //   })
    // },
  },
};
</script>

<style lang="less" scoped>
.functional_region {
  width: 100%;
  display: flex;
  // justify-content: center;
  .region {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    line-height: 22px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #666;
    font-weight: bold;
    // border: 1px solid #ffd245;
    background: #fff;
    border-radius: 34px;
    margin: 12px 0;
    span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 192rpx;
      padding: 4px 0;
      cursor: pointer;
      z-index: 6;
    }
    .special {
      font-size: 14px;
      color: #333;
      border-radius: 16px;
      // background: url('~@/assets/img/yellow_dot.png') no-repeat left top;
      // background-size: 10px 10px;
    }
    img {
      display: none;
    }
    .yellow_dot {
      display: block;
      position: absolute;
      left: 0;
      top: 20%;
      width: 10px;
      height: 10px;
      z-index: 1;
      transform: translateX(-80%) translateY(0);
    }
  }
}
</style>