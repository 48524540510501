<template>
  <div class="stakePool">
    <div class="title">
      <span>{{ $t('lang.swap81') }}</span>
    </div>
    <div class="content">
      <div class="tvl_list">
        <div class="tvl">
          <div>
            <span>{{ $t('lang.swap144') }}</span>
          </div>
          <div class="tvl_value">
            <span>{{memberTVL}}</span>
            <img class="money_img"
                 src="@/assets/img/money.png" />
          </div>
        </div>
        <div class="tvl">
          <div>
            <span>{{ $t('lang.swap220') }}</span>
          </div>
          <div class="tvl_value">
            <span>{{otherTVL}}</span>
            <img class="money_img"
                 src="@/assets/img/money.png" />
          </div>
        </div>
        <div class="tvl">
          <div>
            <span>{{ $t('lang.swap221') }}</span>
          </div>
          <div class="tvl_value">
            <span>{{maxTVL}}</span>
            <img class="money_img"
                 src="@/assets/img/money.png" />
          </div>
        </div>
        <div class="tvl">
          <div>
            <span>{{ $t('lang.swap233') }}</span>
          </div>
          <div class="tvl_value">
            <span>{{power}}</span>
          </div>
        </div>
      </div>

      <div class="pending">
        <div>
          <span>{{ $t('lang.swap222') }}</span>
        </div>
        <div class="award">
          <span class="award_quantity">{{reward}}</span>
          <span>SWAN</span>
        </div>
      </div>
      <div>
        <van-button type="primary"
                    class="swapBtn"
                    :class="reward == 0?'reward':''"
                    :disabled="reward == 0?true:false"
                    @click="takeOut">{{ $t('lang.swap146') }}</van-button>
      </div>
      <div class="pending">
        <div>
          <span>{{ $t('lang.swap223') }}</span>
        </div>
        <div class="award">
          <span class="award_quantity">{{rewardOut}}</span>
          <span>SWAN</span>
        </div>
      </div>

      <div class="content_list">
        <div class="title">
          <div class="li"
               v-for="(item,index) of titleList"
               :key="index">
            <span>{{$t(item.text)}}</span>
          </div>
        </div>
        <div class="hide"
             v-if="list.length==0">
          <img src="@/assets/img/reject.png"
               class="" />
          <span>{{$t('lang.swap228')}}</span>
        </div>
        <div class="list"
             v-else>
          <div class="li"
               v-for="(item,index) of list"
               :key="index">
            <span>{{item.createTime}}</span>
            <span>{{item.amount}}</span>
            <span :class="item.type ==1?'nature':'dividend'">{{item.type ==1?$t('lang.swap227'):$t('lang.swap229')}}</span>
          </div>
        </div>
      </div>
      <!-- <div class="content_list">
        <div class="title">
          <div class="power">
            <span>算力榜</span>
          </div>
        </div>
        <div class="hide"
             v-if="powerList.length==0">
          <img src="@/assets/img/reject.png"
               class="" />
          <span>{{$t('lang.swap234')}}</span>
        </div>
        <div class="power_list"
             v-else>
          <div class="power_li"
               v-for="(item,index) of powerList"
               :key="index"
               :class="index+1 <=3?`special${index+1}`:''">
            <span class="index">{{index+1}}</span>
            <div class="li_right">
              <span>{{item.address.substring(0, 4)+ '******' + item.address.substring(item.address.length - 6, item.address.length)}}</span>
              <span class="span">{{item.power}}</span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { coin } from '../../api/index';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      memberTVL: 0, //个人
      otherTVL: 0, //其他
      maxTVL: 0,  //最大
      power: 0,  //算力
      reward: 0,
      rewardOut: 0,
      titleList: [
        { text: 'lang.swap230', },
        { text: 'lang.swap231', },
        { text: 'lang.swap232', },
      ],
      list: [], //
      powerList: [] //算力榜
    }
  },
  async created () {
    this.getUserTvl()
    this.memberInfo()
    this.memberRewardRecord()
    this.memberPower()
  },
  methods: {
    //V1个人TVL
    getUserTvl () {
      coin.userTvl(this.account).then(res => {
        if (res.code == 200) {
          this.memberTVL = res.data.memberTVL;
          this.otherTVL = res.data.communityOtherTVL;
          this.maxTVL = res.data.communityMaxTVL;
          this.power = res.data.power;
        }
      });
    },
    async memberInfo () {
      coin.memberInfo(this.account).then(res => {
        if (res.code == 200) {
          this.reward = res.data.reward;
          this.rewardOut = res.data.rewardOut;
        }
      });
    },
    takeOut () {
      let data = {
        type:0
      }
      coin.memberReward(data,this.account).then(res => {
        if (res.code == 200) {
          if (res.data) {
            this.$toast.success(this.$t('lang.swap225'));
          } else {
            this.$toast.fail(this.$t('lang.swap226'));
          }
        }
        this.memberInfo()
      });
    },
    memberRewardRecord () {
      coin.memberRewardRecord(this.account).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      });
    },
    // 算力榜
    memberPower () {
      coin.memberPower(this.account).then(res => {
        if (res.code == 200) {
          this.powerList = res.data
        }
      });
    }
  },
  watch: {
    account () {
      //获取账号
      this.getUserTvl()
      this.memberInfo()
      this.memberRewardRecord()
      this.memberPower()
    },
  },
  mounted () {
    this.timer = setInterval(async () => {
      this.getUserTvl()
      this.memberInfo()
    }, 8000);
  },
  // 清除定时器
  beforeDestroy () {
    window.clearInterval(this.timer);
  },
  computed: {
    ...mapState(['account']),
  },
}
</script>

<style lang="less" scoped>
.stakePool {
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  margin-top: 20px;

  .title {
    font-size: 16px;
    color: #333333;
  }
  .content {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 12px 16px;
    margin: 20px 0;
    .tvl_list {
      margin-bottom: 60px;
      .tvl {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: Segoe UI-Bold, Segoe UI;
        font-weight: bold;
        color: #333333;
        background: #ffffff;
        border: 1px solid #ffd245;
        border-radius: 10px;
        padding: 6px 12px;
        margin-top: 12px;
        div {
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .money_img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .pending {
      font-size: 12px;
      color: #333333;
      // margin-top: 20px;
      .award {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        // margin-top: 10px;
        .award_quantity {
          font-size: 20px;
          color: #ffd245;
          margin-right: 6px;
        }
      }
    }
    .swapBtn {
      width: 100%;
      height: auto;
      font-size: 16px;
      color: #333333;
      border-radius: 16px;
      padding: 6px 0;
      margin: 40px 0;
    }
    .reward {
      background: #999;
      border: 0;
    }
    .content_list {
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-bottom: 1px solid #ffd245;

      border-radius: 10px;
      margin: 30px 0 30px;
      padding-bottom: 10px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 10px 10px 0px 0px;
        // padding: 16px 10px 8px;
        .power {
          position: relative;
          font-size: 22px;
          line-height: 22px;
          text-shadow: 0px 2px 3px #efa842;
          background: url('~@/assets/img/computational.png') no-repeat;
          background-size: 100% 100%;
          padding: 30px 100px;
          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-70%);
          }
        }
        .li {
          width: 33%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0;
          .li_img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }
      }
      .hide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 40px 0;
      }
      .list {
        overflow: auto;
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        padding: 10px;
        .li {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 10px 0;
          border-bottom: 1px solid #ffd245;
          span {
            width: 30%;
            text-align: center;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .advance {
            color: #29ebeb;
          }
          .dividend {
            color: #ff3636;
          }
          .nature {
            color: #ffd245;
          }
        }
      }
      .power_list {
        max-height: 500px;
        overflow: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE10+ */
        &::-webkit-scrollbar {
          display: none; /* ChromeSafari */
        }
        padding: 10px;
        .power_li {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: normal;
          color: #333333;
          background: #eeeeee85;
          border-radius: 6px;
          margin-top: 10px;
          .li_right {
            flex: 1;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            padding: 4px 10px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            .span {
              width: 40%;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; //溢出不换行
              text-align: center;
            }
          }
          .index {
            width: 10%;
            text-align: center;
          }
        }
        .special1 {
          background: #ffd245;
          color: #ffffff;
        }
        .special2 {
          background: #ffd145cb;
          color: #ffffff;
        }
        .special3 {
          background: #ffd14581;
          color: #ffffff;
        }
      }
    }
  }
}
</style>