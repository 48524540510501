<template>
  <div class="header">
    <div span="12"
         class="spread">
      <img src="@/assets/img/favicon.png"
           class="favicon_img"
           @click="menuShow" />
      <img src="@/assets/img/spread.png"
           class="spread_img"
           @click="handleScroll" />
    </div>
    <div span="12"
         class="rightItem">
      <!-- <img src="@/assets/img/set.png"
           @click="handleSettingShow"
           alt=""
      class="rightIcon" />-->
      <div class="addressBox"
           v-if="account">
        <div class="account">{{ getAccount }}</div>
      </div>
      <van-button type="primary"
                  class="loginBtn"
                  v-else
                  @click="handleLogin">{{ $t('lang.swap55') }}</van-button>
      <div class="settings">
        <div class="language_switching edition"
             @click="editionSwitchLanguage">
          <span>{{ editionLanguage }}</span>
        </div>
        <div v-show="editionCondition">
          <div class="multilingual">
            <div v-for="(item, index) of edition"
                 :key="index"
                 @click="editionSwitching(item)"
                 :class="item.text == editionLanguage ? 'special' : 'prohibit'">
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="overlay"
               @click="handleAction"></div>
        </div>
      </div>
      <div class="settings">
        <div class="language_switching"
             @click="switchLanguage">
          <img class="language"
               src="@/assets/img/lang.png" />
          <!-- <span>{{ currentLanguage }}</span> -->
        </div>
        <div v-show="multilingualCondition">
          <div class="multilingual">
            <div v-for="(item, index) of actions"
                 :key="index"
                 @click="languageSwitching(item)"
                 :class="item.text == currentLanguage ? 'special' : ''">
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="overlay"
               @click="handleAction"></div>
        </div>
      </div>
    </div>

    <van-popup v-model="show"
               round
               position="left"
               @close="close"
               class="side">
      <div class="menu">
        <div class="user">
          <div class="invitation">
            <div>
              <img src="@/assets/img/invitations.png"
                   class="invitation_img" />
              <span>{{$t('lang.swap60') }}</span>
            </div>
            <div class="more"
                 @click="toNav('Invitation')">
              <span>{{$t('lang.swap131') }}</span>
            </div>
          </div>
          <div class="address">
            <div class="invitation_address">
              <span>{{invited || $t('lang.swap199')}}</span>
            </div>
            <div class="copys"
                 @click="copy"
                 :data-clipboard-text="this.invite_link">
              <span>{{$t('lang.swap61') }}</span>
            </div>
          </div>
        </div>
        <van-collapse v-model="activeNames">
          <van-collapse-item :name="index + 1"
                             v-for="(item, index) of menuList"
                             :key="index"
                             title-class="menu_li"
                             :is-link="false">
            <template #title>
              <div @click="toNav(item.router, 'title')"
                   class="title">
                <div>
                  <img :src="item.image"
                       class="slot_image" />
                  <span>{{ $t(item.name) }}</span>
                </div>
                <span>></span>
              </div>
            </template>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
    <van-popup v-model="settingShow"
               round>
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
    <!-- <Sidebar :SidebarShow="SidebarShow" /> -->
    <Sidebar :pageShow="pageShow" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import SettingsModal from '@/components/SettingsModal.vue';
import Sidebar from '@/components/Sidebar.vue';
import axios from 'axios';
import { coin } from '../api/index';
import Clipboard from 'clipboard';
export default {
  name: 'Header',
  components: {
    SettingsModal,
    Sidebar,
  },
  data () {
    return {
      settingShow: false,
      showPopover: false,
      price: 0,
      currentLanguage: '',
      actions: [
        { text: '中文简体', lang: 'zh-CN', className: 'langPopover' },
        { text: 'English', lang: 'en-US', className: 'langPopover' },
        { text: '中文繁体', lang: 'zh-HK', className: 'langPopover' },
      ],
      edition: [{ text: 'V1' }, { text: 'V2' }],
      editionLanguage: 'V2',
      editionCondition: false,
      show: false,
      // menuList: [
      //   //下拉菜单
      //   {
      //     image: require('@/assets/img/home_page.png'),
      //     name: 'lang.swap85',
      //     subordinate: [],
      //     router: 'Index',
      //   },
      //   {
      //     image: require('@/assets/img/market.png'),
      //     name: 'lang.swap86',
      //     router: '',
      //     subordinate: [
      //       { name: 'lang.swap79', router: 'boardOfDirectors' },
      //       { name: 'lang.swap80', router: 'Invitation' },
      //       { name: 'lang.swap81', router: '' },
      //       { name: 'DAO', router: '' },
      //     ],
      //   },
      //   {
      //     image: require('@/assets/img/transaction.png'),
      //     name: 'lang.swap88',
      //     router: '',
      //     subordinate: [
      //       { name: 'lang.swap87', router: 'Swap' },
      //       { name: 'lang.swap78', router: 'LiquidityIndex' },
      //       { name: 'lang.swap82', router: '' },
      //       { name: 'lang.swap1', router: 'FlashExchange' },
      //     ],
      //   },
      //   {
      //     image: require('@/assets/img/earn.png'),
      //     name: 'lang.swap89',
      //     router: '',
      //     subordinate: [
      //       { name: 'lang.swap83', router: '' },
      //       { name: 'lang.swap84', router: 'Mining' },
      //     ],
      //   },
      //   {
      //     image: require('@/assets/img/nft.png'),
      //     name: 'NFT',
      //     subordinate: [],
      //     router: '',
      //   },
      //   {
      //     image: require('@/assets/img/game.png'),
      //     name: 'GAME',
      //     subordinate: [],
      //     router: '',
      //   },
      //   {
      //     image: require('@/assets/img/startingCenters.png'),
      //     name: 'lang.swap178',
      //     subordinate: [],
      //     router: 'StartingCenter',
      //   },

      //   {
      //     image: require('@/assets/img/message.png'),
      //     name: 'lang.swap133',
      //     router: '',
      //     subordinate: [
      //       // { name: '白皮书', router: '' },
      //       // { name: '审计', router: '' },
      //       // { name: 'Support@SwanFinance.cc', router: '' },
      //       { name: 'lang.swap128', router: 'NoticeIndex' },
      //       { name: 'lang.swap172', router: 'Feedback' },
      //     ],
      //   },
      // ],
      menuList: [
        //下拉菜单
        {
          image: require('@/assets/img/home_pages.png'),
          name: 'lang.swap85',
          router: 'Index',
        },
        // {
        //   image: require('@/assets/img/rechange.png'),
        //   name: 'lang.swap1',
        //   router: 'FlashExchange',
        // },
        {
          image: require('@/assets/img/rightsAndInterests.png'),
          name: 'lang.swap81',
          router: 'StakePool',
        },
        {
          image: require('@/assets/img/crowdFunding.png'),
          name: 'lang.swap178',
          router: 'StartingCenter',
        },
        {
          image: require('@/assets/img/government.png'),
          name: 'lang.swap193',
          router: 'govern',
        },
        {
          image: require('@/assets/img/notices.png'),
          name: 'lang.swap128',
          router: 'NoticeIndex',
        },
        {
          image: require('@/assets/img/feedback.png'),
          name: 'lang.swap172',
          router: 'Feedback',
        },

        {
          image: require('@/assets/img/understand.png'),
          name: 'lang.swap194',
          router: 'Understand',
        },
        {
          image: require('@/assets/img/gameFi.png'),
          name: 'lang.swap202',
          router: 'GameFi',
        },
        // {
        //   image: require('@/assets/img/gameFi.png'),
        //   name: 'lang.swap202',
        //   router: 'GameFi',
        // },
      ],
      activeNames: ['0'],
      multilingualCondition: false,
      invite_link: '',
      pageShow: true
    };
  },
  computed: {
    ...mapState(['account']),
    getAccount () {
      if (this.account) {
        return '0x...' + this.account.substring(this.account.length - 4, this.account.length);
      } else {
        return '';
      }
    },
    invited () {
      if (this.account && this.invite_link) {
        return this.account.substring(0, 7) + '*****' + this.account.substring(this.account.length - 6, this.account.length);
      } else {
        return '';
      }
    },
  },
  created () {
    this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN';
    // this.currentLanguage = this.$i18n.locale == 'zh' ? '中文' : 'English';
    for (let i in this.actions) {
      if (this.actions[i].lang == this.$i18n.locale) {
        this.currentLanguage = this.actions[i].text;
      }
    }
    // axios
    //   .get('https://api.pancakeswap.info/api/v2/tokens/0x25BF35B089DAcB352eadB802a685207d31E23a9F')
    //   .then(res => {
    //     this.price = (res.data.data.price * 1).toFixed(2);
    //     console.log(res);
    //   })
    //   .catch(e => { });
    // this.editions();
    this.init();
  },
  mounted () {
  },
  methods: {
    // 获取邀请地址
    init () {
      if (this.account) {
        let data = {
          address: this.account,
        };
        coin.getAccount(data).then(res => {
          if (res.code == 200) {
            this.invite_link = res.data.inviteLink;
          }
        });
      }
    },
    // 复制
    copy () {
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap200'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap201'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    handleLogin () {
      // this.$toast("链接了钱包");
      this.$store.dispatch('setWebProvider');
    },
    handleSettingShow () {
      this.settingShow = true;
    },
    handleSettingClose () {
      this.settingShow = false;
    },
    languageSwitching (item) {
      // let lang = this.lang.$i18n.locale === 'zh' ? 'en' : 'zh';

      this.currentLanguage = item.text;
      this.$i18n.locale = item.lang;
      localStorage.setItem('lang', item.lang);
      this.multilingualCondition = false;
    },
    changeLaguages () {
      let lang = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      this.$i18n.locale = lang;
    },
    menuShow () {
      this.show = true;
      this.init();
    },
    close () {
      this.show = false;
      // console.log('close');
    },
    toNav (router, title) {
      // if (title) {
      //   if (router != '') {
      //     this.$router.push({ name: router });
      //     this.close();
      //   }
      // } else {
      if (this.$route.name == router) return
      if (router != '') {
        this.$router.push({ name: router });
        this.close();
      } else {
        this.$toast(this.$t('lang.swap65'));
      }
      // }
    },
    //语言弹窗的状态
    switchLanguage () {
      this.multilingualCondition = !this.multilingualCondition;
    },
    editionSwitchLanguage () {
      this.editionCondition = !this.editionCondition;
    },
    editionSwitching (item) {
      // this.editionLanguage = item.text;
      // let a = {};
      // if (item.text == 'V1') {
      //   a = {
      //     jackRouter: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
      //     jackFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
      //   };
      // } else {
      //   a = {
      //     jackRouter: '0x6D327b4cc271652D2bB9765A4d079a3258964a35',
      //     jackFactory: '0x04E61DF94148dD533f7f180d0B9dAC00e6E68Be0',
      //   };
      // }
      // localStorage.setItem('pool', JSON.stringify(a));
      // this.$store.commit('SET_POOL', a);
      // console.log('cccc');
      // this.$emit('reload', item.text);
      // this.editionCondition = false;
    },
    handleAction () {
      this.multilingualCondition = false;
      this.editionCondition = false;
    },
    // editions () {
    //   const pool = JSON.parse(localStorage.getItem('pool'));
    //   if (!pool) return;
    //   this.editionLanguage = pool.jackFactory == '0xca143ce32fe78f1f7019d7d551a6402fc5350c73' ? 'V1' : 'V2';
    //   this.$store.commit('SET_POOL', pool);
    // },
    handleScroll () {
      this.pageShow = !this.pageShow
    }
  },
  watch: {
    account () {
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  // position: relative;
  height: 50px;
  // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffd245;
  background: #fff;
  border-left: 0;
  border-right: 0;
  padding: 0 16px;
  z-index: 99999;
  .rightItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #595b5b;
      background: #ffd245;
      border-radius: 16px;
      padding: 4px 10px;
      margin-left: 20px;
    }
    .rightIcon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .loginBtn {
      height: 28px;
      font-weight: bold;
      background: #ffd245;
      color: #fff;
    }
  }
  .addressBox {
    max-width: 150px;
    line-height: 20px;
    font-size: 20px;
    // padding: 5px;
    display: flex;
    align-items: center;
  }
  .walletBox {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: -12px;
    z-index: 10;
    .wallet {
      height: 30px;
      width: 30px;
    }
  }
  .account {
    font-family: Kanit, sans-serif;
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #ffd245;
    padding: 0 10px 0 10px;
    line-height: 28px;
    color: #fff;
    font-weight: bold;
    border-radius: 16px;
    box-shadow: 0px 1px 0px 0.5px #ffd245;
  }
  .spread {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .favicon_img {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }
    .spread_img {
      display: none;
    }
  }
  /deep/ .van-collapse-item,
  .van-hairline--top-bottom {
    border: 0 !important;
    position: static;
  }
  /deep/ .van-collapse-item__content {
    padding: 0;
    position: static;
  }
  /deep/ .van-collapse-item {
    padding: 16px 14px !important;
  }
  /deep/ .van-collapse-item--border::after {
    border: 0 !important;
  }
  .side {
    height: 100%;
    width: 80%;
    // border: 1px solid #ffd0c1;
    .user {
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      background: linear-gradient(180deg, #ffd245 0%, #fff1c4 100%);
      box-shadow: 0px 3px 6px 1px #ffd85e;
      border-radius: 16px;
      padding: 10px 14px;
      margin: 0 14px 30px;
      .invitation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .invitation_img {
          width: 26px;
          height: 26px;
        }
        .more {
          font-size: 12px;
          border-radius: 16px;
          border: 1px solid #ffffff;
          padding: 2px 12px;
        }
      }
      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #333333;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        margin-top: 20px;
        .invitation_address {
          background: #fff;
          border-radius: 16px;
          padding: 4px 16px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .copys {
          background: #fff;
          border-radius: 16px;
          padding: 4px 16px;
          margin-left: 10px;
        }
      }
    }
  }
  .menu {
    // position: relative;
    height: 100%;
    overflow: auto;
    background-color: #fff;
    border-radius: 0px 16px 16px 0px;
    // overflow: hidden;
    // border: 2px solid #ffd245;
    padding: 14px 0 60px;

    .menu_li {
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
      }
    }
    .slot_image {
      width: 20px;
      height: 20px;
      margin-right: 18px;
    }
    .menu_list {
      color: #606266;
      font-size: 12px;
      padding: 8px 0 8px 30px;
    }
  }
  .settings {
    display: flex;
    position: relative;
    font-weight: bold;
    font-family: YouSheBiaoTiYuan-Regular, YouSheBiaoTiYuan;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      background: #ffd245;
      border-radius: 16px;
      padding: 0px 8px 0 8px;
      margin-left: 10px;
    }
    .language {
      width: 30px;
      height: 30px;
      padding: 4px;
    }
    .multilingual {
      position: absolute;
      top: 34px;
      right: 0;
      font-size: 14px;
      width: 92px;
      color: #333;
      background: #fff;
      box-shadow: 0px 2px 6px 0px rgba(16, 27, 82, 0.5);
      border-radius: 6px;
      text-align: center;
      padding: 2px;
      z-index: 999;
      div {
        padding: 6px 0;
      }
      .special {
        color: #ffd245;
      }
      .prohibit {
        color: #999;
      }
    }
    .overlay {
      position: fixed;
      z-index: 998;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.144);
      margin-top: 48px;
    }
  }
  .edition {
    padding: 5px 20px !important;
  }
}
@media screen and (min-width: 1000px) {
  .header {
    .side {
      height: 100%;
      width: 20%;
      border: 1px solid #ffd0c1;
    }
  }
}
@media screen and (min-width: 700px) {
  .header {
    .spread {
      cursor: pointer;
      .favicon_img {
        display: none;
      }
      .spread_img {
        display: block;
        width: 20px;
        height: 16px;
        margin: 0 10px;
      }
    }
  }
}
</style>
