import axios from "axios";
import { Toast } from "vant";

const service = axios.create({
    // baseURL: 'http://192.168.11.236:8085',
    baseURL: 'https://api.swanfinance.cc',
});


service.interceptors.request.use((config) => {
    config.data = config.data
    return config
});

// ajax响应拦截器;
service.interceptors.response.use(
    (res) => {
        // 获取后端返回的数据与状态码;
        // 状态码位200时, 则无异常;
        if (res.status === 200) {
            return res.data;
        } else if (res.status === 401) {  //token失效，删除登录信息
        } else if (res.status === 500 || 5101) {
            // 500 网络错误(服务端错误)
            Toast({ message: `${res.data.msg}` || "Something went wrong, please try again later", className: 'toastLevel' });
            return Promise.reject(res.data);
        } else {                      // 状态码不为200时, 则视情况处理;
            return Promise.reject(res.data);
        }
    },
    (error) => {
        if (error.message.indexOf("status code 500") > -1) {
            Toast("Server error, please try again later");
        }
        if (error.message.indexOf("timeout") > -1) {
            Toast("Connection timed out");
        }
        if (error && error.response) {
            // 登录过期
            if (error.response.status === 401) {
                // 清除登录
            } else {
                Toast(`${error.response.status}-${error.response.data.message}`);
            }
        }
        return Promise.reject(error);
    }
);
export default service;