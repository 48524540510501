// import addresses from '@/config/constants/contracts.js';
import store from '@/store';
// let addresses = {}
// addresses = store.state.pool
// window.addEventListener('setItemEvent', function (e) {
//   if (e.key == 'pool') {
//     addresses = JSON.parse(e.newValue)
//   }
// });

const addresses = {
  jackRouter: '0x6D327b4cc271652D2bB9765A4d079a3258964a35',
  jackFactory: '0x04E61DF94148dD533f7f180d0B9dAC00e6E68Be0',
  weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
};

export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
