<template>
  <div class="gapCards">
    <div class="title">
      <span>{{ $t('lang.swap247') }}</span>
      <span class="gap">GAP</span>
    </div>
    <div v-if="cardsList.length == 0"
         class="tips">
      <span>{{ $t('lang.swap257') }}</span>
    </div>
    <van-list v-model="loading"
              :finished="finished"
              :finished-text="$t('lang.swap270')"
              @load="cardInfo"
              v-else>
      <div class="cards_list">
        <div class="li"
             v-for="(item, index) of cardsList"
             :key="index">
          <img :src="item.imgUrl"
               class="img" />
          <!-- <span>{{item.starName}}</span> -->
          <div :style="{'margin' : '6px 0 10px','color':'#ffd245'}">
            <!-- <span>#{{item.code}}</span>
            <span class="grade"
                  v-if="item.type == 0">{{ $t('lang.swap266') }}</span>
            <span class="grade"
                  v-else-if="item.type == 1">{{ $t('lang.swap267') }}</span>
            <span class="grade"
                  v-else-if="item.type == 2">{{ $t('lang.swap268') }}</span>
            <span class="grade"
                  v-else-if="item.type == 3">{{ $t('lang.swap269') }}</span> -->
            <span>{{ item.price }} * {{ item.incomeRate / 100 }} {{$t('lang.swap273')}}</span>
            <!-- <span>{{item.incomeRate}}%</span> -->
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { blindBox } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      cardsList: [
        // { type: 1, price: '456', incomeRate: '450', imgUrl: require('@/assets/img/Rectangle 8.png') },
        // { type: 1, code: '456', starName: '456', imgUrl: require('@/assets/img/creation_one.png') },
        // { type: 1, code: '456', starName: '456', imgUrl: require('@/assets/img/creation_one.png') },
        // { type: 1, code: '456', starName: '456', imgUrl: require('@/assets/img/creation_one.png') },
        // { type: 1, code: '456', starName: '456', imgUrl: require('@/assets/img/creation_one.png') },
        // { type: 1, code: '456', starName: '456', imgUrl: require('@/assets/img/creation_one.png') },
      ],
      pageNum: 1, // 页码
      pageSize: 10,
      total: 0,
      totalPage: 0, // 总页数（需要前端自己算）
      loading: false,
      finished: false,
    };
  },
  created () {
    this.cardInfo();
  },
  methods: {
    cardInfo () {
      if (this.account) {
        let data = {
          pageNum: this.pageNum * 1,
          pageSize: this.pageSize * 1,
        };
        blindBox.gapCardInfo(data, this.account).then(res => {
          if (res.code == 200) {
            this.cardsList = this.cardsList.concat(res.data.rows);
            this.total = res.data.total;
            this.totalPage = Math.ceil(res.data.total / this.pageSize);
            // this.finished = false
            this.pageNum++
            this.loading = false;
            if (this.pageNum > this.totalPage) return (this.finished = true);
          }
        });
      }
    },
    // 下一页
    // onLoads () {
    //   if (this.totalPage == 0) return
    //   if (this.pageNum > this.totalPage) return (this.finished = true);
    //   this.cardInfo();
    //   this.loading = false;
    // },
  },
  watch: {
    account () {
      this.cardInfo();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.gapCards {
  // height: 500px;
  font-size: 18px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  // overflow: auto;
  margin-top: 20px;
  .title {
    // position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // background: #f5f5f5;
    // left: 0;
    // top: 50px;
    align-items: center;
    .gap {
      background: #ffd245;
      border-radius: 8px;
      padding: 2px 10px;
    }
  }
  .tips {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
  .cards_list {
    display: flex;
    // height: 300px;
    justify-content: space-between;
    flex-wrap: wrap;
    // overflow: auto;

    // padding: 20px 0 10px;
    margin: 20px 0 10px;
    .li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 46%;
      font-size: 12px;
      background: url('~@/assets/img/background_gap.png') no-repeat;
      background-size: 100% 100%;
      margin-bottom: 10px;
      white-space: nowrap; //溢出不换行
      .img {
        width: 100%;
      }
      .grade {
        display: inline-block;
        margin: 6px 6px 14px;
      }
      .arrows {
        width: 20px;
      }
    }
  }
}
</style>