<template>
  <div class="liquidity">
    <div class="exchange_function">
      <div class="function_top">
        <div @click="liquidity"
             class="function_button">
          <div>
            <img class="left_arrow"
                 src="@/assets/img/left_arrow.png" />
          </div>
          <div class="liquidity_title">
            <span class="title">{{ $t("lang.swap94") }}</span>
            <div>
              <span>{{ $t("lang.swap95") }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="install_img">
            <img class="install"
                 src="@/assets/img/set.png"
                 @click="handleSettingShow" />
            <img class="refresh"
                 src="@/assets/img/record.png"
                 @click="handleRecordShow" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="promote"
           v-if="firstShow">
        <div class="promote_subtitle">
          <span>{{ $t("lang.swap120") }}</span>
          <img class="warning"
               src="@/assets/img/warning.png" />
        </div>
        <div class="special">
          <span>{{ $t("lang.swap121") }}</span>
        </div>
        <div>
          <span>{{ $t("lang.swap122") }}</span>
        </div>
      </div>

      <div class="exchange_currency">
        <div class="currency">
          <div class="currency_right"
               v-if="fromCur.symbol !== ''">
            <span class="max"
                  @click="handleToMax('from')">{{ $t("lang.swap6") }}</span>
            <div @click="handleGetCurrency('from')"
                 class="currencys_right">
              <img class="image"
                   :src="fromCur.logoUrl" />
              <span class="bnb">{{ fromCur.symbol }}</span>
              <img class="arrows"
                   src="@/assets/img/arrows.png" />
            </div>
          </div>

          <div class="currency_right"
               v-else
               @click="handleGetCurrency('from')">
            <span class="bnb">{{ $t("lang.swap5") }}</span>
            <img class="arrows"
                 src="@/assets/img/arrows.png" />
          </div>
          <div class="amount">
            <div>
              {{ $t("lang.swap4") }}
              <span class="overstriking">
                {{
                significantDigits(fromCur.balance)
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="balance">
          <van-field class="tokenInput"
                     v-model="fromInput"
                     placeholder="0"
                     @input="hanldeInputFrom" />
        </div>
      </div>

      <div class="iconDown">
        <span>+</span>
      </div>

      <div class="exchange_currency">
        <div class="currency">
          <div class="currency_right"
               v-if="toCur.symbol !== ''">
            <span class="max"
                  @click="handleToMax('to')">{{ $t("lang.swap6") }}</span>
            <div @click="handleGetCurrency('to')"
                 class="currencys_right">
              <img class="image"
                   :src="toCur.logoUrl" />
              <span class="bnb">{{ toCur.symbol }}</span>
              <img class="arrows"
                   src="@/assets/img/arrows.png" />
            </div>
          </div>
          <div class="currency_right"
               v-else
               @click="handleGetCurrency('to')">
            <span class="bnb">{{ $t("lang.swap5") }}</span>
            <img class="arrows"
                 src="@/assets/img/arrows.png" />
          </div>
          <div class="amount">
            <div>
              {{ $t("lang.swap4") }}
              <span class="overstriking">
                {{
                significantDigits(toCur.balance)
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="balance">
          <van-field class="tokenInput"
                     v-model="toInput"
                     placeholder="0"
                     @input="hanldeInputTo" />
        </div>
      </div>

      <div class="share"
           v-show="account && this.toInput && this.fromInput">
        <div class="subtitle">
          <span>{{ $t("lang.swap97") }}</span>
        </div>
        <div class="detailed_data">
          <div>
            <span v-if="firstShow">-</span>
            <span v-else>{{ (this.toInput / this.fromInput).toFixed(8) }}</span>
            <span class="special">{{ this.toCur.symbol }} / {{ this.fromCur.symbol }}</span>
          </div>
          <div>
            <span v-if="firstShow">-</span>
            <span v-else>{{ (this.fromInput / this.toInput).toFixed(8) }}</span>
            <span class="special">{{ this.fromCur.symbol }} / {{ this.toCur.symbol }}</span>
          </div>
          <div>
            <span v-if="firstShow">0%</span>
            <span v-else-if="this.percentage < 0.01">{{ this.percentageMin }}</span>
            <span v-else-if="this.percentage >= 100">
              {{
              this.percentageMax
              }}
            </span>
            <span v-else>{{ this.percentage }}%</span>
            <span class="special">{{ $t("lang.swap96") }}</span>
          </div>
        </div>
      </div>
      <div class="button_swapBtn">
        <!-- 未连接钱包 -->
        <van-button type="primary"
                    class="swapBtn"
                    v-if="!account"
                    @click="handleLogin">{{ $t("lang.swap9") }}</van-button>
        <!-- 此交易流动性不足 -->
        <van-button type="default"
                    class="swapBtn"
                    v-else-if="insufficientLiquidityShow"
                    disabled>{{ $t("lang.swap12") }}</van-button>
        <!-- 输入数量 -->
        <van-button type="default"
                    class="swapBtn"
                    v-else-if="getNoInputBtnShow"
                    disabled>{{ $t("lang.swap10") }}</van-button>
        <!-- 余额不足 -->
        <van-button type="default"
                    class="swapBtn"
                    v-else-if="getNoBalanceBtnShow"
                    disabled>{{ $t("lang.swap11") }}</van-button>
        <!-- 选择代币 -->
        <van-button type="default"
                    class="swapBtn"
                    v-else-if="getNoSelectBtnShow"
                    disabled>{{ $t("lang.swap13") }}</van-button>
        <!-- 授权 -->
        <div v-else-if="allowanceToRouter">
          <van-button type="primary"
                      class="swapBtn"
                      :loading="approveLoadingFrom"
                      loading-text="Approving..."
                      v-if="allowanceFrom == 0"
                      @click="handleApproveFrom">{{ $t("lang.swap14") }}{{ this.fromCur.symbol }}</van-button>
          <van-button type="primary"
                      class="swapBtn"
                      :loading="approveLoadingTo"
                      loading-text="Approving..."
                      @click="handleApproveTo"
                      v-if="allowanceTo == 0"
                      :style="{ 'margin-top': '10px' }">{{ $t("lang.swap14") }}{{ this.toCur.symbol }}</van-button>
        </div>

        <!-- 包装 -->
        <van-button type="primary"
                    class="swapBtn"
                    v-else-if="getWrap"
                    @click="handleDeposit">{{ $t("lang.swap15") }}</van-button>
        <!-- 解包 -->
        <van-button type="primary"
                    class="swapBtn"
                    v-else-if="getWithdraw"
                    @click="handleWithdraw">{{ $t("lang.swap16") }}</van-button>
        <!-- 闪队 -->
        <van-button type="primary"
                    class="swapBtn"
                    :loading="supplyLoadingBtn"
                    loading-text="Waiting..."
                    v-else
                    @click="supply(true)">{{ $t("lang.swap105") }}</van-button>
      </div>
    </div>
    <van-popup v-model="transactionRecordShow"
               round>
      <transaction-record @listenClose="handleTransactionRecordClose" />
    </van-popup>
    <van-popup v-model="settingShow"
               round>
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
    <van-popup v-if="tokenListShow"
               v-model="tokenListShow"
               round>
      <CurrencyList @listenClose="handleTokenListClose"
                    @listenSelecteCurrency="handlerSelecteCurrency" />
    </van-popup>
    <van-popup v-model="addliquidityDetailShow"
               @closed="handleSwapDetailClosed"
               round>
      <add-liquidity-detail @listenClose="handleSwapDetailClose"
                            @listenConfirm="handleAddLiquidity"
                            :toCur="toCur"
                            :fromCur="fromCur"
                            :percentage="percentage"
                            :swapDetail="swapDetail"
                            :addliquidityDetail="0"></add-liquidity-detail>
    </van-popup>
    <van-popup v-model="pendingDetailShow"
               round
               @closed="handlePendingSwapClosed">
      <pending-swap @listenClose="handlePendingSwapClose"
                    :pendingDetail="pendingDetail"
                    :fromCur="fromCur"
                    :toCur="toCur" />
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CurrencyList from '@/components/CurrencyList.vue';
import web3 from 'web3';
import { getAllPair } from '@/utils/pairHelp';
import TransactionRecord from '@/views/swap/TransactionRecord.vue';
import SettingsModal from '@/components/SettingsModal.vue';
import { getJackRouterAddress } from '@/utils/addressHelp.js';
import { MaxUint256 } from '@ethersproject/constants';
import { isBnb } from '@/utils/verify.js';
import PendingSwap from '@/views/swap/PendingSwap.vue';
import { useJackRouterContract, useErc20Contract } from '@/utils/useContract';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';

import { getErc20Contract, getJackRouterContract, getJackPairContract, getJackFactoryContract } from '@/utils/contractHelp';
import { simpleRpcProvider } from '@/utils/provider';
import AddLiquidityDetail from './addliquidityDetail.vue';
export default {
  data () {
    return {
      supplyLoadingBtn: false,
      tokenListShow: false,
      fromInput: '', //from输入框
      addliquidityDetailShow: false,
      swapDetail: {},
      fromCur: {
        name: 'BNB',
        symbol: 'BNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        chainId: 56,
        decimals: 18,
        balance: '',
        logoUrl: require('@/assets/img/bnb.png'),
      },

      toInput: '', //输出价格
      toCur: {
        balance: '',
        name: '',
        symbol: '',
        address: '',
        chainId: '',
        decimals: '',
        logoUrl: '',
      },
      allPaths: [], //所有的多路径
      currentPaths: [], //当前所有多路径
      pathIndex: -1, //当前选择的多路径
      priceDirection: false,
      price: '',
      priceShow: false,
      inputSource: 'from',
      tokenSource: 'from', //判断当前选择得是输出还是输入

      transactionRecordShow: false, //交易记录
      settingShow: false, //设置滑点
      insufficientLiquidityShow: false, //当固定输出时，大于池子深度
      pendingDetailShow: false,
      pendingDetail: { status: 0, hash: '', fromInput: '', toInput: '' },
      // detailedData: [
      //   { name: "USDT-XXXX每XX", quantity: "0.0111111" },
      //   { name: "XX每USDT-XXX", quantity: "90" },
      //   { name: "流动池份额", quantity: "10.00%" }
      // ],
      percentage: 0,
      percentageMin: '<0.01%',
      percentageMax: '>100%',
      allowanceToRouter: false, //判断是否需要授权
      approveLoadingFrom: false, //授权loading
      approveLoadingTo: false, //授权loading
      allowanceFrom: 0,
      allowanceTo: 0,
      storeLpList: [], //lpList取值
      storeLp: {
        //duixiang
        fromAddress: '',
        fromSymbol: '',
        fromDecimals: '',
        toAddress: '',
        toSymbol: '',
        toDecimals: '',
        lpAddress: '',
      },
      firstShow: false,
      isPairAddress: '0x0000000000000000000000000000000000000000',
      isSave: true,
      haveLp: 0,
      switchVersionShow: '0',
    };
  },
  async created () {
    try {
      // if (this.pool.jackFactory == '0xca143ce32fe78f1f7019d7d551a6402fc5350c73') {
      //   this.storeLpList = localStorage.getItem('v1LpList') ? JSON.parse(localStorage.getItem('v1LpList')) : [];
      // } else {
      //   this.storeLpList = localStorage.getItem('v2LpList') ? JSON.parse(localStorage.getItem('v2LpList')) : [];
      // }
       this.storeLpList = localStorage.getItem('lpList') ? JSON.parse(localStorage.getItem('lpList')) : [];
    } catch (e) {
      this.storeLpList = [];
    }
  },
  beforeDestroy () { },
  watch: {},
  methods: {
    liquidity (i) {
      this.$router.go(-1);
      // this.$router.push({ name: 'LiquidityIndex' });
    },

    handlePendingSwapClosed () {
      this.pendingDetail = { status: 0, hash: '', fromInput: '', toInput: '' };
    },

    handlePendingSwapClose () {
      this.pendingDetailShow = false;
    },
    handleTransactionRecordClose () {
      this.transactionRecordShow = false;
    },
    handlePendingSwapClosed () {
      this.pendingDetail = { status: 0, hash: '', fromInput: '', toInput: '' };
    },
    //关闭弹窗(获得)
    handleSwapDetailClose () {
      this.addliquidityDetailShow = false;
    },
    //关闭详情
    handleSwapDetailClosed () {
      this.swapDetail = {
        fromInput: '',
        toInput: '',
      };
    },

    async supply (show) {
      const slippage = accDiv(this.slippage, 100);
      this.swapDetail.inputOutMin = await significantDigits(accSub(this.toInput, accMul(this.toInput, slippage)));
      this.swapDetail.fromInput = this.fromInput;
      this.swapDetail.lpBalance = this.haveLp * 1;
      this.swapDetail.toInput = this.toInput;
      if (show) {
        this.addliquidityDetailShow = true;
      }
    },

    async handleToMax (Max) {
      if (Max == 'from') {
        // 判断是否是BNB
        if (isBnb(this.fromCur)) {
          // BNB是否大于 0.01
          if (this.fromCur.balance > 0.01) {
            this.fromInput = significantDigits(this.fromCur.balance * 1 - 0.01);
          } else {
            this.fromInput = 0;
          }
        } else {
          this.fromInput = significantDigits(this.fromCur.balance);
        }
        this.getAmountsOut();
      } else {
        // 判断是否是BNB
        if (isBnb(this.toCur)) {
          // BNB是否大于 0.01
          if (this.toCur.balance * 1 > 0.01) {
            this.toInput = significantDigits(this.toCur.balance * 1 - 0.01);
          } else {
            this.toInput = 0;
          }
        } else {
          this.toInput = significantDigits(this.toCur.balance);
        }
        this.getAmountsIn();
      }
      this.inputSource = Max;
    },

    //调用合约
    handleAddLiquidity () {
      this.handleSwapDetailClose();
      this.pendingDetailShow = true;
      this.isSave = true;
      this.pendingDetail.status = 4;
      const slippage = accDiv(this.slippage, 100);
      const deadline = accAdd(accDiv(Date.parse(new Date()), 1000), accMul(this.deadline, 60));
      const amountIn = parseAmount(this.fromInput, this.fromCur.decimals);
      const amountInMin = toFixed(accSub(amountIn, accMul(amountIn, slippage)), 0);
      const amountOut = parseAmount(this.toInput, this.toCur.decimals);
      const amountOutMin = toFixed(accSub(amountOut, accMul(amountOut, slippage)), 0);
      this.pendingDetail.fromInput = this.fromInput;
      this.pendingDetail.toInput = this.toInput;
      if (isBnb(this.fromCur)) {
        //输入为bnb
        this.addLiquidityETH(this.toCur.address, amountOut, amountOutMin, amountIn, this.account, deadline);
      } else if (isBnb(this.toCur)) {
        this.addLiquidityETH(this.fromCur.address, amountIn, amountInMin, amountOut, this.account, deadline);
      } else {
        this.addLiquidity(this.fromCur.address, this.toCur.address, amountIn, amountOut, amountInMin, amountOutMin, this.account, deadline);
      }
    },

    addLiquidityETH (token, amount, amountMin, amoutEth, to, deadline) {
      this.supplyLoadingBtn = true;
      const jackRouterContract = useJackRouterContract();
      jackRouterContract.methods
        .addLiquidityETH(token, amount, amountMin, amoutEth, to, deadline)
        .send({
          from: to,
          value: amoutEth,
          gasPrice: web3.utils.numberToHex(this.gasPrice),
        })
        .on('transactionHash', hash => {
          this.fromCur.balance = '';
          this.toCur.balance = '';
          this.fromInput = '';
          this.toInput = '';
          const transactionsDetail = {
            title: '',
            hash,
            message: `${this.$t('lang.swap94')} ${this.fromCur.symbol} ${this.toCur.symbol}`,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('receipt', receipt => { })
        .on('confirmation', receipt => {
          this.saveStoreList();
          this.supplyLoadingBtn = false;
        })
        .on('error', (error, receipt) => {
          this.supplyLoadingBtn = false;
          this.pendingDetail.status = 2;
          throw error;
        });
    },

    addLiquidity (tokenA, tokenB, amountIn, amountOut, amountInMin, amountOutMin, to, deadline) {
      this.supplyLoadingBtn = true;
      const jackRouterContract = useJackRouterContract();
      jackRouterContract.methods
        .addLiquidity(tokenA, tokenB, amountIn, amountOut, amountInMin, amountOutMin, to, deadline)
        .send({
          from: to,
          gasPrice: web3.utils.numberToHex(this.gasPrice),
        })
        .on('transactionHash', hash => {
          this.fromCur.balance = '';
          this.toCur.balance = '';
          this.fromInput = '';
          this.toInput = '';
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
          const transactionsDetail = {
            title: '',
            hash,
            message: `${this.$t('lang.swap94')} ${this.fromCur.symbol} / ${this.toCur.symbol}`,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => { })
        .on('confirmation', receipt => {
          this.saveStoreList();
          this.supplyLoadingBtn = false;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.supplyLoadingBtn = false;
          throw error;
        });
    },

    //关闭币种选择弹窗
    handleTokenListClose () {
      this.tokenListShow = false;
    },
    // from的输入框
    async hanldeInputFrom () {
      this.inputSource = 'from';
      this.getAmountsOut();
      // this.supply();
    },

    //to的输入框
    hanldeInputTo () {
      this.inputSource = 'to';
      this.getAmountsIn();
    },

    //币种选择
    async handlerSelecteCurrency (currency) {
      this.tokenListShow = false;
      if (this.tokenSource == 'from') {
        if (currency.address == this.toCur.address && currency.name == this.toCur.name) {
          this.turnAround();
        } else {
          this.fromCur = currency;
        }
      } else {
        if (currency.address == this.fromCur.address && currency.name == this.fromCur.name) {
          this.turnAround();
        } else {
          this.toCur = currency;
        }
      }
      this.handleGetAllPair();
      this.handleApproveBtnShow(); //是否展示授权按钮
      this.handleCurBalance();
    },

    //获取所有的交易对
    async handleGetAllPair () {
      if (this.getWrap || this.getWithdraw) {
        this.allpaths = [];
        return;
      }
      if (this.fromCur.address == '' || this.toCur.address == '') {
        return;
      }
      const allPair = getAllPair(this.fromCur, this.toCur);
      const promisePairAddress = allPair.map(item => {
        const jackFactoryContract = getJackFactoryContract();
        const pairAddress = jackFactoryContract.methods.getPair(item.fromCur.address, item.toCur.address).call();
        return pairAddress;
      });
      let allPairAddress = await Promise.all(promisePairAddress);
      let lpPair = [];
      for (let i = 0; i < allPair.length; i++) {
        const lpAddress = allPairAddress[i];
        if (lpAddress == '0x0000000000000000000000000000000000000000') {
          continue;
        }
        lpPair.push({ lpAddress, ...allPair[i] });
      }
      //储备量
      let promiseReserve = lpPair.map(item => {
        const jackPairContract = getJackPairContract(item.lpAddress);
        let total = jackPairContract.methods.totalSupply().call();
        const reserves = jackPairContract.methods.getReserves().call();
        return reserves;
      });
      const allReserves = await Promise.all(promiseReserve);
      let newlpPair = lpPair.map((item, index) => {
        let newItem = JSON.parse(JSON.stringify(item));
        if (newItem.fromCur.address.toLowerCase() < newItem.toCur.address.toLowerCase()) {
          newItem.fromCur.reserves = allReserves[index]._reserve0;
          newItem.toCur.reserves = allReserves[index]._reserve1;
        } else {
          newItem.fromCur.reserves = allReserves[index]._reserve1;
          newItem.toCur.reserves = allReserves[index]._reserve0;
        }
        return newItem;
      });

      let pathFrom = [];
      for (let i of newlpPair) {
        if (i.fromCur.address == this.fromCur.address) {
          pathFrom.push(i);
        }
      }

      let pathTo = [];

      for (let i of newlpPair) {
        if (i.toCur.address == this.toCur.address) {
          pathTo.push(i);
        }
      }

      let allpaths = [];
      // 算出到底有多少条路径
      for (let i = 0; i < pathFrom.length; i++) {
        const item = pathFrom[i];
        const findItem = pathTo.find(toItem => {
          return item.toCur.address == toItem.fromCur.address;
        });
        if (findItem && findItem.toCur.address == this.toCur.address) {
          allpaths.push([item, findItem]);
          continue;
        }
        if (item.toCur.address == this.toCur.address) {
          allpaths.push([item]);
        }
      }
      this.allpaths = allpaths;
      if (this.inputSource === 'from') {
        this.getAmountsOut();
      }

      if (this.inputSource === 'to') {
        this.getAmountsIn();
      }
    },

    async saveStoreList () {
      if (this.isSave) {
        this.isSave = false;
        this.storeLp.fromAddress = this.fromCur.address;
        this.storeLp.fromSymbol = this.fromCur.symbol;
        this.storeLp.fromDecimals = this.fromCur.decimals;
        this.storeLp.toAddress = this.toCur.address;
        this.storeLp.toSymbol = this.toCur.symbol;
        this.storeLp.toDecimals = this.toCur.decimals;
        const jackFactoryContract = getJackFactoryContract();
        this.storeLp.lpAddress = await jackFactoryContract.methods.getPair(this.storeLp.fromAddress, this.storeLp.toAddress).call();
        if (this.storeLpList.length == 0) {
          this.storeLpList.push(this.storeLp);
        } else {
          let isHave = false;
          for (var i = 0; i < this.storeLpList.length; i++) {
            if (this.storeLpList[i].lpAddress == this.storeLp.lpAddress) {
              isHave = true;
              break;
            }
          }
          if (!isHave) {
            this.storeLpList.push(this.storeLp);
          }
        }
        localStorage.setItem('lpList', JSON.stringify(this.storeLpList));
        // if (this.pool.jackFactory == '0xca143ce32fe78f1f7019d7d551a6402fc5350c73') {
        //   localStorage.setItem('v1LpList', JSON.stringify(this.storeLpList));
        // } else {
        //   localStorage.setItem('v2LpList', JSON.stringify(this.storeLpList));
        // }
        this.storeLp = {
          fromAddress: '',
          fromSymbol: '',
          fromDecimals: '',
          toAddress: '',
          toSymbol: '',
          toDecimals: '',
          lpAddress: '',
        };
        this.fromInput = '';
        this.toInput = '';
      }
    },

    // 获取两边币种余额
    async handleCurBalance () {
      if (this.account) {
        if (isBnb(this.fromCur)) {
          const balance = await simpleRpcProvider.eth.getBalance(this.account);
          this.fromCur.balance = formatAmount(balance);
        }

        if (!isBnb(this.fromCur) && this.fromCur.address != '') {
          const erc20Contract = getErc20Contract(this.fromCur.address);
          const balance = await erc20Contract.methods.balanceOf(this.account).call();
          this.fromCur.balance = formatAmount(balance, this.fromCur.decimals);
        }

        if (isBnb(this.toCur)) {
          const balance = await simpleRpcProvider.eth.getBalance(this.account);
          this.toCur.balance = formatAmount(balance);
        }

        if (!isBnb(this.toCur) && this.toCur.address != '') {
          const erc20Contract = getErc20Contract(this.toCur.address);
          const balance = await erc20Contract.methods.balanceOf(this.account).call();
          this.toCur.balance = formatAmount(balance, this.toCur.decimals);
        }
      }
    },

    // from的输入框
    async hanldeInputFrom () {
      this.inputSource = 'from';
      this.getAmountsOut();
    },

    //to的输入框
    async hanldeInputTo () {
      this.inputSource = 'to';
      this.getAmountsIn();
      this.share();
    },
    async getAmountsOut () {

      this.insufficientLiquidityShow = false;
      if (parseFloat(this.fromInput) === 0 || this.fromInput == '') {
        if (this.firstShow) {
          return;
        }
        this.toInput = '';
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.toInput = '';
        return;
      }

      if (this.getWrap || this.getWithdraw) {
        this.toInput = this.fromInput;
        return;
      }

      if (this.multipath) {
        this.currentPaths = this.allpaths.filter(item => {
          return item.length == 1;
        });
      } else {
        this.currentPaths = this.allpaths;
      }
      let jackFactoryContract = getJackFactoryContract();
      let pairAddress = await jackFactoryContract.methods.getPair(this.fromCur.address, this.toCur.address).call();

      if (pairAddress == '0x0000000000000000000000000000000000000000') {
        this.firstShow = true;
        return;
      } else {

        this.firstShow = false;
        const jackPairContract = getJackPairContract(pairAddress);
        let max = 0;
        let reserves = await jackPairContract.methods.getReserves().call();
        if (reserves._reserve0 == 0 || reserves._reserve1 == 0) {
          this.firstShow = true
          return
        }

        if (this.fromCur.address.toLowerCase() < this.toCur.address.toLocaleLowerCase()) {
          let fromAll = reserves._reserve0 / Math.pow(10, this.fromCur.decimals);
          let toAll = reserves._reserve1 / Math.pow(10, this.toCur.decimals);
          let scale = toAll / fromAll; //币的价格

          max = (this.fromInput * scale).toFixed(this.toCur.decimals);
        } else {
          let fromALL = reserves._reserve1 / Math.pow(10, this.fromCur.decimals);
          let toAll = reserves._reserve0 / Math.pow(10, this.toCur.decimals);
          let scale = toAll / fromALL; //币的价格
          max = (this.fromInput * scale).toFixed(this.toCur.decimals);

        }
        this.toInput = max;

        this.share();
        this.getPairPrice();
      }
    },

    async getAmountsIn () {

      this.insufficientLiquidityShow = false;
      if (parseFloat(this.toInput) === 0 || this.toInput == '') {
        if (this.firstShow) {
          return;
        }
        this.fromInput = '';
        this.priceShow = false;
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.fromInput = '';
        this.priceShow = false;
        return;
      }

      if (this.getWrap || this.getWithdraw) {
        this.fromInput = this.toInput;
        return;
      }

      let jackFactoryContract = getJackFactoryContract();
      let pairAddress = await jackFactoryContract.methods.getPair(this.fromCur.address, this.toCur.address).call();

      if (pairAddress == '0x0000000000000000000000000000000000000000') {
        this.firstShow = true;

        return;
      } else {

        this.firstShow = false;
        const jackPairContract = getJackPairContract(pairAddress);
        let max = 0;
        let reserves = await jackPairContract.methods.getReserves().call();
        if (reserves._reserve0 == 0 || reserves._reserve1 == 0) {
          this.firstShow = true
          return
        }
        if (this.fromCur.address.toLowerCase() < this.toCur.address.toLocaleLowerCase()) {
          let fromAll = reserves._reserve0 / Math.pow(10, this.fromCur.decimals);
          let toAll = reserves._reserve1 / Math.pow(10, this.toCur.decimals);
          let scale = toAll / fromAll; //币的价格

          max = (this.toInput / scale).toFixed(this.fromCur.decimals);
        } else {
          let fromALL = reserves._reserve1 / Math.pow(10, this.fromCur.decimals);
          let toAll = reserves._reserve0 / Math.pow(10, this.toCur.decimals);
          let scale = toAll / fromALL; //币的价格
          max = (this.toInput / scale).toFixed(this.fromCur.decimals);
        }
        this.fromInput = max;
        this.share();
        this.getPairPrice();
      }
    },

    //获取百分比
    async share () {
      if (this.firstShow) {
        return;
      }
      let jackFactoryContract = getJackFactoryContract();
      let pairAddress = await jackFactoryContract.methods.getPair(this.fromCur.address, this.toCur.address).call();
      const jackPairContract = getJackPairContract(pairAddress);
      let total = (await jackPairContract.methods.totalSupply().call()) * 1;
      let reserves = await jackPairContract.methods.getReserves().call();
      let sort = 0;
      let sort2 = 0;
      if (this.fromCur.address.toLowerCase() < this.toCur.address.toLocaleLowerCase()) {
        sort = this.fromInput * Math.pow(10, this.fromCur.decimals);
        sort2 = this.toInput * Math.pow(10, this.toCur.decimals);
      } else {
        sort = this.toInput * Math.pow(10, this.toCur.decimals);
        sort2 = this.fromInput * Math.pow(10, this.fromCur.decimals);
      }
      let liquidity = Math.min((sort * total) / (reserves._reserve0 * 1), (sort2 * total) / (reserves._reserve1 * 1));
      this.percentage = liquidity / (total + liquidity);

      this.haveLp = significantDigits(((liquidity + total) * this.percentage) / Math.pow(10, 18));
      this.percentage = (this.percentage * 100).toFixed(3);
    },

    // 流动池价格更换
    async getPairPrice () {
      if (this.firstShow) {
        return;
      }
      if (parseFloat(this.fromInput) === 0 || parseFloat(this.toInput) === 0) {
        this.fromInput = '';
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.fromInput = '';
        return;
      }
      const fromInput = parseFloat(this.fromInput);
      const toInput = parseFloat(this.toInput);
      if (this.priceDirection) {
        const price = accDiv(fromInput, toInput);
        this.price = significantDigits(price);
      } else {
        const price = accDiv(toInput, fromInput);
        this.price = significantDigits(price);
      }
      this.priceShow = true;
    },

    // 是否需要显示授权按钮
    async handleApproveBtnShow () {
      const to = getJackRouterAddress();
      if (this.account) {
        let erc20ContractTo = getErc20Contract(this.toCur.address);
        let erc20ContractFrom = getErc20Contract(this.fromCur.address);
        this.allowanceFrom = await erc20ContractFrom.methods.allowance(this.account, to).call();
        this.allowanceTo = await erc20ContractTo.methods.allowance(this.account, to).call();
        if (this.allowanceFrom == 0 || this.allowanceTo == 0) {
          this.allowanceToRouter = true;
        } else {
          this.allowanceToRouter = false;
        }
      }
    },

    //点击选择币种
    handleGetCurrency (tokenSource) {
      this.tokenSource = tokenSource;
      this.tokenListShow = true;
    },

    // 交换from to数据
    turnAround () {
      const tempCurrency = { ...this.toCur };
      this.toCur = { ...this.fromCur };
      this.fromCur = { ...tempCurrency };

      const tempInput = this.toInput;
      this.toInput = this.fromInput;
      this.fromInput = tempInput;

      this.inputSource = this.inputSource === 'from' ? 'to' : 'from';
    },

    //是否显示设置得余额
    handleSettingShow () {
      this.settingShow = true;
    },

    handleSettingClose () {
      this.settingShow = false;
    },

    handleRecordShow () {
      this.transactionRecordShow = true;
    },

    handleTransactionRecordClose () {
      this.transactionRecordShow = false;
    },

    async initialization () {
      this.handleCurBalance();
      // 余额定时器请求
      this.handleTimerBalance();
    },

    async handleTimerBalance () {
      window.clearTimeout(this.timerBalance);
      this.timerBalance = setInterval(async () => {
        this.handleCurBalance();
      }, 3000);
    },

    async handleApproveTo () {
      const erc20Contract = useErc20Contract(this.toCur.address);
      const amount = MaxUint256.toString();
      const to = getJackRouterAddress();
      const gas = await erc20Contract.methods.approve(to, amount).estimateGas({ from: this.account });
      this.approveLoadingTo = true;
      erc20Contract.methods
        .approve(to, amount)
        .send({
          from: this.account,
          gas: gasProcessing(gas),
          gasPrice: web3.utils.numberToHex(this.gasPrice),
        })
        .on('transactionHash', hash => {
          const transactionsDetail = {
            title: '',
            hash,
            message: `${this.$t('lang.swap14')} ${this.fromCur.symbol}`,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('confirmation', receipt => {
          this.handleApproveBtnShow();
          this.approveLoadingTo = false;
        })
        .on('error', (error, receipt) => {
          this.approveLoadingTo = false;
          this.handleApproveBtnShow();
          throw error;
        });
    },

    async handleApproveFrom () {
      const erc20Contract = useErc20Contract(this.fromCur.address);
      const amount = MaxUint256.toString();
      const to = getJackRouterAddress();
      const gas = await erc20Contract.methods.approve(to, amount).estimateGas({ from: this.account });
      this.approveLoadingFrom = true;
      erc20Contract.methods
        .approve(to, amount)
        .send({
          from: this.account,
          gas: gasProcessing(gas),
          gasPrice: web3.utils.numberToHex(this.gasPrice),
        })
        .on('transactionHash', hash => {
          const transactionsDetail = {
            title: '',
            hash,
            message: `${this.$t('lang.swap14')} ${this.fromCur.symbol}`,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('confirmation', receipt => {
          this.handleApproveBtnShow();
          this.approveLoadingFrom = false;
          this.getLpList();
        })
        .on('error', (error, receipt) => {
          this.approveLoadingFrom = false;
          this.handleApproveBtnShow();
          throw error;
        });
    },
    significantDigits,
  },
  components: {
    TransactionRecord,
    SettingsModal,
    CurrencyList,
    AddLiquidityDetail,
    PendingSwap,
  },
  watch: {
    account () {
      //获取账号
      this.initialization();
    },
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath', 'pool']),
    // 是否需要显示余额不足提示
    getNoBalanceBtnShow () {

      if (accGt(this.fromInput, this.fromCur.balance) || accGt(this.toInput, this.toCur.balance)) {
        return true;
      }
      return false;
    },

    //是否需要显示inputBtn按钮
    getNoInputBtnShow () {
      if ((parseFloat(this.fromInput) === 0 || this.fromInput === '') && this.fromCur.address !== '') {
        return true;
      } else if ((parseFloat(this.toInput) === 0 || this.toInput === '') && this.toCur.address !== '') {
        return true;
      }
      return false;
    },
    // 是否需要显示选择币种
    getNoSelectBtnShow () {
      if (this.fromCur.address == '' || this.toCur.address == '') {
        return true;
      }
      return false;
    },
    // 是否BNB - WBNB
    getWrap () {
      if (isBnb(this.fromCur) && this.toCur.address == this.fromCur.address) {
        return true;
      }
      return false;
    },
    // 是否WBNB - BNB
    getWithdraw () {
      if (isBnb(this.toCur) && this.toCur.address == this.fromCur.address) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.liquidity {
  background: #fff;
  width: 100%;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 20px;
  overflow: hidden;
  .exchange_function {
    font-size: 18px;
    font-weight: 600;
    color: #1b365f;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
    padding: 22px 18px 30px 12px;
    .function_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // div {
      //   display: flex;
      //   align-items: center;
      // }
      .left_arrow {
        width: 24px;
        height: 16px;
        margin-right: 8px;
        margin-top: 18px;
      }

      .function_button {
        display: flex;
        font-size: 10px;
        color: #666666;
        // margin: 6px 0 0 22px;
        .liquidity_title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 18px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
            color: #333333;
            margin-bottom: 10px;
          }
        }
      }
      .install_img {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        .install {
          width: 20px;
          height: 20px;
          margin-right: 14px;
        }
        .refresh {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .container {
    padding: 20px;
    .exchange_currency {
      margin: 16px 0;
      .balance {
        margin-top: 10px;
        .tokenInput {
          // display: flex;
          // justify-content: flex-end;
          font-size: 16px !important;
          font-weight: 400;
          color: #595b5b;
          border-radius: 10px;
          border: 1px solid rgba(112, 112, 112, 0.5);

          padding: 8px 18px !important;
          text-align: center;
          /deep/ .van-field__control {
            text-align: right !important;
          }
        }
      }
      .currency {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;

        .currency_right {
          display: flex;
          align-items: center;
          max-width: 50%;
          .max {
            line-height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2a4e6f;
            background: #e9c87b;
            border-radius: 8px;
            white-space: nowrap;

            padding: 1px 3px;
          }
          .currencys_right {
            display: flex;
            align-items: center;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .image {
            width: 24px;
            height: 25px;
            margin: 0 5px;
            box-shadow: rgbr(0, 0, 0, 0.08) 0px 3px 5px;
            border-radius: 24px;
          }
          .bnb {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .arrows {
            width: 8px;
            height: 4px;
            margin-left: 6px;
          }
        }
        .amount {
          max-width: 40%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #2a4e6f;
          padding: 0 10px;
          div {
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .overstriking {
            width: 100%;
            font-weight: 800;
          }
        }
      }
    }

    .share {
      font-weight: 400;
      background: #f2f1f1;
      border-radius: 18px;
      border: 1px solid #999999;
      margin-top: 12px;
      .subtitle {
        font-size: 12px;

        color: #333333;
        margin: 24px 8px 10px;
      }
      .detailed_data {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 18px;

        border: 1px solid #999999;
        padding: 0 10px 12px;
        margin-top: 7px;
        div {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 50%;
          font-size: 12px;
          color: #333333;
          margin-top: 24px;
          .special {
            font-size: 10px;
            color: #333333;
            margin-top: 8px;
          }
        }
      }
    }
    .button_swapBtn {
      margin: 26px 0 14px;
      .swapBtn {
        width: 100%;
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        padding: 10px 0;
      }
    }
    .promote {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      background: #f8f7f7;
      border-radius: 10px;
      border: 1px solid #ffd245;
      padding: 30px 26px 26px 52px;
      margin: 18px 0 26px;
      .promote_subtitle {
        position: relative;
        font-size: 16px;
        color: #333333;
        .warning {
          position: absolute;
          left: -30px;
          top: 0;
          width: 20px;
          height: 20px;
        }
      }
      .special {
        margin: 16px 0;
      }
    }
    .iconDown {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
}
</style>
